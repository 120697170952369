import { channelType } from "../types";

const channels: channelType[] = [
  {
    code: "digitalstorefront",
    name: "global.channels.digitalStorefront",
    languages: ["en", "de"],
    defaultLanguage: "de",
    currencies: ["USD", "EUR"],
  },
  {
    code: "customerterminal",
    name: "global.channels.customerTerminal",
    languages: ["en", "de"],
    defaultLanguage: "de",
    currencies: ["USD", "EUR"],
  },
  {
    code: "crmchannel",
    name: "global.channels.crm",
    languages: ["en", "de"],
    defaultLanguage: "de",
    currencies: ["USD", "EUR"],
  },
  {
    code: "erpchannel",
    name: "global.channels.erp",
    languages: ["en", "de"],
    defaultLanguage: "de",
    currencies: ["USD", "EUR"],
  },
  {
    code: "salestoolschannel",
    name: "global.channels.salesTools",
    languages: ["en", "de"],
    defaultLanguage: "de",
    currencies: ["USD", "EUR"],
  },
  {
    code: "onlineshopchannel",
    name: "global.channels.onlineShop",
    languages: ["en", "de"],
    defaultLanguage: "de",
    currencies: ["USD", "EUR"],
  },
];
export default channels;

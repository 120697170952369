import { useContext } from "react";
import { Context } from ".";
import { InputGroup, Select } from "../../../../components";
import { rules, types } from "../../../../constants";

export default function BasicInfo() {
  const { data, handleSetValue } = useContext(Context);
  return (
    <section className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-6">
      <div className="col-span-full">
        <InputGroup
          label="b2bManagement.users.create.email"
          value={data.emailAddress}
          setValue={handleSetValue("emailAddress")}
          rules={rules.email}
        />
      </div>
      <div className="col-span-full">
        <InputGroup
          label="b2bManagement.users.create.personalNumber"
          value={data.personalNumber}
          setValue={handleSetValue("personalNumber")}
          rules={rules.required}
        />
      </div>
      <InputGroup
        label="b2bManagement.users.create.firstName"
        value={data.firstName}
        setValue={handleSetValue("firstName")}
        rules={rules.required}
      />
      <InputGroup
        label="b2bManagement.users.create.lastName"
        value={data.lastName}
        setValue={handleSetValue("lastName")}
        rules={rules.required}
      />
      <InputGroup
        label="b2bManagement.users.create.dateOfBirth"
        value={data.dateOfBirth}
        setValue={handleSetValue("dateOfBirth")}
        rules={rules.required}
        type="date"
      />
      <Select
        label="b2bManagement.users.create.gender"
        value={data.gender}
        setValue={handleSetValue("gender")}
        items={types.gender}
        rules={rules.required}
      />
    </section>
  );
}

import * as IconSax from "iconsax-react";
import { ComponentProps, useMemo } from "react";
import { IconVariantType, iconNameType } from "../types";
type iconProps = {
  name: iconNameType;
  variant?: IconVariantType;
  size?: number | string;
  className?: string;
};
function Icon({
  name,
  variant,
  size = "1.25em",
  className = "",
  ...props
}: iconProps & Omit<ComponentProps<"svg">, "ref">) {
  const Component = useMemo(() => IconSax[name], [name]);
  return (
    <Component
      variant={variant}
      size={size}
      className={`inline-block align-sub ${className}`}
      {...props}
    />
  );
}
export default Icon;

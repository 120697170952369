import { Accordion, Button, Drawer, Icon, Image, Text } from "../components";
import { useAxios, useConverter } from "../hooks";
import { toggleProps } from "../types";
import { importProductItemType } from "../types/import-product";
type detailsMenuProps = {
  data: importProductItemType;
} & toggleProps;
export default function ImportProductDetailsMenu({
  data,
  isOpen,
  toggle,
}: detailsMenuProps) {
  const convert = useConverter();
  const { axios, loading } = useAxios();
  const basicInfoItems = [
    {
      label: "productManagement.providers.productDetails.productTitle",
      value: data.title,
    },
    {
      label: "productManagement.providers.productDetails.language",
      value: data.language,
    },
    {
      label: "productManagement.providers.productDetails.articleNumber",
      value: data.productKey,
    },
    { label: "productManagement.providers.productDetails.price", value: "" },
    {
      label: "productManagement.providers.productDetails.productFamily",
      value: data.productFamily,
    },
    {
      label: "productManagement.providers.productDetails.shortDescription",
      value: data.shortDescription,
    },
    {
      label: "productManagement.providers.productDetails.description",
      value: data.description,
    },
  ];
  const specifications = data.contents?.map((e) => ({
    label: e.attributeCode,
    value: e.value,
  }));
  const isImported = !!data.importInfo;
  const media = data.media?.filter((e) => !!e.thumbnailUrl) ?? [];
  const hasMedia = !!media.length;
  const importProduct = () => {
    const url = "";
    const body = {};
    axios.post(url, body).then(({ data }) => {
      console.log(data);
    });
  };
  return (
    <Drawer isOpen={isOpen} toggle={toggle} size="xl">
      <Drawer.Menu>
        <Drawer.Header className="flex items-center">
          <h2 className="text-heading-2 text-gray-800 flex-1">
            <Text>productManagement.providers.productDetails.title</Text>
          </h2>
          <Button
            type="button"
            onClick={toggle}
            variant="gray"
            disabled={loading.post}
          >
            <Icon name="CloseCircle" />{" "}
            <Text>productManagement.providers.productDetails.closeBtn</Text>
          </Button>
        </Drawer.Header>
        <Drawer.Body className="space-y-6">
          <Accordion>
            <Accordion.Item className="border border-dashed border-gray-600 rounded">
              <Accordion.Toggle className="!text-heading-3 text-gray-600 p-8">
                <Text>
                  productManagement.providers.productDetails.basicInfo
                </Text>
              </Accordion.Toggle>
              <Accordion.Body className="p-8 pt-0">
                <table className="w-full">
                  <tbody>
                    {basicInfoItems.map((e) => (
                      <tr
                        key={e.label}
                        className="text-body-1 text-gray-800 align-top even:bg-gray-100"
                      >
                        <td className="font-medium p-2 whitespace-nowrap">
                          <Text>{e.label}</Text>
                        </td>
                        <td
                          className="font-normal p-2"
                          dangerouslySetInnerHTML={{ __html: e.value || "--" }}
                        ></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          <Accordion>
            <Accordion.Item className="border border-dashed border-gray-600 rounded">
              <Accordion.Toggle className="!text-heading-3 text-gray-600 p-8">
                <Text>
                  productManagement.providers.productDetails.basicInfo
                </Text>
              </Accordion.Toggle>
              <Accordion.Body className="p-8 pt-0">
                <table className="w-full">
                  <tbody>
                    {specifications.map((e) => (
                      <tr
                        key={e.label}
                        className="text-body-1 text-gray-800 align-top even:bg-gray-100"
                      >
                        <td className="font-medium p-2 whitespace-nowrap">
                          <Text>{e.label}</Text>
                        </td>
                        <td
                          className="font-normal p-2"
                          dangerouslySetInnerHTML={{ __html: e.value || "--" }}
                        ></td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          {hasMedia && (
            <Accordion>
              <Accordion.Item className="border border-dashed border-gray-600 rounded">
                <Accordion.Toggle className="!text-heading-3 text-gray-600 p-8">
                  <Text>productManagement.providers.productDetails.media</Text>
                </Accordion.Toggle>
                <Accordion.Body className="p-8 pt-0 grid grid-cols-1 lg:grid-cols-3 gap-4">
                  {media.map((e) => (
                    <Image
                      key={e.id}
                      src={e.thumbnailUrl}
                      alt={e.attributeCode}
                      className="w-full rounded"
                    />
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          )}
        </Drawer.Body>
        <Drawer.Footer className="flex items-center gap-4">
          <Button
            type="button"
            onClick={toggle}
            variant="gray"
            className="me-auto"
            disabled={loading.post}
          >
            <Icon name="CloseCircle" />{" "}
            <Text>productManagement.providers.productDetails.closeBtn</Text>
          </Button>
          {isImported && (
            <div className="flex-1">
              <p className="block w-fit ms-auto max-w-full bg-warning-light text-warning-active text-heading-5 rounded py-3 px-2">
                <Icon name="InfoCircle" variant="Bulk" />{" "}
                <Text>
                  productManagement.providers.productDetails.importedProductText
                </Text>{" "}
                {convert.date(data.importInfo?.importedDate)}
              </p>
            </div>
          )}
          <Button
            type="button"
            onClick={importProduct}
            variant="primary"
            loading={loading.post}
          >
            <Icon name="DocumentForward" variant="Bold" />{" "}
            <Text>
              productManagement.providers.productDetails.importAgainBtn
            </Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}

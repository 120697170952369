import { Address } from "../types";

export default function convertAddress(address?: Address): string {
  if (!address) return "";
  const { street, number, postalCode, city, state, country } = address;
  return [
    [street, number],
    [postalCode, city],
    [state, country],
  ]
    .map((e) => e.filter(Boolean).join(" "))
    .filter((e) => !!e.length)
    .join(", ");
}

const productDetails = {
  title: "Product Details DE",
  closeBtn: "Close DE",
  importAgainBtn: "Import again DE",

  basicInfo: "Basic info DE",
  specification: "Technical specifications DE",
  media: "Media DE",

  productTitle: "Product Title DE",
  language: "Language DE",
  articleNumber: "Article Number DE",
  price: "Price DE",
  productFamily: "Product family DE",
  shortDescription: "Short Description DE",
  description: "Description DE",

  importedProductText: "This product was imported at DE",
};
export default productDetails;

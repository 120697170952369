import { Fragment, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import {
  Button,
  Icon,
  Pagination,
  SearchBox,
  Status,
  Table,
  Text,
} from "../../../../../components";
import { config, types } from "../../../../../constants";
import { useAxios } from "../../../../../hooks";
import { WithPaging } from "../../../../../types";
import { providerProductItemType } from "../../../../../types/provider-product";
import DetailsMenu from "./DetailsMenu";

type dataType = providerProductItemType;
type paramsType = {
  pageNumber: string;
  pageSize: string;
  keyword: string;
  companyId: string;
};

export default function ProviderProducts() {
  const { axios, loading } = useAxios();
  const [data, setData] = useState({} as WithPaging<dataType>);
  const [params, setParams] = useSearchParams({
    pageNumber: "1",
    pageSize: config.pageSize,
    keyword: "",
    companyId: "",
  } as paramsType);
  const [activeId, setActiveId] = useState("");
  const clearActiveId = () => {
    setActiveId("");
  };
  const handleSetParams = (key: keyof paramsType) => {
    return (value: any) =>
      setParams((p) => {
        p.set(key, String(value));
        return p;
      });
  };
  const findProviderName = (id: number) => {
    return types.productProviders.find((e) => e.id === id)?.name ?? "--";
  };
  const goToDetails = (id: string) => {
    return () => setActiveId(id);
  };
  const getData = () => {
    const url = "/productservice/api/providerproductimportpursuits";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [params]);
  return (
    <section className="space-y-4">
      <div className="flex flex-wrap items-center gap-4">
        <SearchBox
          value={params.get("keyword")}
          setValue={handleSetParams("keyword")}
          className="w-auto basis-full lg:basis-1/3"
        />
        <Button as={Link} to="add" className="w-fit ms-auto">
          <Icon name="Add" /> <Text>Add new EN</Text>
        </Button>
      </div>
      <Table loading={loading.get}>
        {data.items?.map((e) => (
          <Fragment key={e.providerProductImportPursuitId}>
            <tr
              onClick={goToDetails(e.providerProductImportPursuitId)}
              className="cursor-pointer"
            >
              <td>
                <h6 className="text-gray-800 text-heading-6">
                  {findProviderName(e.providerName)}
                </h6>
              </td>
              <td>
                <Status.Pursuit id={e.status} />
              </td>
              <td>
                <p className="text-body-2 text-gray-600">
                  <Text>Configuration Version EN</Text>:{" "}
                  {e.configurationVersion}
                </p>
              </td>
              <td>
                <p className="text-body-2 text-gray-600">
                  <Text>Created by EN</Text>: {e.createdBy}
                </p>
              </td>
              <td>
                <Button type="button" variant="gray">
                  <Text>Details EN</Text>
                </Button>
              </td>
            </tr>
            <DetailsMenu
              isOpen={activeId === e.providerProductImportPursuitId}
              toggle={clearActiveId}
              data={e}
            />
          </Fragment>
        ))}
      </Table>
      <Pagination
        pageNumber={params.get("pageNumber")}
        setActivePage={handleSetParams("pageNumber")}
        totalItems={data.totalItems}
        totalPages={data.totalPages}
      />
    </section>
  );
}

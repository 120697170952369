import MainLayout from "../layouts/MainLayout";
import Dashboard from "../pages/Dashboard";
import CreateCompany from "../pages/b2b management/companies/create";
import CompanyDetails from "../pages/b2b management/companies/details";
import Companies from "../pages/b2b management/companies/list";
import CreateUser from "../pages/b2b management/users/create";
import UserDetails from "../pages/b2b management/users/details";
import Users from "../pages/b2b management/users/list";
import ImportProducts from "../pages/product management/providers/import products/list";
import CreateProviderProduct from "../pages/product management/providers/provider products/create";
import ProviderProductDetails from "../pages/product management/providers/provider products/details";
import ProviderProducts from "../pages/product management/providers/provider products/list";
import CreatePlatformUser from "../pages/user management/platform users/create";
import PlatformUserDetails from "../pages/user management/platform users/details";
import PlatformUsers from "../pages/user management/platform users/list";
import RoleDetails from "../pages/user management/roles/details";
import Roles from "../pages/user management/roles/list";
import UserGroupDetails from "../pages/user management/user groups/details";
import UserGroups from "../pages/user management/user groups/list";

const routes = [
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
      {
        path: "companies",
        element: <Companies />,
      },
      {
        path: "companies/add",
        element: <CreateCompany />,
      },
      {
        path: "companies/:companyId",
        element: <CompanyDetails />,
      },
      {
        path: "users",
        element: <Users />,
      },
      {
        path: "users/add",
        element: <CreateUser />,
      },
      {
        path: "users/:companyId/:userId",
        element: <UserDetails />,
      },
      {
        path: "roles",
        element: <Roles />,
      },
      {
        path: "roles/:roleId",
        element: <RoleDetails />,
      },
      {
        path: "user-groups",
        element: <UserGroups />,
      },
      {
        path: "user-groups/:userGroupId",
        element: <UserGroupDetails />,
      },
      {
        path: "platform-users",
        element: <PlatformUsers />,
      },
      {
        path: "platform-users/add",
        element: <CreatePlatformUser />,
      },
      {
        path: "platform-users/:userId",
        element: <PlatformUserDetails />,
      },
      {
        path: "import-products",
        element: <ImportProducts />,
      },
      {
        path: "provider-products",
        element: <ProviderProducts />,
      },
      {
        path: "provider-products/add",
        element: <CreateProviderProduct />,
      },
      {
        path: "provider-products/:providerId",
        element: <ProviderProductDetails />,
      },
      {
        path: "*",
        element: <h1>Page not found</h1>,
      },
    ],
  },
];
export default routes;

import {createSlice} from "@reduxjs/toolkit";

const permissions = createSlice({
  name: "permissions",
  initialState: [] as string[],
  reducers: {
    setPermissions: (state, action) => {
      return action.payload;
    },
  },
});

export const {setPermissions} = permissions.actions;
export default permissions.reducer;

import { useContext } from "react";
import { Context } from ".";
import { Loading, Text, Wrapper } from "../../../../components";
import { useGetPermissions } from "../../../../hooks";
export default function Finalize() {
  const { data } = useContext(Context);
  const { permissions, loading } = useGetPermissions();
  const items = [
    {
      title: "userManagement.roles.details.basicInfoTab",
      items: [
        {
          label: "userManagement.roles.details.roleName",
          value: data.name,
        },
        {
          label: "userManagement.roles.details.shortDescription",
          value: data.description,
        },
      ],
    },
    {
      title: "userManagement.roles.details.roleInfoTab",
      items: data.permissions?.map((e) => ({
        label:
          permissions
            .map((e) => e.permissions)
            .flat()
            .find(({ key }) => key === e.code)?.label ?? "",
        value: "",
      })),
    },
  ];
  if (loading.get) return <Loading.Inline />;
  return (
    <section className="space-y-8">
      {items.map((e) => (
        <Wrapper.Section key={e.title}>
          <ul className="space-y-4">
            <li>
              <h3 className="text-heading-3 text-gray-600">
                <Text>{e.title}</Text>
              </h3>
            </li>
            {e.items?.map((e, i) => (
              <li
                key={i}
                className="flex flex-col lg:flex-row lg:items-start gap-1 lg:gap-8 text-gray-800"
              >
                <h6 className="text-body-1 lg:flex-1 text-gray-800">
                  <Text>{e.label}</Text>
                </h6>
                {!!e.value && (
                  <p className="text-heading-6 text-gray-700 lg:flex-[2]">
                    {e.value}
                  </p>
                )}
              </li>
            ))}
          </ul>
        </Wrapper.Section>
      ))}
    </section>
  );
}

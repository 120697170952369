import { dateFormats } from "../constants";
import useCurrentLanguage from "./useCurrentLanguage";

export default function useConverter() {
  const locale = useCurrentLanguage();
  // const convertAmount = (amount: number | null | undefined) => {
  //   return (
  //     amount?.toLocaleString(locale, {
  //       style: "currency",
  //       currency: "EUR",
  //     }) ?? ""
  //   );
  // };
  const date = (
    date: string | number | Date | null | undefined,
    format: Intl.DateTimeFormatOptions = dateFormats.onlyDate
  ) => {
    if (!date) return "";
    const value = new Date(date);
    return value.toLocaleString(locale, format);
  };
  const price = (value: string): string => {
    if (!value) return "";
    const isDe = locale === "de";
    const [intValue = "", decimalValue = null] = value.split(".");
    const int = Number(intValue).toLocaleString(locale);
    const decimal = decimalValue?.slice(0, 2);
    const hasDecimal = decimalValue !== null;
    if (!hasDecimal) return int;
    return [int, decimal].join(isDe ? "," : ".");
  };
  return { date, price };
}

import { useContext } from "react";
import { Context } from ".";
import {
  AddressForm
} from "../../../../components";

export default function AddressTab() {
  const { data, setData,addressErrorMsg } = useContext(Context);
  return (
    <section className="space-y-6">
      <AddressForm
              data={data as any}
              setData={setData as any}
              errorMsg={addressErrorMsg}
            />
    </section>
  );
}

import {appPermissions} from "./../constants";
import {Permission} from "./../types";
import useSelector from "./useSelector";

export const usePermission = (...permissionsKey: Permission[]) => {
  const permissions = useSelector(s => s.permissions);
  if (!permissionsKey?.length) return false;
  return !!permissionsKey?.some(
    permissionKey => permissions?.includes(appPermissions[permissionKey]),
  );
};

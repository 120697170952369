import { useContext } from "react";
import toast from "react-hot-toast";
import { Context } from ".";
import {
  Button,
  Form,
  InputGroup,
  SearchAddress,
  Select,
  Text,
  Wrapper,
} from "../../../../components";
import { countries, rules, types } from "../../../../constants";
import { useAxios, useDataState } from "../../../../hooks";
import { userDetailsType } from "../../../../types/user";

export default function General() {
  const { axios, loading } = useAxios();
  const { userData, setUserData } = useContext(Context);
  const [data, setData, setBaseData, isChanged] = useDataState(userData);
  const states = countries.find((e) => e.id === data.country)?.states ?? [];
  const handleSetValue = (key: keyof userDetailsType) => {
    return (value: any) => setData((p) => ({ ...p, [key]: value }));
  };
  const submit = () => {
    const url = `/accountservice/api/users/${userData.userId}/b2b-admin-by-companyid`;
    const body = { ...data };
    body.imageUrl = userData.imageUrl;
    axios.put(url, body).then(() => {
      const message = "b2bManagement.users.details.successMessage";
      toast.success(message);
      setUserData(body);
      setBaseData(body);
    });
  };
  return (
    <Form onSubmit={submit} className="grid grid-cols-1 lg:grid-cols-2 gap-6">
      <Wrapper>
        <Wrapper.Header>
          <h2 className="text-gray-800 text-heading-2">
            <Text>b2bManagement.users.details.basicInfoTitle</Text>
          </h2>
        </Wrapper.Header>
        <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <InputGroup
            label="b2bManagement.users.details.firstName"
            value={data.firstName}
            setValue={handleSetValue("firstName")}
            rules={rules.required}
          />
          <InputGroup
            label="b2bManagement.users.details.lastName"
            value={data.lastName}
            setValue={handleSetValue("lastName")}
            rules={rules.required}
          />
          <Select
            label="b2bManagement.users.details.gender"
            value={data.gender}
            setValue={handleSetValue("gender")}
            items={types.gender}
            rules={rules.required}
          />
          <InputGroup
            label="b2bManagement.users.details.dateOfBirth"
            value={data.dateOfBirth}
            setValue={handleSetValue("dateOfBirth")}
            rules={rules.required}
            type="date"
          />
          <div className="col-span-full">
            <InputGroup
              label="b2bManagement.users.details.personalNumber"
              value={data.personalNumber}
              setValue={handleSetValue("personalNumber")}
              rules={rules.required}
            />
          </div>
          <div className="col-span-full">
            <InputGroup
              label="b2bManagement.users.details.email"
              value={data.emailAddress}
              setValue={handleSetValue("emailAddress")}
              rules={rules.email}
            />
          </div>
        </Wrapper.Body>
      </Wrapper>
      <Wrapper>
        <Wrapper.Header>
          <h2 className="text-gray-800 text-heading-2">
            <Text>b2bManagement.users.details.addressTitle</Text>
          </h2>
        </Wrapper.Header>
        <Wrapper.Body className="space-y-6">
          <SearchAddress setValue={(e) => setData((p) => ({ ...p, ...e }))} />
          <Wrapper.Section className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <InputGroup
              label="b2bManagement.users.details.street"
              value={data.street}
              setValue={handleSetValue("street")}
              rules={rules.required}
            />
            <InputGroup
              label="b2bManagement.users.details.houseNumber"
              value={data.houseNumber}
              setValue={handleSetValue("houseNumber")}
              rules={rules.required}
            />
            <InputGroup
              label="b2bManagement.users.details.postalCode"
              value={data.postalCode}
              setValue={handleSetValue("postalCode")}
              rules={rules.required}
            />
            <InputGroup
              label="b2bManagement.users.details.city"
              value={data.city}
              setValue={handleSetValue("city")}
              rules={rules.required}
            />
            <div className="col-span-full">
              <Select
                label="b2bManagement.users.details.country"
                value={data.country}
                setValue={handleSetValue("country")}
                items={countries}
                rules={rules.required}
              />
            </div>
            <div className="col-span-full">
              <Select
                label="b2bManagement.users.details.state"
                value={data.state}
                setValue={handleSetValue("state")}
                items={states}
                rules={rules.required}
              />
            </div>
            <InputGroup
              label="b2bManagement.users.details.mobileNumber"
              value={data.mobileNumber}
              setValue={handleSetValue("mobileNumber")}
              rules={rules.required}
              type="number"
            />
            <InputGroup
              label="b2bManagement.users.details.phoneNumber"
              value={data.phoneNumber}
              setValue={handleSetValue("phoneNumber")}
              rules={rules.required}
              type="number"
            />
          </Wrapper.Section>
        </Wrapper.Body>
      </Wrapper>
      <Wrapper className="sticky col-span-full bottom-0">
        <Wrapper.Body className="flex items-center justify-end gap-4">
          <Button type="button" variant="white" disabled={loading.update}>
            <Text>b2bManagement.users.details.discardBtn</Text>
          </Button>
          <Button
            type="submit"
            variant="primary"
            loading={loading.update}
            disabled={!isChanged}
          >
            <Text>b2bManagement.users.details.saveChangedBtn</Text>
          </Button>
        </Wrapper.Body>
      </Wrapper>
    </Form>
  );
}

import { cloneDeep } from "lodash";
import { useContext } from "react";
import { Context } from ".";
import { CheckBox, Loading, Toggler, Wrapper } from "../../../../components";
import { useGetPermissions, usePermission } from "../../../../hooks";

export default function RoleInfo() {
  const { permissions, loading } = useGetPermissions();
  const { data, setData } = useContext(Context);
  const updatePermission = usePermission("AS_UpdateRole");
  const createPermissionData = (key: string) => {
    return { clicked: true, disabled: false, code: key };
  };
  const handleChecked = (key: string) => {
    return !!data.permissions?.map((e) => e.code).includes(key);
  };
  const handleToggleChecked = (key: string) => {
    return (value: boolean) => {
      setData((p) => {
        const data = cloneDeep(p);
        data.permissions ??= [];
        if (value) {
          data.permissions.push(createPermissionData(key));
          return data;
        }
        const index = data.permissions.findIndex((e) => e.code === key);
        data.permissions.splice(index, 1);
        return data;
      });
    };
  };
  const handleSelectAll = (key: string) => {
    const permission = permissions.find((e) => e.key === key)?.permissions;
    const activePermissions = data.permissions?.map((e) => e.code);
    return !!permission?.every((e) => activePermissions?.includes(e.key));
  };
  const handleToggleSelectAll = (key: string) => {
    return (value: boolean) => {
      setData((p) => {
        const data = cloneDeep(p);
        const permission =
          permissions.find((e) => e.key === key)?.permissions ?? [];
        const activePermissions = data.permissions ?? [];
        const activePermission = permission.map((e) => e.key);
        if (!value) {
          data.permissions = activePermissions.filter(
            (e) => !activePermission.includes(e.code)
          );
          return data;
        }
        const newPermissions = activePermission
          .filter((key) => !activePermissions.map((e) => e.code).includes(key))
          .map(createPermissionData);
        data.permissions = activePermissions.concat(newPermissions);
        return data;
      });
    };
  };

  if (loading.get) return <Loading.Inline />;
  return (
    <fieldset disabled={!updatePermission}>
      <section className="space-y-8">
        {permissions.map((e) => (
          <Wrapper.Section key={e.key} className="space-y-8">
            <h6 className="text-heading-3 text-gray-600">{e.title}</h6>
            <ul className="grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-2">
              {e.permissions.map((e) => (
                <li key={e.key}>
                  <CheckBox
                    label={e.label}
                    value={handleChecked(e.key)}
                    setValue={handleToggleChecked(e.key)}
                  />
                </li>
              ))}
            </ul>
            <Toggler
              label="userManagement.roles.details.togglePermissions"
              value={handleSelectAll(e.key)}
              setValue={handleToggleSelectAll(e.key)}
            />
          </Wrapper.Section>
        ))}
      </section >
    </fieldset>
  );
}

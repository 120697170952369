import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAxios from "./useAxios";

type permissionType = {
  key: string;
  title: string;
  permissions: {
    key: string;
    label: string;
  }[];
};
export default function useGetPermissions() {
  const { axios, loading } = useAxios();
  const { i18n } = useTranslation();
  const [permissions, setPermissions] = useState<permissionType[]>([]);

  const getPermissions = () => {
    const url = "/accountservice/api/permissions";
    const config = { params: { localization: i18n.language, cache: true } };
    axios.get(url, config).then(({ data }) => {
      setPermissions(data.sections);
    });
  };
  useEffect(getPermissions, [i18n.language]);

  return { permissions, loading };
}

import { cloneDeep } from "lodash";
import {
  Dispatch,
  SetStateAction,
  createContext,
  createElement,
  useEffect,
  useState,
} from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router";
import { Loading, Wizard } from "../../../../components";
import { useAxios, usePermission } from "../../../../hooks";
import BasicInfo from "./BasicInfo";
import Finalize from "./Finalize";
import RoleInfo from "./RoleInfo";
type dataType = {
  id: string;
  name: string;
  description: string;
  permissions: {
    code: string;
    clicked: boolean;
    disabled: boolean;
  }[];
};
type contextType = {
  data: dataType;
  setData: Dispatch<SetStateAction<dataType>>;
  handleSetValue: (key: keyof dataType) => (value: any) => void;
};
export const Context = createContext({} as contextType);
export default function RoleDetails() {
  const steps = [
    {
      label: "userManagement.roles.details.basicInfoTab",
      text: "userManagement.roles.details.basicInfoDesc",
      id: 0,
      component: BasicInfo,
    },
    {
      label: "userManagement.roles.details.roleInfoTab",
      text: "userManagement.roles.details.roleInfoDesc",
      id: 1,
      component: RoleInfo,
    },
    {
      label: "userManagement.roles.details.finalizeTab",
      text: "userManagement.roles.details.finalizeDesc",
      id: 2,
      component: Finalize,
    },
  ];
  const navigate = useNavigate();
  const { roleId } = useParams();
  const isNew = roleId === "add";
  const { axios, loading } = useAxios();
  const [activeIndex, setActiveIndex] = useState(0);
  const [data, setData] = useState({} as dataType);
  const isFirstStep = activeIndex === 0;
  const isLastStep = activeIndex === steps.length - 1;
  const updatePermission = usePermission("AS_UpdateRole");
  const handleSetValue = (key: keyof dataType) => {
    return (value: any) =>
      setData((p) => {
        const data = cloneDeep(p);
        data[key] = value;
        return data;
      });
  };
  const handleCancel = () => {
    if (isFirstStep) return navigate(-1);
    setActiveIndex((p) => p - 1);
  };
  const getData = () => {
    if (isNew) return;
    const url = `/accountservice/api/roles/${roleId}`;
    axios.get(url).then(({ data }) => {
      setData(data);
    });
  };
  const addNew = () => {
    const url = "/accountservice/api/roles";
    const body = { ...data };
    axios.post(url, body).then(({ data: id }) => {
      const url = `/roles/${id}`;
      const message = "userManagement.roles.details.addMessage";
      toast.success(message);
      navigate(url);
      setActiveIndex(0);
    });
  };
  const update = () => {
    const url = `/accountservice/api/roles/${roleId}`;
    const body = { ...data };
    axios.put(url, body).then(() => {
      const message = "userManagement.roles.details.updateMessage";
      toast.success(message);
      setActiveIndex(0);
    });
  };
  const submit = () => {
    if (!isLastStep) return setActiveIndex((p) => p + 1);
    isNew ? addNew() : update();
  };
  useEffect(getData, [isNew, roleId]);
  if (loading.get) return <Loading.Inline />;
  return (
    <Wizard onSubmit={submit} onCancel={handleCancel} activeStep={activeIndex}>
      <Wizard.Items>
        {steps.map((e) => (
          <Wizard.Item
            key={e.id}
            index={e.id}
            label={e.label}
            description={e.text}
          />
        ))}
      </Wizard.Items>
      <Wizard.Tabs>
        <Context.Provider value={{ data, setData, handleSetValue }}>
          {steps.map((e) => (
            <Wizard.Tab
              key={e.id}
              index={e.id}
              label={e.label}
              loading={loading.post || loading.update}
              isLastStep={isLastStep}
              disabled={isLastStep && !updatePermission}
            >
              {createElement(e.component)}
            </Wizard.Tab>
          ))}
        </Context.Provider>
      </Wizard.Tabs>
    </Wizard>
  );
}

const global = {
  noAccessText: 'You have no access to dashboard',
  pleaseLoginAccount: 'Please login with another account',
  lgoinToDashboard: 'Login to dashboard with another account',
  noItems: "No item found",
  pagination: "Showing {{ from }} to {{ to }} of {{ totalItems }} entries",
  searchBox: "Search...",
  wizard: {
    backBtn: "Back",
    nextBtn: "Next",
    submitBtn: "Submit",
  },
  uploadFile: {
    title: "Upload file",
    description: "Drag or drop file",
  },
  channels: {
    digitalStorefront: "Digital Storefront",
    customerTerminal: "Digital Kiosk",
    crm: "CRM Channel",
    erp: "ERP Channel",
    salesTools: "Sales Tools Channel",
    onlineShop: "Online Shop Channel",
  },
  status: {
    pursuit: {
      unknown: "Unknown",
      inProgress: "InProgress",
      fail: "Fail",
      success: "Success",
    },
    productImported: {
      unknown: "unknown",
      newProductCreated: "New Product Created",
      productIsUpdated: "Product is updated",
      fail: "Fail",
    },
  },
  types: {
    gender: {
      male: "Male",
      female: "Female",
    },
    user: {
      none: "None",
      admin: "Admin",
      user: "User",
    },
  },
  locations: {
    street: "Street",
    houseNumber: "House Number",
    postalCode: "Postal Code",
    city: "City",
    country: "Country",
    state: "State",
    location: "Location",
    locationOnMap: "Location On Map",
    address: "Address",
    searchPlaceHolder: "Search address",
    name: "Name",
  },
};
export default global;

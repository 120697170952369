import { cloneDeep } from "lodash";
import {
  Dispatch,
  SetStateAction,
  createContext,
  createElement,
  useState,
} from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { Wizard } from "../../../../components";
import { useAxios, useSearchAddress } from "../../../../hooks";
import Address from "./Address";
import BasicInfo from "./BasicInfo";
import CompanyInfo from "./CompanyInfo";
import Finalize from "./Finalize";
import { ValidateAddress } from "../../../../methods";

type dataType = {
  companyId: string;
  companyName: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  personalNumber: string;
  dateOfBirth: string;
  gender: string;
  street: string;
  houseNumber: string;
  postalCode: string;
  city: string;
  state: string;
  country: string;
  mobileNumber: string;
  phoneNumber: string;
  address: any;
};

type contextType = {
  data: dataType;
  setData: Dispatch<SetStateAction<dataType>>;
  handleSetValue: (key: keyof dataType) => (value: any) => void;
  addressErrorMsg: string;
};

export const Context = createContext({} as contextType);
export default function CreateUser() {
  const steps = [
    {
      label: "b2bManagement.users.create.companyInfoTab",
      text: "b2bManagement.users.create.companyInfoDesc",
      id: 0,
      component: CompanyInfo,
    },
    {
      label: "b2bManagement.users.create.basicInfoTab",
      text: "b2bManagement.users.create.basicInfoDesc",
      id: 1,
      component: BasicInfo,
    },
    {
      label: "b2bManagement.users.create.addressTab",
      text: "b2bManagement.users.create.addressDesc",
      id: 2,
      component: Address,
    },
    {
      label: "b2bManagement.users.create.finalizeTab",
      text: "b2bManagement.users.create.finalizeDesc",
      id: 3,
      component: Finalize,
    },
  ];
  const navigate = useNavigate();
  const { axios, loading } = useAxios();
  const [activeIndex, setActiveIndex] = useState(0);
  const [data, setData] = useState({ country: "DE" } as dataType);
  const isFirstStep = activeIndex === 0;
  const isLastStep = activeIndex === steps.length - 1;
  const isLocationStep = activeIndex === 2;
  const [addressErrorMsg, setAddressErrorMasg] = useState("");
  const searchAddress = useSearchAddress();
  const [validatingAddress, setValidatingAddress] = useState(false);

  const handleSetValue = (key: keyof dataType) => {
    return (value: any) =>
      setData((p) => {
        const data = cloneDeep(p);
        data[key] = value;
        return data;
      });
  };

  const handleCancel = () => {
    if (isFirstStep) return navigate(-1);
    setActiveIndex((p) => p - 1);
  };

  const validateAddress = async () => {
    setAddressErrorMasg("");
    setValidatingAddress(true);
    await ValidateAddress(data.address as any, searchAddress).then(res => {
      if (!res?.selected && !res.suggested) {
        setAddressErrorMasg("Address is not valid");
      }
      if (res.selected) {
        setData({
          ...data,
          address: {
            ...res.selected,
            name: data.address.name,
            number: data.address.number,
          },
        });
        setActiveIndex((p) => p + 1)
      }
      if (res.suggested) {
        setData({
          ...data,
          address: {
            ...res.suggested,
            name: data.address.name,
            number: data.address.number,
          },
        });
        setAddressErrorMasg(
          "Address has been changed please check before submitting",
        );
      }
    }).finally(() => {
      setValidatingAddress(false);
    });
  }

  const submit = async () => {
    if (isLocationStep && data.address) { await validateAddress() }
    else if (isLastStep) {
      const url = "/accountservice/api/users/b2b-admin";
      const body = { ...data };
      axios.post(url, body).then(({ data: id }) => {
        const url = `/users/${data.companyId}/${id}`;
        const message = "b2bManagement.users.create.successMessage";
        toast.success(message);
        navigate(url);
      });
    }
    else return setActiveIndex((p) => p + 1);
  };

  return (
    <Wizard onSubmit={submit} onCancel={handleCancel} activeStep={activeIndex}>
      <Wizard.Items>
        {steps.map((e) => (
          <Wizard.Item
            key={e.id}
            index={e.id}
            label={e.label}
            description={e.text}
          />
        ))}
      </Wizard.Items>
      <Wizard.Tabs>
        <Context.Provider value={{ data, setData, handleSetValue, addressErrorMsg }}>
          {steps.map((e) => (
            <Wizard.Tab
              key={e.id}
              index={e.id}
              label={e.label}
              loading={loading.post || validatingAddress}
              isLastStep={isLastStep}
            >
              {createElement(e.component)}
            </Wizard.Tab>
          ))}
        </Context.Provider>
      </Wizard.Tabs>
    </Wizard>
  );
}

const layout = {
  dashboard: "DASHBOARD DE",
  b2bManagement: {
    root: "B2B MANAGEMENT DE",
    companies: "B2B Companies DE",
    users: "B2B Users DE",
  },
  productManagement: {
    root: "PRODUCT MANAGEMENT DE",
    providers: {
      root: "PROVIDERS DE",
      providerProducts: "Provider Products DE",
      importProducts: "Import Products DE",
    },
  },
  userManagement: {
    root: "USER MANAGEMENT DE",
    allPlatformUsers: "All Platform users DE",
    userGroups: "User Groups DE",
    roles: "Roles DE",
  },
};
export default layout;

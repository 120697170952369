import { useContext, useEffect, useState } from "react";
import { Context } from ".";
import { InputGroup, Loading, Select } from "../../../../components";
import { rules } from "../../../../constants";
import { useAxios } from "../../../../hooks";
import { roleItemType } from "../../../../types/role";

export default function Information() {
  const { data, handleSetValue } = useContext(Context);
  const { axios, loading } = useAxios();
  const [roles, setRoles] = useState<roleItemType[]>([]);
  const rolesItems = roles.map((e) => ({ name: e.name, id: e.id }));
  const getData = () => {
    const url = "/accountservice/api/roles";
    const config = { params: { pageSize: 100, pageNumber: 1 } };
    axios.get(url, config).then(({ data }) => {
      setRoles(data.items);
    });
  };
  useEffect(getData, []);
  if (loading.get) return <Loading.Inline />;
  return (
    <section className="space-y-6">
      <InputGroup
        label="userManagement.userGroups.details.userGroupName"
        value={data.name}
        setValue={handleSetValue("name")}
        rules={rules.required}
      />
      <Select
        label="userManagement.userGroups.details.role"
        value={data.roles}
        setValue={handleSetValue("roles")}
        items={rolesItems}
        rules={rules.required}
        multiple
      />
      <InputGroup
        as="textarea"
        label="userManagement.userGroups.details.shortDescription"
        value={data.description}
        setValue={handleSetValue("description")}
        rules={rules.required}
      />
    </section>
  );
}

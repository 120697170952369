const layout = {
  dashboard: "DASHBOARD",
  b2bManagement: {
    root: "B2B MANAGEMENT",
    companies: "B2B Companies",
    users: "B2B Users",
  },
  productManagement: {
    root: "PRODUCT MANAGEMENT",
    providers: {
      root: "PROVIDERS",
      providerProducts: "Provider Products",
      importProducts: "Import Products",
    },
  },
  userManagement: {
    root: "USER MANAGEMENT",
    allPlatformUsers: "All Platform users",
    userGroups: "User Groups",
    roles: "Roles",
  },
};
export default layout;

import { useContext } from "react";
import { Context } from ".";
import { InputGroup } from "../../../../components";
import { rules } from "../../../../constants";
import { usePermission } from "../../../../hooks";

export default function BasicInfo() {
  const { data, handleSetValue } = useContext(Context);
  const updatePermission = usePermission("AS_UpdateRole");
  return (
    <fieldset disabled={!updatePermission}>
      <section className="space-y-6">
        <InputGroup
          label="userManagement.roles.details.roleName"
          value={data.name}
          setValue={handleSetValue("name")}
          rules={rules.required}
        />
        <InputGroup
          as="textarea"
          label="userManagement.roles.details.shortDescription"
          value={data.description}
          setValue={handleSetValue("description")}
          rules={rules.required}
        />
      </section>
    </fieldset>
  );
}

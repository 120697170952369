import { useContext } from "react";
import { Context } from ".";
import { Text, Wrapper } from "../../../../../components";
import { languages, types } from "../../../../../constants";
export default function Finalize() {
  const { data } = useContext(Context);
  const items = [
    {
      title: "Basic info EN",
      items: [
        {
          label: "Provider name EN",
          value: types.productProviders.find(
            (e) => `${e.id}` === `${data.providerName}`
          )?.name,
        },
        {
          label: "Language EN",
          value: languages.find((e) => e.id === data.language)?.name,
        },
        { label: "Configuration version EN", value: data.configurationVersion },
      ],
    },
  ];
  return (
    <section className="space-y-8">
      {items.map((e) => (
        <Wrapper.Section key={e.title}>
          <ul className="space-y-4">
            <li>
              <h3 className="text-heading-3 text-gray-600">
                <Text>{e.title}</Text>
              </h3>
            </li>
            {e.items.map((e) => (
              <li
                key={e.label}
                className="flex flex-col lg:flex-row lg:items-start gap-1 lg:gap-8 text-gray-800"
              >
                <h6 className="text-body-1 lg:flex-1">
                  <Text>{e.label}</Text>
                </h6>
                <p className="text-heading-6 lg:flex-[2]">{e.value}</p>
              </li>
            ))}
          </ul>
        </Wrapper.Section>
      ))}
    </section>
  );
}

import { isEmpty } from "lodash";
import { Fragment } from "react";
import { twMerge } from "tailwind-merge";
import { childrenProps } from "../types";
import Loading from "./_Loading";
import NoItems from "./_NoItems";
type tableProps = {
  loading?: boolean;
  className?: string;
} & childrenProps;
function Table({ children, loading, className }: tableProps) {
  const hasChildren = !isEmpty(children);
  return (
    <div className="w-full overflow-auto">
      <table
        className={twMerge(
          "table whitespace-nowrap text-left min-w-full border-separate border-spacing-y-4 [&_tr]:rounded [&_tr]:bg-white [&_td>*]:w-max [&_td]:p-4 [&_td:first-child]:rounded-l [&_td:last-child]:rounded-r [&_td:first-child]:text-left [&_td:last-child]:text-right",
          "[&_tr]:shadow-high",
          className
        )}
      >
        <tbody>
          {loading ? (
            <Loading.Tr />
          ) : (
            <Fragment>
              {hasChildren ? (
                children
              ) : (
                <tr>
                  <td colSpan={20}>
                    <NoItems />
                  </td>
                </tr>
              )}
            </Fragment>
          )}
        </tbody>
      </table>
    </div>
  );
}
export default Table;

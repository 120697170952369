import { cloneDeep } from "lodash";
import { Fragment, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Context } from "..";
import {
  Button,
  Drawer,
  Form,
  Select,
  Text,
  Toggler,
} from "../../../../../components";
import { languages, rules } from "../../../../../constants";
import { useAxios, useDataState, usePermission, useTranslate } from "../../../../../hooks";
import { Company, channelType, toggleProps } from "../../../../../types";
import { companyChannel } from "../../../../../types/company";

type channelDetailsProps = {
  channel: channelType;
} & toggleProps;
export default function ChannelDetails({
  channel,
  isOpen,
  toggle,
}: channelDetailsProps) {
  const translate = useTranslate();
  const { axios, loading } = useAxios();
  const { companyData, setCompanyData } = useContext(Context);
  const [active, setActive] = useState(false);
  const updatePermission = usePermission("AS_UpdateCompanyChannel");
  const [data, setData, setBaseData, isChanged] =
    useDataState<Company.Channel | null>(null);
  const getData = () => {
    const initData = {
      availableCurrencies: channel.currencies,
      availableLanguages: channel.languages,
      channelDefaultLanguage: channel.defaultLanguage,
      channelNameTranslates: languages.map((language) => ({
        name: translate(channel.name, { lng: language.id }),
        language: language.id,
      })),
      code: channel.code,
    };
    const data = companyData.channels.find((e) => e.code === channel.code);
    setActive(!!data);
    setBaseData(data ?? initData);
  };
  const handleSetValue = (key: keyof companyChannel) => {
    return (value: any) =>
      setData((p) => {
        const data = cloneDeep(p);
        if (!data) return data;
        data[key] = value;
        return data;
      });
  };
  const toggleActive = () => {
    const url = `/accountservice/api/companies/${companyData.companyId}/channel`;
    const body = {
      channelCode: data?.code,
      channelNameTranslates: data?.channelNameTranslates,
      availableLanguages: data?.availableLanguages,
      channelDefaultLanguage: data?.channelDefaultLanguage,
      availableCurrencies: data?.availableCurrencies,
    };
    axios.post(url, body).then(() => {
      const message = "b2bManagement.companies.details.successMessage";
      toast.success(message);
      setCompanyData((p) => {
        if (!data || !p) return p;
        const company = cloneDeep(p);
        company.channels.push(data);
        return company;
      });
      setActive(true);
      setBaseData(data);
    });
  };
  const submit = () => {
    if (!data) return;
    const url = `/accountservice/api/companies/${companyData.companyId}/channel`;
    const body = {
      channelCode: data.code,
      channelNameTranslates: data.channelNameTranslates,
      availableLanguages: data.availableLanguages,
      channelDefaultLanguage: data.channelDefaultLanguage,
      availableCurrencies: data.availableCurrencies,
    };
    axios.put(url, body).then(() => {
      const message = "b2bManagement.companies.details.successMessage";
      toast.success(message);
      setBaseData(data);
      setCompanyData((p) => {
        if (!p) return p;
        const company = cloneDeep(p);
        const code = body.channelCode;
        const channelIndex = company.channels.findIndex((e) => e.code === code);
        if (channelIndex === -1) return p;
        company.channels[channelIndex] = data;
        return company;
      });
    });
  };
  useEffect(getData, [channel]);
  return (
    <Drawer as={Form} onSubmit={submit} isOpen={isOpen} toggle={toggle}>
      <fieldset disabled={!updatePermission}>
        <Drawer.Menu>
          <Drawer.Header>
            <h2 className="text-heading-2 text-gray-800">
              <Text>{channel.name}</Text>
            </h2>
          </Drawer.Header>
          <Drawer.Body className="space-y-6">
            {!!data && (
              <Fragment>
                <Select
                  label="b2bManagement.companies.details.channelAvailableLanguages"
                  value={data.availableLanguages}
                  setValue={handleSetValue("availableLanguages")}
                  items={channel.languages.map((e) => ({ name: e, id: e }))}
                  rules={rules.required}
                  multiple
                />
                <Select
                  label="b2bManagement.companies.details.channelDefaultLanguage"
                  value={data.channelDefaultLanguage}
                  setValue={handleSetValue("channelDefaultLanguage")}
                  rules={rules.required}
                  items={data.availableLanguages?.map((e) => ({
                    name: e,
                    id: e,
                  }))}
                />
                <Select
                  label="b2bManagement.companies.details.channelAvailableCurrencies"
                  value={data.availableCurrencies}
                  setValue={handleSetValue("availableCurrencies")}
                  items={channel.currencies.map((e) => ({ name: e, id: e }))}
                  rules={rules.required}
                  multiple
                />
                <Toggler
                  label="b2bManagement.companies.details.channelActivate"
                  value={active}
                  setValue={toggleActive}
                  disabled={active || loading.post}
                />
              </Fragment>
            )}
          </Drawer.Body>
          {active && updatePermission && (
            <Drawer.Footer className="flex items-center justify-between gap-4">
              <Button
                type="button"
                variant="gray"
                disabled={loading.update}
                onClick={toggle}
              >
                <Text>b2bManagement.companies.details.cancelBtn</Text>
              </Button>
              <Button
                type="submit"
                variant="primary"
                loading={loading.update}
                disabled={!isChanged}
              >
                <Text>b2bManagement.companies.details.submitBtn</Text>
              </Button>
            </Drawer.Footer>
          )}
        </Drawer.Menu>
      </fieldset>
    </Drawer>
  );
}

import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { Context } from ".";
import {
  AddressForm,
  Button,
  Form,
  InputGroup,
  Map,
  Text,
  Wrapper,
} from "../../../../components";
import { useAxios, useDataState, usePermission, useSearchAddress } from "../../../../hooks";
import { companyDetailsType } from "../../../../types/company";
import { rules } from "../../../../constants";
import { ValidateAddress } from "../../../../methods";

export default function Profile() {
  const { axios, loading } = useAxios();
  const { companyData, setCompanyData } = useContext(Context);
  const [data, setData, setBaseData, isChanged] = useDataState(companyData);
  const [addressErrorMsg, setAddressErrorMasg] = useState("");
  const [validatingAddress, setValidatingAddress] = useState(false);
  const searchAddress = useSearchAddress();
  const updatePermission = usePermission("AS_UpdateCompany");

  const handleSetValue = (key: keyof companyDetailsType) => {
    return (value: any) => setData((p) => ({ ...p, [key]: value }));
  };

  const onSubmit = () => {
    setAddressErrorMasg("");
    if (!data.address) submit();
    else {
      setValidatingAddress(true);
      ValidateAddress(data.address as any, searchAddress).then(res => {
        if (!res?.selected && !res.suggested) {
          setAddressErrorMasg("Address is not valid");
        }
        if (res.selected) {
          setData({
            ...data,
            address: {
              ...res.selected,
              name: data.address.name,
              number: data.address.number,
            },
          });
          submit();
        }
        if (res.suggested) {
          setData({
            ...data,
            address: {
              ...res.suggested,
              name: data.address.name,
              number: data.address.number,
            },
          });
          setAddressErrorMasg(
            "Address has been changed please check before submitting",
          );
        }
      }).finally(() => { setValidatingAddress(false); })
    }
  }

  const submit = () => {
    const url = `/accountservice/api/companies/${companyData.companyId}`;
    const body = { ...data };
    body.logo = companyData.logo;
    axios.put(url, body).then(() => {
      const message = "b2bManagement.companies.details.successMessage";
      toast.success(message);
      setCompanyData(body);
      setBaseData(body);
    });
  };

  return (
    <fieldset disabled={!updatePermission}>
      <Form onSubmit={onSubmit} className="space-y-6">
        <Wrapper>
          <Wrapper.Header>
            <h2 className="text-gray-800 text-heading-2">
              <Text>b2bManagement.companies.details.basicInfoTitle</Text>
            </h2>
          </Wrapper.Header>
          <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <div className="space-y-6">
              <InputGroup
                label="b2bManagement.companies.details.name"
                value={data.name}
                setValue={handleSetValue("name")}
                rules={rules.required}
              />
              <InputGroup
                label="b2bManagement.companies.details.mobileNumber"
                value={data.mobileNumber}
                setValue={handleSetValue("mobileNumber")}
              />
              <InputGroup
                label="b2bManagement.companies.details.phoneNumber"
                value={data.phoneNumber}
                setValue={handleSetValue("phoneNumber")}
              />
            </div>
            <InputGroup
              as="textarea"
              label="b2bManagement.companies.details.description"
              value={data.description}
              setValue={handleSetValue("description")}
            />
          </Wrapper.Body>
        </Wrapper>
        <Wrapper>
          <Wrapper.Header>
            <h2 className="text-gray-800 text-heading-2">
              <Text>b2bManagement.companies.details.locationTitle</Text>
            </h2>
          </Wrapper.Header>
          <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <section className="space-y-6">
              <AddressForm
                data={data as any}
                setData={setData as any}
                errorMsg={addressErrorMsg}
              />
            </section>
            <Map
              title={data.name}
              address={data.address}
              className="w-full aspect-image lg:aspect-auto lg:self-stretch rounded"
            />
          </Wrapper.Body>
        </Wrapper>
        {updatePermission &&
          <Wrapper className="sticky bottom-0">
            <Wrapper.Body className="flex items-center justify-end gap-4">
              <Button
                as={Link}
                replace
                to="/companies"
                type="button"
                variant="white"
                disabled={loading.update}
              >
                <Text>b2bManagement.companies.details.discardBtn</Text>
              </Button>
              <Button
                type="submit"
                variant="primary"
                loading={loading.update || validatingAddress}
                disabled={!isChanged}
              >
                <Text>b2bManagement.companies.details.saveChangedBtn</Text>
              </Button>
            </Wrapper.Body>
          </Wrapper>
        }
      </Form>
    </fieldset>
  );
}

import { FormEvent, ReactNode } from "react";

type formProps = {
  children: ReactNode;
  onSubmit: () => void;
  className?: string;
};
export default function Form({ onSubmit, className, children }: formProps) {
  const submit = (e: FormEvent) => {
    e.preventDefault();
    const form = e.currentTarget;
    const inputGroups = form.querySelectorAll(".input-group");
    const formControls = [...inputGroups].map((e) => ({
      inputGroup: e,
      // @ts-ignore: Unreachable code error
      valid: !!e.querySelector(".form-control")?.onValid?.(),
    }));
    const everyValid = formControls.every((e) => !!e.valid);
    if (everyValid) return onSubmit();
    const inputGroup = formControls.find((e) => !e.valid)?.inputGroup;
    inputGroup?.scrollIntoView({ block: "center", behavior: "smooth" });
  };
  return (
    <form className={className} onSubmit={submit}>
      {children}
    </form>
  );
}

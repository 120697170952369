import { cloneDeep } from "lodash";
import {
  Dispatch,
  SetStateAction,
  createContext,
  createElement,
  useMemo,
  useState,
} from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router";
import { Wizard } from "../../../../../components";
import { useAxios } from "../../../../../hooks";
import BasicInfo from "./BasicInfo";
import File from "./File";
import Finalize from "./Finalize";
type dataType = {
  uri: string;
  providerName: string;
  configurationVersion: string;
  language: string;
};
type contextType = {
  data: dataType;
  setData: Dispatch<SetStateAction<dataType>>;
  handleSetValue: (key: keyof dataType) => (value: any) => void;
};
export const Context = createContext({} as contextType);
export default function CreateProviderProduct() {
  const steps = [
    {
      label: "Basic info EN",
      text: "nameName, language, ... EN",
      id: 0,
      component: BasicInfo,
    },
    {
      label: "File EN",
      text: "Choose file EN",
      id: 1,
      component: File,
    },
    {
      label: "Finalize EN",
      text: "Finalize information EN",
      id: 2,
      component: Finalize,
    },
  ];
  const navigate = useNavigate();
  const { axios, loading } = useAxios();
  const [activeIndex, setActiveIndex] = useState(0);
  const [data, setData] = useState({} as dataType);
  const isFirstStep = activeIndex === 0;
  const isFileStep = activeIndex === 1;
  const isLastStep = activeIndex === steps.length - 1;
  const handleSetValue = (key: keyof dataType) => {
    return (value: any) =>
      setData((p) => {
        const data = cloneDeep(p);
        data[key] = value;
        return data;
      });
  };
  const handleCancel = () => {
    if (isFirstStep) return navigate(-1);
    setActiveIndex((p) => p - 1);
  };
  const handleDisabled = useMemo(() => {
    return activeIndex === 1 && !data.uri;
  }, [activeIndex, data]);
  const validate = () => {
    const url = "/productservice/api/providerproductimports/validate";
    const config = { params: { ...data } };
    axios.get(url, config).then(() => {
      setActiveIndex((p) => p + 1);
    });
  };
  const submit = () => {
    if (isFileStep) return validate();
    if (!isLastStep) return setActiveIndex((p) => p + 1);
    const url = "/productservice/api/providerproductimports";
    const body = { ...data };
    axios.post(url, body).then(({ data: id }) => {
      const url = `/provider-products/${id}`;
      const message = "successMessage EN";
      toast.success(message);
      navigate(url);
    });
  };
  return (
    <Wizard onSubmit={submit} onCancel={handleCancel} activeStep={activeIndex}>
      <Wizard.Items>
        {steps.map((e) => (
          <Wizard.Item
            key={e.id}
            index={e.id}
            label={e.label}
            description={e.text}
          />
        ))}
      </Wizard.Items>
      <Wizard.Tabs>
        <Context.Provider value={{ data, setData, handleSetValue }}>
          {steps.map((e) => (
            <Wizard.Tab
              key={e.id}
              index={e.id}
              label={e.label}
              loading={loading.post || loading.get}
              disabled={handleDisabled}
              isLastStep={isLastStep}
            >
              {createElement(e.component)}
            </Wizard.Tab>
          ))}
        </Context.Provider>
      </Wizard.Tabs>
    </Wizard>
  );
}

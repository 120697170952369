import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { Context } from "..";
import {
  Button,
  Drawer,
  Form,
  Icon,
  InputGroup,
  Text,
} from "../../../../../components";
import { rules } from "../../../../../constants";
import { useAxios } from "../../../../../hooks";
import { toggleProps } from "../../../../../types";

type Props = {
  onSubmitted: () => void;
} & toggleProps;
type Data = {
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
};
export default function CreateUser({ isOpen, toggle, onSubmitted }: Props) {
  const { companyData } = useContext(Context);
  const { axios, loading } = useAxios();
  const [data, setData] = useState<Data>({});
  const handleSetValue = (key: keyof Data) => {
    return (value: any) => setData((p) => ({ ...p, [key]: value }));
  };
  const submit = () => {
    const url = `/accountservice/api/users/company/${companyData.companyId}`;
    const body = {
      userType: 4,
      userGroupId: null,
      firstName: data.firstName,
      lastName: data.lastName,
      emailAddress: data.emailAddress,
      dateOfBirth: null,
      gender: 0,
      address: null,
      mobileNumber: null,
      phoneNumber: null,
    };
    axios.post(url, body).then(() => {
      const message =
        "b2bManagement.companies.details.addAdminUserSuccessMessage";
      toast.success(message);
      setData({});
      toggle();
      onSubmitted();
    });
  };
  return (
    <Drawer as={Form} onSubmit={submit} isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="flex items-center">
          <h2 className="flex-1 text-heading-2 text-gray-800">
            <Text>b2bManagement.companies.details.addAdminUserTitle</Text>
          </h2>
          <Button type="button" variant="gray" onClick={toggle}>
            <Icon name="CloseSquare" />{" "}
            <Text>b2bManagement.companies.details.closeBtn</Text>
          </Button>
        </Drawer.Header>
        <Drawer.Body className="space-y-6">
          <InputGroup
            label="b2bManagement.companies.details.userFirstName"
            value={data.firstName}
            setValue={handleSetValue("firstName")}
            rules={rules.required}
          />
          <InputGroup
            label="b2bManagement.companies.details.userLastName"
            value={data.lastName}
            setValue={handleSetValue("lastName")}
            rules={rules.required}
          />
          <InputGroup
            label="b2bManagement.companies.details.userEmailAddress"
            value={data.emailAddress}
            setValue={handleSetValue("emailAddress")}
            rules={rules.email}
          />
        </Drawer.Body>
        <Drawer.Footer className="flex items-center justify-between gap-4">
          <Button
            type="button"
            variant="gray"
            disabled={loading.post}
            onClick={toggle}
          >
            <Text>b2bManagement.companies.details.cancelBtn</Text>
          </Button>
          <Button type="submit" variant="primary" loading={loading.post}>
            <Text>b2bManagement.companies.details.submitBtn</Text>
          </Button>
        </Drawer.Footer>
      </Drawer.Menu>
    </Drawer>
  );
}

const userGroups = {
  list: {
    addNewBtn: "Add new",
    editBtn: "Edit",
    active: "Active",
  },
  details: {
    infoTab: "Information",
    infoDesc: "Name, Description ...",
    finalizeTab: "Finalize",
    finalizeDesc: "Finalize information",

    userGroupName: "User group name",
    role: "Role",
    shortDescription: "Short description",

    backBtn: "Back",
    nextBtn: "Next",
    submitBtn: "Submit",
    addMessage: "User Group has been added successfully.",
    updateMessage: "User Group has been updated successfully.",
  },
};
export default userGroups;

import { Address } from "../types";

const formatLocalAddress = (
  street: string,
  number: string | number,
  postalCode: string | number,
  city: string,
  state: string,
  country?: string,
): string => {
  const addressParts = [
    `${street} ${number}`,
    `${postalCode} ${city}`,
    state,
    country,
  ];
  return addressParts.join(", ");
};

const getAddressFormat = (address: Address): string => {
  if (!address) {
    return "";
  }
  const {street, number, postalCode, city, state, country} = address;
  return formatLocalAddress(
    street ?? "",
    number ?? "",
    postalCode ?? "",
    city ?? "",
    state ?? "",
    country || "Germany",
  );
};

type res = {
  selected: null | Address;
  suggested: null | Address;
};

export const ValidateAddress = async (address: Address, searchAddress:any) => {
  const query = getAddressFormat(address);
  const result: res = {
    selected: null,
    suggested: null,
  };
  if (query)
    await searchAddress({query, countrySet: address?.countryCode || "de"}).then(
      (items:any) => {
        if (items[0]) {
          const firstResult = items[0];
          if (
            address.postalCode === firstResult.address.postalCode &&
            address.city === firstResult.address.city &&
            address.street === firstResult.address.street &&
            address.state === firstResult.address.state &&
            address.countryCode === firstResult.address.countryCode
          ) {
            result.selected = firstResult.address;
            result.suggested = null;
          } else {
            result.selected = null;
            result.suggested = firstResult.address;
          }
        }
      },
    );
  return result;
};

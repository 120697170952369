import {
  ComponentProps,
  createContext,
  ElementType,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from "react";
import { twMerge } from "tailwind-merge";
import { asProps, sizeType, toggleProps } from "../types";
import Portal from "./_Portal";

type drawerMainProps = {
  className?: string;
  children: ReactNode;
};
type drawerContextType = {
  size: sizeType;
  isOpen: boolean;
};
type drawerProps = toggleProps & { size?: sizeType };
const DrawerContext = createContext({} as drawerContextType);
function Drawer<E extends ElementType = "div">({
  as,
  isOpen,
  toggle,
  className = "",
  children,
  size = null,
  ...props
}: drawerMainProps & drawerProps & asProps<E> & ComponentProps<E>) {
  const Component = as || "div";
  return (
    <Portal>
      <Component
        className={twMerge(
          "drawer group fixed inset-0 flex z-30 bg-black/20 opacity-0 pointer-events-none transition-opacity [&.active]:opacity-100 [&.active]:pointer-events-auto",
          isOpen && "active",
          className
        )}
        {...props}
      >
        <button
          type="button"
          onClick={toggle}
          className="h-full flex-1 opacity-0 cursor-default"
        />
        <DrawerContext.Provider value={{ size, isOpen }}>
          {children}
        </DrawerContext.Provider>
      </Component>
    </Portal>
  );
}
function DrawerMenu({ children, className = "" }: drawerMainProps) {
  const drawerMenuRef = useRef<HTMLDivElement>(null);
  const isDragging = useRef<boolean>(false);
  const startX = useRef<number>(0);
  const startWidth = useRef<number>(0);
  const { size, isOpen } = useContext(DrawerContext);
  const handleSizeWidth = useMemo<string>(() => {
    if (!size) return "w-[32.5rem]";
    const sizes: { [S in Exclude<sizeType, null>]: string } = {
      sm: "",
      md: "",
      lg: "w-[47.5rem]",
      xl: "w-[60rem]",
    };
    return sizes[size];
  }, [size]);
  const updateWidth = (value: number) => {
    const width = value >= 100 ? 100 : value <= 0 ? 0 : value;
    drawerMenuRef.current?.style.setProperty("width", `${width}%`);
  };
  const dragStart = (e: any) => {
    isDragging.current = true;
    startX.current = e.pageX || e.touches?.[0].pageX;
    const drawerMenuWidth = drawerMenuRef.current?.clientWidth ?? 0;
    const width = (drawerMenuWidth / window.innerWidth) * 100;
    startWidth.current = parseInt(`${width}`);
    drawerMenuRef.current?.classList.add("dragging");
  };
  const dragStop = () => {
    isDragging.current = false;
    drawerMenuRef.current?.classList.remove("dragging");
  };
  const dragging = (e: any) => {
    if (!isDragging.current) return;
    const delta = startX.current - (e.pageX || e.touches?.[0].pageX);
    const width = startWidth.current + (delta / window.innerWidth) * 100;
    updateWidth(width);
  };
  useEffect(() => {
    if (isOpen) return;
    drawerMenuRef.current?.style.removeProperty("width");
  }, [isOpen]);
  useEffect(() => {
    document.addEventListener("mouseup", dragStop);
    document.addEventListener("mousemove", dragging);
    document.addEventListener("touchend", dragStop);
    document.addEventListener("touchmove", dragging);
    return () => {
      document.removeEventListener("mouseup", dragStop);
      document.removeEventListener("mousemove", dragging);
      document.removeEventListener("touchend", dragStop);
      document.removeEventListener("touchmove", dragging);
    };
  }, []);
  return (
    <div
      ref={drawerMenuRef}
      className={twMerge(
        handleSizeWidth,
        "relative h-full flex flex-col bg-white rounded-l px-6 translate-x-full transition-[transform,width] [&.dragging]:transition-none group-[.drawer.active]:translate-x-0 min-w-[min(30rem,95%)] max-w-[95%]",
        className
      )}
    >
      {children}
      <button
        type="button"
        className="absolute w-1 h-10 bg-gray-300 rounded-full top-[calc(50%-1rem)] left-2 cursor-grab"
        onMouseDown={dragStart}
        onTouchStart={dragStart}
      />
    </div>
  );
}
function DrawerHeader({ className = "", children }: drawerMainProps) {
  return (
    <div className={twMerge("py-3 text-start border-b border-gray", className)}>
      {children}
    </div>
  );
}
function DrawerBody({ className = "", children }: drawerMainProps) {
  return (
    <div className={twMerge("py-3 flex-1 overflow-auto", className)}>
      {children}
    </div>
  );
}
function DrawerFooter({ className = "", children }: drawerMainProps) {
  return (
    <div className={twMerge("w-full py-3 border-t border-gray", className)}>
      {children}
    </div>
  );
}
Drawer.Menu = DrawerMenu;
Drawer.Header = DrawerHeader;
Drawer.Body = DrawerBody;
Drawer.Footer = DrawerFooter;
export default Drawer;

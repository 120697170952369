const roles = {
  list: {
    addNewBtn: "Add new role DE",
    editBtn: "Edit DE",
    active: "Active DE",
  },
  details: {
    basicInfoTab: "Basic information DE",
    basicInfoDesc: "Name, Description ... DE",
    roleInfoTab: "Role information DE",
    roleInfoDesc: "Authorizations DE",
    finalizeTab: "Finalize DE",
    finalizeDesc: "Finalize information DE",

    roleName: "Role name DE",
    shortDescription: "Short description DE",

    togglePermissions: "Select all/ Deselect All DE",

    backBtn: "Back DE",
    nextBtn: "Next DE",
    submitBtn: "Submit DE",
    addMessage: "Role has been added successfully. DE",
    updateMessage: "Role has been updated successfully. DE",
  },
};
export default roles;

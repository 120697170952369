import { useMemo } from "react";
import { twMerge } from "tailwind-merge";
import { convertAddress } from "../methods";
import { Address, ClassName } from "../types";

type mapProps = {
  title: string;
  address: Address | null;
} & ClassName;
export default function Map({ title, address, className }: mapProps) {
  const generateSrc = useMemo(() => {
    if (!address) return "";
    const fullAddress = convertAddress(address);
    const query = encodeURIComponent(fullAddress);
    return `https://maps.google.com/maps?q=${query}&t=&z=15&ie=UTF8&iwloc=&output=embed`;
  }, [address]);
  return (
    <iframe
      title={title}
      className={twMerge("bg-gray-200", className)}
      src={generateSrc}
    />
  );
}

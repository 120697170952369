const languages = [
  {
    name: "German",
    id: "de",
  },
  {
    name: "English",
    id: "en",
  },
];
export default languages;

import { useContext, useEffect } from "react";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { Context } from "..";
import {
  Button,
  Form,
  InputGroup,
  Loading,
  Select,
  Text,
  Wrapper,
} from "../../../../../components";
import { languages, rules } from "../../../../../constants";
import { useAxios, useDataState, usePermission } from "../../../../../hooks";
import { Company } from "../../../../../types";

export default function Settings() {
  const { companyData } = useContext(Context);
  const companyId = companyData.companyId;
  const { axios, loading } = useAxios();
  const updatePermission = usePermission("AS_UpdateCompany");
  const [data, setData, setBaseData, isChanged] =
    useDataState<Company.Settings | null>(null);
  const handleSetValue = (key: keyof Company.Settings) => {
    return (value: any) =>
      setData((p) => ({ ...p, [key]: value } as Company.Settings));
  };
  const getData = () => {
    const url = `/accountservice/api/companysettings/${companyId}`;
    axios.get(url).then(({ data }) => {
      setBaseData(data);
    });
  };
  const submit = () => {
    if (!data) return;
    const url = `/accountservice/api/companysettings/${companyId}`;
    const body = { ...data };
    axios.post(url, body).then(() => {
      const message = "b2bManagement.companies.details.settingSuccessMessage";
      toast.success(message);
      setBaseData(body);
    });
  };
  useEffect(getData, []);
  if (loading.get) return <Loading.Inline />;
  if (!data) return null;
  return (
    <fieldset disabled={!updatePermission}>
      <Form onSubmit={submit} className="space-y-6">
        <Wrapper>
          <Wrapper.Header>
            <h2 className="text-gray-800 text-heading-2">
              <Text>b2bManagement.companies.details.settingsTab</Text>
            </h2>
          </Wrapper.Header>
          <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <section className="space-y-6">
              <InputGroup
                label="b2bManagement.companies.details.customerNumberPrefix"
                value={data.customerNumberPrefix}
                setValue={handleSetValue("customerNumberPrefix")}
                rules={rules.required}
              />
              <Select
                label="b2bManagement.companies.details.dashboardLanguages"
                value={data?.dashboardLanguages ?? []}
                setValue={handleSetValue("dashboardLanguages")}
                items={languages}
                rules={rules.required}
                multiple
              />
              <Select
                label="b2bManagement.companies.details.defaultDashboardLanguage"
                value={data?.defaultDashboardLanguage}
                setValue={handleSetValue("defaultDashboardLanguage")}
                items={languages.filter((e) =>
                  data?.dashboardLanguages?.includes(e.id)
                )}
                rules={rules.required}
              />
              <Select
                label="b2bManagement.companies.details.contentLanguages"
                value={data?.contentLanguages ?? []}
                setValue={handleSetValue("contentLanguages")}
                items={languages}
                rules={rules.required}
                multiple
              />
              <Select
                label="b2bManagement.companies.details.defaultContentLanguage"
                value={data?.defaultContentLanguage}
                setValue={handleSetValue("defaultContentLanguage")}
                items={languages.filter((e) =>
                  data?.contentLanguages?.includes(e.id)
                )}
                rules={rules.required}
              />
            </section>
          </Wrapper.Body>
        </Wrapper>
        {updatePermission &&
          <Wrapper className="sticky bottom-0">
            <Wrapper.Body className="flex items-center justify-end gap-4">
              <Button
                as={Link}
                replace
                to="/companies"
                type="button"
                variant="white"
                disabled={loading.post}
              >
                <Text>b2bManagement.companies.details.discardBtn</Text>
              </Button>
              <Button
                type="submit"
                variant="primary"
                loading={loading.post}
                disabled={!isChanged}
              >
                <Text>b2bManagement.companies.details.saveChangedBtn</Text>
              </Button>
            </Wrapper.Body>
          </Wrapper>
        }
      </Form>
    </fieldset>
  );
}

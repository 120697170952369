import { ComponentProps, ElementType, ReactNode, useMemo } from "react";
import { twMerge } from "tailwind-merge";
import { Variant, asProps } from "../types";
type badgeType =
  | { fill?: boolean; outline?: never }
  | { fill?: never; outline?: boolean };
type defaultBadgeProps = {
  children: ReactNode;
  className?: string;
  variant?: Variant;
} & badgeType;
type badgeProps<E extends ElementType> = defaultBadgeProps &
  asProps<E> &
  Omit<ComponentProps<E>, keyof defaultBadgeProps>;
type colorsType = {
  [V in Exclude<Variant, null>]: string;
};
export default function Badge<E extends ElementType = "span">({
  as,
  children,
  variant = "primary",
  className = "",
  fill = false,
  outline = false,
  ...props
}: badgeProps<E>) {
  const Component = as || "span";
  const activeVariant = useMemo(() => {
    if (!variant) return "";
    const backgroundColors: colorsType = {
      primary: "bg-primary",
      info: "bg-info",
      danger: "bg-danger",
      warning: "bg-warning",
      success: "bg-success",
      dark: "bg-dark",
      secondary: "bg-secondary",
      light: "",
      white: "",
      gray: "bg-gray-500",
    };
    const colors: colorsType = {
      primary: "text-primary",
      info: "text-info",
      danger: "text-danger",
      warning: "text-warning",
      success: "text-success",
      dark: "text-dark",
      secondary: "text-gray-900",
      light: "",
      white: "",
      gray: "text-gray-900",
    };
    const borderColors: colorsType = {
      primary: "border-primary-light",
      info: "border-info",
      danger: "border-danger",
      warning: "border-warning",
      success: "border-success",
      dark: "border-dark",
      secondary: "border-gray-900",
      light: "",
      white: "",
      gray: "border-gray-500",
    };
    if (fill)
      return [
        backgroundColors[variant],
        "text-white",
        "py-1 px-2",
        "text-xs",
      ].join(" ");
    if (outline)
      return [
        "border",
        borderColors[variant],
        colors[variant],
        "bg-transparent",
        "py-1 px-3",
        "text-xs",
      ].join(" ");
    return [
      backgroundColors[variant],
      colors[variant],
      "bg-opacity-10",
      "py-1 px-2",
      "text-xs",
    ].join(" ");
  }, [variant, fill, outline]);
  return (
    <Component
      className={twMerge(
        "inline-block align-middle whitespace-nowrap rounded-md font-medium leading-6",
        activeVariant,
        className
      )}
      {...props}
    >
      {children}
    </Component>
  );
}

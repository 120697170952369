import { useContext } from "react";
import { Context } from ".";
import { Text, Wrapper } from "../../../../components";
import { types } from "../../../../constants";
import { useConverter } from "../../../../hooks";
export default function Finalize() {
  const { data } = useContext(Context);
  const converter = useConverter();
  const items = [
    {
      title: "userManagement.platformUsers.create.basicInfoTab",
      items: [
        {
          label: "userManagement.platformUsers.create.fullName",
          value: [data.firstName, data.lastName].join(" "),
        },
        {
          label: "userManagement.platformUsers.create.userType",
          value: (
            <Text>
              {types.user.find((e) => `${e.id}` === `${data.userType}`)?.name ??
                ""}
            </Text>
          ),
        },
        {
          label: "userManagement.platformUsers.create.email",
          value: data.emailAddress,
        },
        {
          label: "userManagement.platformUsers.create.gender",
          value: (
            <Text>
              {types.gender.find((e) => `${e.id}` === `${data.gender}`)?.name ??
                ""}
            </Text>
          ),
        },
        {
          label: "userManagement.platformUsers.create.dateOfBirth",
          value: converter.date(data.dateOfBirth),
        },
        {
          label: "userManagement.platformUsers.create.mobileNumber",
          value: data.mobileNumber,
        },
        {
          label: "userManagement.platformUsers.create.phoneNumber",
          value: data.phoneNumber,
        },
      ],
    },
    {
      title: "userManagement.platformUsers.create.userGroupTab",
      items: [
        {
          label: "userManagement.platformUsers.create.userGroup",
          value: data.userGroupName,
        },
      ],
    },
    {
      title: "userManagement.platformUsers.create.addressTab",
      items: [
        {
          label: "userManagement.platformUsers.create.name",
          value: data.address?.name,
        },
        {
          label: "userManagement.platformUsers.create.street",
          value: data.address?.street,
        },
        {
          label: "userManagement.platformUsers.create.houseNumber",
          value: data.address?.number,
        },
        {
          label: "userManagement.platformUsers.create.postalCode",
          value: data.address?.postalCode,
        },
        {
          label: "userManagement.platformUsers.create.city",
          value: data.address?.city,
        },
        {
          label: "userManagement.platformUsers.create.state",
          value: data.address?.state,
        },
        {
          label: "userManagement.platformUsers.create.country",
          value: data.address?.country,
        },
      ],
    },
  ];
  return (
    <section className="space-y-8">
      {items.map((e) => (
        <Wrapper.Section key={e.title}>
          <ul className="space-y-4">
            <li>
              <h3 className="text-heading-3 text-gray-600">
                <Text>{e.title}</Text>
              </h3>
            </li>
            {e.items.map((e) => (
              <li
                key={e.label}
                className="flex flex-col lg:flex-row lg:items-start gap-1 lg:gap-8 text-gray-800"
              >
                <h6 className="text-body-1 lg:flex-1">
                  <Text>{e.label}</Text>
                </h6>
                <p className="text-heading-6 lg:flex-[2]">{e.value ?? "--"}</p>
              </li>
            ))}
          </ul>
        </Wrapper.Section>
      ))}
    </section>
  );
}

import { Variant } from "../types";

type statusType = {
  name: string;
  id: string | number;
  variant?: Variant;
};
const pursuit: statusType[] = [
  {
    name: "global.status.pursuit.unknown",
    id: 0,
    variant: "secondary",
  },
  {
    name: "global.status.pursuit.inProgress",
    id: 1,
    variant: "warning",
  },
  {
    name: "global.status.pursuit.fail",
    id: 2,
    variant: "danger",
  },
  {
    name: "global.status.pursuit.success",
    id: 3,
    variant: "success",
  },
];
const productImported: statusType[] = [
  {
    name: "global.status.productImported.unknown",
    id: 0,
    variant: "secondary",
  },
  {
    name: "global.status.productImported.newProductCreated",
    id: 1,
    variant: "primary",
  },
  {
    name: "global.status.productImported.productIsUpdated",
    id: 2,
    variant: "success",
  },
  {
    name: "global.status.productImported.fail",
    id: 3,
    variant: "danger",
  },
];
const status = { pursuit, productImported };
export default status;

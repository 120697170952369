import { useContext } from "react";
import { Context } from ".";
import { Text, Wrapper } from "../../../../components";
export default function Finalize() {
  const { data } = useContext(Context);
  const items = [
    {
      title: "b2bManagement.companies.create.companyInformationTab",
      items: [
        {
          label: "b2bManagement.companies.create.name",
          value: data.name,
          fullWidth: true,
        },
        {
          label: "b2bManagement.companies.create.description",
          value: data.description,
          fullWidth: true,
        },
        {
          label: "b2bManagement.companies.create.mobileNumber",
          value: data.mobileNumber,
        },
        {
          label: "b2bManagement.companies.create.phoneNumber",
          value: data.phoneNumber,
        },
      ],
    },
    {
      title: "b2bManagement.companies.create.addressInformationTab",
      items: [
        {
          label: "b2bManagement.companies.create.street",
          value: data.address?.street,
        },
        {
          label: "b2bManagement.companies.create.houseNumber",
          value: data.address?.number,
        },
        {
          label: "b2bManagement.companies.create.postalCode",
          value: data.address?.postalCode,
        },
        {
          label: "b2bManagement.companies.create.city",
          value: data.address?.city,
        },
        {
          label: "b2bManagement.companies.create.state",
          value: data.address?.state,
        },
        {
          label: "b2bManagement.companies.create.country",
          value: data.address?.country,
        },
      ],
    },
    {
      title: "b2bManagement.companies.create.adminInfoTab",
      items: [
        {
          label: "b2bManagement.companies.create.adminFirstName",
          value: data.firstName,
        },
        {
          label: "b2bManagement.companies.create.adminLastName",
          value: data.lastName,
        },
        {
          label: "b2bManagement.companies.create.adminEmailAddress",
          value: data.emailAddress,
        },
      ],
    },
  ];
  return (
    <section className="space-y-8">
      {items.map((e) => (
        <Wrapper.Section key={e.title}>
          <ul className="space-y-4">
            <li>
              <h3 className="text-heading-3 text-gray-600">
                <Text>{e.title}</Text>
              </h3>
            </li>
            {e.items.map((e) => (
              <li
                key={e.label}
                className="flex flex-col lg:flex-row lg:items-start gap-1 lg:gap-8 text-gray-800"
              >
                <h6 className="text-body-1 lg:flex-1">
                  <Text>{e.label}</Text>
                </h6>
                <p className="text-heading-6 lg:flex-[2]">{e.value ?? "--"}</p>
              </li>
            ))}
          </ul>
        </Wrapper.Section>
      ))}
    </section>
  );
}

import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  Button,
  Icon,
  Image,
  Pagination,
  SearchBox,
  Table,
  Text,
} from "../../../../components";
import { config } from "../../../../constants";
import { useAxios, usePermission } from "../../../../hooks";
import { convertAddress } from "../../../../methods";
import { Company, WithPaging } from "../../../../types";
import { withPermission } from "../../../../hoc";

type paramsType = {
  pageNumber: string;
  pageSize: string;
  keyword: string;
};

function Companies() {
  const navigate = useNavigate();
  const { axios, loading } = useAxios();
  const [data, setData] = useState({} as WithPaging<Company.Item>);
  const createPermission = usePermission("AS_CreateCompany");
  const [params, setParams] = useSearchParams({
    pageNumber: "1",
    pageSize: config.pageSize,
    keyword: "",
  } as paramsType);
  const handleSetParams = (key: keyof paramsType) => {
    return (value: any) =>
      setParams((p) => {
        p.set(key, String(value));
        return p;
      });
  };
  const goToDetails = (id: string) => {
    return () => navigate(id);
  };
  const getData = () => {
    const url = "/accountservice/api/companies";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [params]);
  return (
    <section className="space-y-4">
      <div className="flex flex-wrap items-center gap-4">
        <SearchBox
          value={params.get("keyword")}
          setValue={handleSetParams("keyword")}
          className="w-auto basis-full lg:basis-1/3"
        />
        {createPermission && <Button as={Link} to="add" className="w-fit ms-auto">
          <Icon name="Add" />{" "}
          <Text>b2bManagement.companies.list.addCompanyBtn</Text>
        </Button>
        }
      </div>
      <Table loading={loading.get}>
        {data.items?.map((e) => (
          <tr
            key={e.companyId}
            onClick={goToDetails(e.companyId)}
            className="cursor-pointer"
          >
            <td>
              <div className="flex items-center gap-4">
                <Image
                  src={e.logo?.thumbnailUrl}
                  alt={e.name}
                  className="w-32 h-20 rounded object-cover object-left"
                />
                <div className="flex-1 space-y-1">
                  <h6 className="text-heading-5">{e.name}</h6>
                  <p className="text-gray-500 text-body-2">
                    <Icon name="Location" variant="Bold" />{" "}
                    {convertAddress(e.address)}
                  </p>
                </div>
              </div>
            </td>
            <td>
              <Button type="button" variant="gray">
                <Text>b2bManagement.companies.list.detailsBtn</Text>
              </Button>
            </td>
          </tr>
        ))}
      </Table>
      <Pagination
        pageNumber={params.get("pageNumber")}
        setActivePage={handleSetParams("pageNumber")}
        totalItems={data.totalItems}
        totalPages={data.totalPages}
      />
    </section>
  );
}

export default withPermission(Companies, ["AS_GetCompany"]);

import { useContext } from "react";
import { Context } from ".";
import { InputGroup } from "../../../../components";
import { rules } from "../../../../constants";

export default function AdminInfo() {
  const { data, handleSetValue } = useContext(Context);
  // b2bManagement.companies.list.addCompanyBtn
  return (
    <section className="space-y-6">
      <InputGroup
        label="b2bManagement.companies.create.adminFirstName"
        value={data.firstName}
        setValue={handleSetValue("firstName")}
        rules={rules.required}
      />
      <InputGroup
        label="b2bManagement.companies.create.adminLastName"
        value={data.lastName}
        setValue={handleSetValue("lastName")}
        rules={rules.required}
      />
      <InputGroup
        label="b2bManagement.companies.create.adminEmailAddress"
        value={data.emailAddress}
        setValue={handleSetValue("emailAddress")}
        rules={rules.email}
      />
    </section>
  );
}

import { useContext } from "react";
import { Context } from ".";
import { Text, Wrapper } from "../../../../components";
import { types } from "../../../../constants";
import { useConverter } from "../../../../hooks";
export default function Finalize() {
  const { data } = useContext(Context);
  const converter = useConverter();
  const items = [
    {
      title: "b2bManagement.users.create.companyInfoTab",
      items: [
        {
          label: "b2bManagement.users.create.companyName",
          value: data.companyName,
        },
      ],
    },
    {
      title: "b2bManagement.users.create.basicInfoTab",
      items: [
        {
          label: "b2bManagement.users.create.fullName",
          value: [data.firstName, data.lastName].join(" "),
        },
        {
          label: "b2bManagement.users.create.email",
          value: data.emailAddress,
        },
        {
          label: "b2bManagement.users.create.personalNumber",
          value: data.personalNumber,
        },
        {
          label: "b2bManagement.users.create.gender",
          value: (
            <Text>
              {types.gender.find((e) => `${e.id}` === `${data.gender}`)?.name ??
                ""}
            </Text>
          ),
        },
        {
          label: "b2bManagement.users.create.dateOfBirth",
          value: converter.date(data.dateOfBirth),
        },
      ],
    },
    {
      title: "b2bManagement.users.create.addressTab",
      items: [
        {
          label: "b2bManagement.users.create.street",
          value: data.street,
        },
        {
          label: "b2bManagement.users.create.houseNumber",
          value: data.houseNumber,
        },
        {
          label: "b2bManagement.users.create.postalCode",
          value: data.postalCode,
        },
        {
          label: "b2bManagement.users.create.city",
          value: data.city,
        },
        {
          label: "b2bManagement.users.create.state",
          value: data.state,
        },
        {
          label: "b2bManagement.users.create.country",
          value: data.country,
        },
        {
          label: "b2bManagement.users.create.mobileNumber",
          value: data.mobileNumber,
        },
        {
          label: "b2bManagement.users.create.phoneNumber",
          value: data.phoneNumber,
        },
      ],
    },
  ];
  return (
    <section className="space-y-8">
      {items.map((e) => (
        <Wrapper.Section key={e.title}>
          <ul className="space-y-4">
            <li>
              <h3 className="text-heading-3 text-gray-600">
                <Text>{e.title}</Text>
              </h3>
            </li>
            {e.items.map((e) => (
              <li
                key={e.label}
                className="flex flex-col lg:flex-row lg:items-start gap-1 lg:gap-8 text-gray-800"
              >
                <h6 className="text-body-1 lg:flex-1">
                  <Text>{e.label}</Text>
                </h6>
                <p className="text-heading-6 lg:flex-[2]">{e.value}</p>
              </li>
            ))}
          </ul>
        </Wrapper.Section>
      ))}
    </section>
  );
}

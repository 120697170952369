import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  createContext,
  createElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router";
import {
  Icon,
  Image,
  Loading,
  Tabs,
  Text,
  Wrapper,
} from "../../../../components";
import { accepts } from "../../../../constants";
import { useAxios, useUploadFile } from "../../../../hooks";
import { convertAddress } from "../../../../methods";
import { Company } from "../../../../types";
import Channels from "./Channels";
import Profile from "./Profile";
import Settings from "./Settings";
import Users from "./Users";

type contextType = {
  companyData: Company.Details;
  setCompanyData: Dispatch<SetStateAction<Company.Details | null>>;
};

export const Context = createContext({} as contextType);

export default function CompanyDetails() {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const { companyId } = useParams();
  const { axios, loading } = useAxios();
  const uploadFile = useUploadFile();
  const [data, setData] = useState<Company.Details | null>(null);
  const [loadingFile, setLoadingFile] = useState(false);
  const tabs = [
    {
      label: "b2bManagement.companies.details.profileTab",
      id: "1",
      component: Profile,
    },
    {
      label: "b2bManagement.companies.details.channelsTab",
      id: "2",
      component: Channels,
    },
    {
      label: "b2bManagement.companies.details.usersTab",
      id: "3",
      component: Users,
    },
    {
      label: "b2bManagement.companies.details.settingsTab",
      id: "4",
      component: Settings,
    },
  ];

  const clickInput = () => {
    inputFileRef.current?.click();
  };

  const clearInput = () => {
    const element = inputFileRef.current;
    if (!element) return;
    element.value = "";
  };

  const updateAvatar = (e: ChangeEvent<HTMLInputElement>) => {
    setLoadingFile(true);
    uploadFile(e.target.files?.[0])
      .then((e) => {
        const url = `/accountservice/api/companies/${data?.companyId}/logo`;
        const body = { url: e.url, thumbnailUrl: e.thumbnailUrl };
        axios.patch(url, body).then(() => {
          setData((p) => ({ ...p, logo: body } as Company.Details));
        });
      })
      .finally(() => {
        setLoadingFile(false);
        clearInput();
      });
  };

  const getData = () => {
    const url = `/accountservice/api/companies/${companyId}`;
    axios.get(url).then(({ data }) => {
      setData(data);
    });
  };

  useEffect(getData, []);

  if (loading.get) return <Loading.Inline />;
  if (!data) return null;

  return (
    <section className="space-y-6">
      <input
        ref={inputFileRef}
        type="file"
        hidden
        accept={accepts.image}
        onChange={updateAvatar}
      />
      <Tabs activeKey={tabs[0].id}>
        <Wrapper>
          <Wrapper.Body className="flex flex-wrap gap-5">
            <button
              type="button"
              className="relative rounded-md overflow-hidden"
              onClick={clickInput}
            >
              <Image
                src={data.logo?.url}
                alt={data.name}
                className="w-80 max-w-full aspect-image object-contain bg-gray-100 data-loading:animate-pulse"
                data-loading={loadingFile || loading.update}
              />
              <span className="absolute top-0 right-0 w-8 h-8 flex-center bg-primary/80 text-white rounded-md">
                <Icon name="Edit2" />
              </span>
            </button>
            <div className="space-y-4">
              <h1 className="text-heading-5 text-gray-800">{data.name}</h1>
              <p className="text-gray-500 text-body-2">
                <Icon name="Location" variant="Bold" />{" "}
                {convertAddress(data.address)}
              </p>
            </div>
          </Wrapper.Body>
          <Wrapper.Footer className="py-0">
            <Tabs.ButtonGroup>
              {tabs.map((e) => (
                <Tabs.Button key={e.label} eventKey={e.id}>
                  <Text>{e.label}</Text>
                </Tabs.Button>
              ))}
            </Tabs.ButtonGroup>
          </Wrapper.Footer>
        </Wrapper>
        <Context.Provider
          value={{
            companyData: data,
            setCompanyData: setData,
          }}
        >
          {tabs.map((e) => (
            <Tabs.Item key={e.label} eventKey={e.id}>
              {createElement(e.component)}
            </Tabs.Item>
          ))}
        </Context.Provider>
      </Tabs>
    </section>
  );
}

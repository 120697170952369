import {
  Button,
  Drawer,
  Icon,
  Image,
  InputGroup,
  Text,
} from "../../../../../components";
import { Company, toggleProps } from "../../../../../types";

type Props = {
  user: Company.User;
} & toggleProps;
export default function UsersDetails({ user, isOpen, toggle }: Props) {
  const isAdmin = user.userType === 1;
  const hasImage = !!user.avatar?.url;
  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="flex items-center">
          <h2 className="flex-1 text-heading-2 text-gray-800">
            <Text>
              {isAdmin
                ? "b2bManagement.companies.details.adminInfoTitle"
                : "b2bManagement.companies.details.userInfoTitle"}
            </Text>
          </h2>
          <Button type="button" variant="gray" onClick={toggle}>
            <Icon name="CloseSquare" />{" "}
            <Text>b2bManagement.companies.details.closeBtn</Text>
          </Button>
        </Drawer.Header>
        <Drawer.Body className="space-y-6">
          <div className="w-20 h-20 flex-center rounded bg-gray-100 text-gray-400 overflow-hidden">
            {hasImage ? (
              <Image
                src={user.avatar?.url}
                alt={user.firstName + user.lastName}
                className="w-full h-full object-cover"
              />
            ) : (
              <Icon name="User" variant="Bulk" className="text-5xl" />
            )}
          </div>
          <InputGroup
            label="b2bManagement.companies.details.userFirstName"
            value={user.firstName}
            disabled
          />
          <InputGroup
            label="b2bManagement.companies.details.userLastName"
            value={user.lastName}
            disabled
          />
          <InputGroup
            label="b2bManagement.companies.details.userEmailAddress"
            value={user.emailAddress}
            disabled
          />
          <Button
            type="button"
            variant="primary"
            className="block w-full lg:w-fit"
          >
            <Text>b2bManagement.companies.details.resendLoginInfoBtn</Text>
          </Button>
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
}

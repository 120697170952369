import { usePermission } from "./../hooks";
import { Fragment } from "react";
import { Children, Permission } from "./../types";

type WithPermissionProps = { permissions: Permission[] } & Children;
export default function WithPermission({
  permissions,
  children,
}: WithPermissionProps) {
  const hasPermission = usePermission(...permissions);
  if (!hasPermission) return null;
  return <Fragment>{children}</Fragment>;
}

import { Link } from "react-router-dom";
import { Button, Drawer, Icon, Text } from "../../../../../components";
import { useConverter } from "../../../../../hooks";
import { toggleProps } from "../../../../../types";
import { providerProductItemType } from "../../../../../types/provider-product";
type detailsMenuProps = {
  data: providerProductItemType;
} & toggleProps;
export default function DetailsMenu({
  data,
  isOpen,
  toggle,
}: detailsMenuProps) {
  const convert = useConverter();
  const items = [
    { label: "Language EN", value: data.language },
    { label: "Update at EN", value: convert.date(data.updatedAt) },
    { label: "Affected Products EN", value: data.affectedProducts },
    { label: "Unaffected Products EN", value: data.unAffectedProducts },
    { label: "New Products EN", value: data.newProducts },
  ];
  return (
    <Drawer isOpen={isOpen} toggle={toggle}>
      <Drawer.Menu>
        <Drawer.Header className="flex items-center gap-4">
          <h5 className="text-heading-2 text-gray-800 flex-1">
            <Text>Details EN</Text>
          </h5>
          <Button
            as={Link}
            to={data.providerProductImportPursuitId}
            variant="danger"
            light
          >
            <Text>More EN</Text>
          </Button>
          <Button type="button" variant="gray" onClick={toggle}>
            <Icon name="CloseCircle" /> <Text>Close EN</Text>
          </Button>
        </Drawer.Header>
        <Drawer.Body>
          <table className="w-full text-gray-800 text-body-1 font-normal">
            <tbody>
              {items.map((e) => (
                <tr
                  key={e.label}
                  className="border-b border-gray-200 last:border-transparent"
                >
                  <td className="py-4 text-start">
                    <Text>{e.label}</Text>
                  </td>
                  <td className="py-4 text-end">{e.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Drawer.Body>
      </Drawer.Menu>
    </Drawer>
  );
}

import { status } from "../constants";
import Badge from "./_Badge";
import Text from "./_Text";

type statusProps = {
  id: number | string;
};

function Pursuit({ id }: statusProps) {
  const item = status.pursuit.find((e) => `${e.id}` === `${id}`);
  return (
    <Badge variant={item?.variant}>
      <Text>{item?.name ?? "-"}</Text>
    </Badge>
  );
}
function ProductImported({ id }: statusProps) {
  const item = status.productImported.find((e) => `${e.id}` === `${id}`);
  return (
    <Badge variant={item?.variant}>
      <Text>{item?.name ?? "-"}</Text>
    </Badge>
  );
}

const Status = { Pursuit, ProductImported };
export default Status;

export const appPermissions = {
    AS_CreateUser: "AS_CreateUser",
    AS_UpdateUser: "AS_UpdateUser",
    AS_GetUser: "AS_GetUser",
    AS_CreateCompany: "AS_CreateCompany",
    AS_UpdateCompany: "AS_UpdateCompany",
    AS_GetCompany: "AS_GetCompany",
    AS_CreateCompanyChannel: "AS_CreateCompanyChannel",
    AS_UpdateCompanyChannel:"AS_UpdateCompanyChannel",
    AS_CreateUserGroup: "AS_CreateUserGroup",
    AS_GetUserGroup:"AS_GetUserGroup",
    AS_CreateRole: "AS_CreateRole",
    AS_UpdateRole: "AS_UpdateRole",
    AS_GetRole: "AS_GetRole",
    withoutPermission: "withoutPermission"
  };

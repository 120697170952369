import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { Context } from ".";
import {
  AddressForm,
  Button,
  Form,
  InputGroup,
  Loading,
  Select,
  Text,
  WithPermission,
  Wrapper,
} from "../../../../components";
import { rules, types } from "../../../../constants";
import { useAxios, useDataState, usePermission, useSearchAddress } from "../../../../hooks";
import { userGroupItemType } from "../../../../types/user-group";
import { ValidateAddress } from "../../../../methods";

export default function General() {
  const { axios, loading } = useAxios();
  const { userData, setUserData } = useContext(Context);
  const [data, setData, setBaseData, isChanged] = useDataState(userData);
  const [userGroups, setUserGroups] = useState<userGroupItemType[]>([]);
  const [addressErrorMsg, setAddressErrorMasg] = useState("");
  const [validatingAddress, setValidatingAddress] = useState(false);
  const searchAddress = useSearchAddress();
  const updatePermissions = usePermission("AS_UpdateUser");

  const userGroupItems = userGroups?.map((e) => ({ name: e.name, id: e.id }));
  const handleSetValue = (key: keyof typeof data) => {
    return (value: any) => setData((p) => ({ ...p, [key]: value }));
  };

  const onSubmit = () => {
    setAddressErrorMasg("");
    if (!data.address) submit();
    else {
      setValidatingAddress(true);
      ValidateAddress(data.address as any, searchAddress).then(res => {
        if (!res?.selected && !res.suggested) {
          setAddressErrorMasg("Address is not valid");
        }
        if (res.selected) {
          setData({
            ...data,
            address: {
              ...res.selected,
              name: data.address?.name || '',
              number: data.address?.number || '',
            },
          });
          submit();
        }
        if (res.suggested) {
          setData({
            ...data,
            address: {
              ...res.suggested,
              name: data.address?.name || '',
              number: data.address?.number || '',
            },
          });
          setAddressErrorMasg(
            "Address has been changed please check before submitting",
          );
        }
      }).finally(() => { setValidatingAddress(false); })
    }
  }

  const submit = () => {
    const url = `/accountservice/api/users/${userData.userId}/platform`;
    const body = { ...data };
    body.avatar = userData.avatar;
    axios.put(url, body).then(() => {
      const message = "userManagement.platformUsers.details.successMessage";
      toast.success(message);
      setUserData(body);
      setBaseData(body);
    });
  };

  const getUserGroups = () => {
    const url = "/accountservice/api/usergroups";
    const config = { params: { pageSize: 100, pageNumber: 1 } };
    axios.get(url, config).then(({ data }) => {
      setUserGroups(data.items);
    });
  };

  useEffect(getUserGroups, []);

  if (loading.get) return <Loading.Inline />;

  return (
    <fieldset disabled={!updatePermissions}>
      <Form onSubmit={onSubmit} className="grid grid-cols-1 lg:grid-cols-2 gap-6" >
        <Wrapper>
          <Wrapper.Header>
            <h2 className="text-gray-800 text-heading-2">
              <Text>userManagement.platformUsers.details.basicInfoTitle</Text>
            </h2>
          </Wrapper.Header>
          <Wrapper.Body className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <InputGroup
              label="userManagement.platformUsers.details.firstName"
              value={data.firstName}
              setValue={handleSetValue("firstName")}
              rules={rules.required}
            />
            <InputGroup
              label="userManagement.platformUsers.details.lastName"
              value={data.lastName}
              setValue={handleSetValue("lastName")}
              rules={rules.required}
            />
            <Select
              className="col-span-full"
              label="userManagement.platformUsers.details.userType"
              value={data.userType}
              // setValue={handleSetValue("userType")}
              items={types.user}
              rules={rules.required}
              disabled
            />
            <InputGroup
              className="col-span-full"
              label="userManagement.platformUsers.details.email"
              value={data.emailAddress}
              setValue={handleSetValue("emailAddress")}
              rules={rules.email}
            />
            <Select
              label="userManagement.platformUsers.details.gender"
              value={data.gender}
              setValue={handleSetValue("gender")}
              items={types.gender}
            />
            <InputGroup
              label="userManagement.platformUsers.details.dateOfBirth"
              value={data.dateOfBirth}
              setValue={handleSetValue("dateOfBirth")}
              type="date"
            />
            <Select
              className="col-span-full"
              label="userManagement.platformUsers.details.userGroup"
              value={data.userGroupId}
              setValue={handleSetValue("userGroupId")}
              items={userGroupItems}
              rules={rules.required}
            />
            <InputGroup
              label="userManagement.platformUsers.details.mobileNumber"
              value={data.mobileNumber}
              setValue={handleSetValue("mobileNumber")}
              type="number"
            />
            <InputGroup
              label="userManagement.platformUsers.details.phoneNumber"
              value={data.phoneNumber}
              setValue={handleSetValue("phoneNumber")}
              type="number"
            />
          </Wrapper.Body>
        </Wrapper>
        <Wrapper>
          <Wrapper.Header>
            <h2 className="text-gray-800 text-heading-2">
              <Text>userManagement.platformUsers.details.addressTitle</Text>
            </h2>
          </Wrapper.Header>
          <Wrapper.Body className="space-y-6">
            <AddressForm
              data={data as any}
              setData={setData as any}
              errorMsg={addressErrorMsg}
            />
          </Wrapper.Body>
        </Wrapper>
        <WithPermission permissions={["AS_UpdateUser"]}>
          <Wrapper className="sticky col-span-full bottom-0">
            <Wrapper.Body className="flex items-center justify-end gap-4">
              <Button type="button" variant="white" disabled={loading.update}>
                <Text>userManagement.platformUsers.details.discardBtn</Text>
              </Button>
              <Button
                type="submit"
                variant="primary"
                loading={loading.update || validatingAddress}
                disabled={!isChanged}
              >
                <Text>userManagement.platformUsers.details.saveChangedBtn</Text>
              </Button>
            </Wrapper.Body>
          </Wrapper>
        </WithPermission>
      </Form>
    </fieldset>
  );
}

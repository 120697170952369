import { ComponentProps, useMemo } from "react";
import { twMerge } from "tailwind-merge";
import { useTranslate } from "../hooks";
import Badge from "./_Badge";
import Dropdown from "./_Dropdown";
import Icon from "./_Icon";
import InputGroup from "./_InputGroup";
import Text from "./_Text";

type itemType = {
  name: string;
  id: number | string;
};
type multipleProps =
  | {
      value: any;
      multiple?: false | never;
    }
  | {
      value: any[];
      multiple: true;
    };
type defaultSelectProps = {
  items: itemType[];
  id?: string;
} & multipleProps;
type selectProps = defaultSelectProps &
  Omit<
    ComponentProps<typeof InputGroup>,
    "onClick" | "append" | "readOnly" | "type" | "size" | "value"
  >;

export default function Select({
  label,
  value,
  items,
  rules,
  prepend,
  placeholder,
  setValue = () => {},
  multiple = false,
  disabled,
  className,
}: selectProps) {
  const translate = useTranslate();
  const activeItemName = items?.find((e) => `${e.id}` === `${value}`)?.name;
  const handleValue = useMemo(() => {
    if (multiple) return !!value?.length ? " " : "";
    return translate(activeItemName ?? "");
  }, [multiple, value, activeItemName, translate]);
  const handlePrepend = useMemo(() => {
    if (!multiple) return prepend;
    const activeItems = items.filter((e) => value?.includes(e.id));
    const handleRemoveItem = (id: string | number) => {
      return () =>
        setValue(value?.filter?.((e: string | number) => `${e}` !== `${id}`));
    };
    return activeItems.map((e) => (
      <span key={e.id} className="input-group-text">
        <Badge as="button" type="button" onClick={handleRemoveItem(e.id)}>
          <Text>{e.name}</Text>{" "}
          <Icon name="CloseSquare" className="text-gray-700" />
        </Badge>
      </span>
    ));
  }, [prepend, multiple, value, items, setValue]);
  const handleItems = useMemo(() => {
    if (!multiple) return items;
    return items.filter((e) => !value?.includes?.(e.id));
  }, [items, value, multiple]);
  const handleSetValue = (id: any) => {
    if (!multiple) return setValue(id);
    setValue([...(value ?? []), id]);
  };
  const hasItems = !!handleItems?.length;
  return (
    <Dropdown
      className={twMerge("block w-full", className)}
      onSelect={handleSetValue}
    >
      <Dropdown.Toggle
        as={InputGroup}
        label={label}
        value={handleValue}
        rules={rules}
        placeholder={placeholder}
        prepend={handlePrepend}
        disabled={disabled}
        append={
          <span className="input-group-text">
            <Icon name="ArrowDown2" />
          </span>
        }
      />
      <Dropdown.Menu className="top-full max-h-[10rem] overflow-auto">
        {hasItems ? (
          handleItems.map((e, i) => (
            <Dropdown.Item
              key={[e.id, e.name, i].join("-")}
              eventKey={e.id}
              isActive={value === e.id}
            >
              <Text>{e.name}</Text>
            </Dropdown.Item>
          ))
        ) : (
          <p className="text-center text-gray-500 p-2">
            <Text>global.noItems</Text>
          </p>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}
// {clear && value !== undefined && value && (
//   <i
//     onClick={() => {
//       setValue(null);
//     }}
//   >
//     <Icon className="mr-2" icon={"times"} />
//   </i>
// )}

import { Variant } from "../types";

type Type = {
  name: string;
  id: string | number;
  variant?: Variant;
};
const gender: Type[] = [
  {
    name: "global.types.gender.male",
    id: 1,
  },
  {
    name: "global.types.gender.female",
    id: 2,
  },
];
const user: Type[] = [
  {
    name: "global.types.user.none",
    id: 0,
    variant: "gray",
  },
  {
    name: "global.types.user.admin",
    id: 1,
    variant: "success",
  },
  {
    name: "global.types.user.user",
    id: 2,
    variant: "primary",
  },
];
const productProviders: Type[] = [
  {
    name: "EMV",
    id: 1,
  },
];
const types = { gender, user, productProviders };
export default types;

import { Fragment, createContext, useContext } from "react";
import tickIcon from "../assets/icons/tick active.svg";
import { childrenProps } from "../types";
import Button from "./_Button";
import Form from "./_Form";
import Icon from "./_Icon";
import Image from "./_Image";
import Text from "./_Text";
import Wrapper from "./_Wrapper";

type wizardProps = {
  activeStep: number;
  onSubmit: () => void;
  onCancel: () => void;
} & childrenProps;
type wizardListProps = childrenProps;
type wizardItemProps = {
  index: number;
  label: string;
  description: string;
};
type wizardTabsProps = childrenProps;
type wizardTabProps = {
  index: number;
  label: string;
  loading?: boolean;
  disabled?: boolean;
  isLastStep?: boolean;
} & childrenProps;
type contextType = {
  activeStep: number;
  onCancel: () => void;
};
const Context = createContext({} as contextType);
function Wizard({ activeStep, onSubmit, onCancel, children }: wizardProps) {
  return (
    <Form
      onSubmit={onSubmit}
      className="grid grid-cols-1 lg:grid-cols-3 lg:items-start gap-4"
    >
      <Context.Provider value={{ activeStep, onCancel }}>
        {children}
      </Context.Provider>
    </Form>
  );
}
function WizardItems({ children }: wizardListProps) {
  return (
    <Wrapper>
      <Wrapper.Body>
        <ul className="flex flex-col gap-2">{children}</ul>
      </Wrapper.Body>
    </Wrapper>
  );
}
function WizardItem({ index, label, description }: wizardItemProps) {
  const { activeStep } = useContext(Context);
  return (
    <Fragment>
      <li
        data-active={index === activeStep}
        className="group flex items-center gap-4"
      >
        <div className="bg-primary-light text-primary w-10 h-10 flex-center text-heading-3 rounded transition-colors group-data-active:bg-primary group-data-active:text-white">
          {index >= activeStep ? (
            index + 1
          ) : (
            <Image
              src={tickIcon}
              alt="tick"
              className="w-4 h-4 object-contain"
            />
          )}
        </div>
        <div className="flex-1">
          <h6 className="text-gray-800 text-heading-4 truncate transition-colors group-data-active:text-primary-active">
            <Text>{label}</Text>
          </h6>
          <p className="text-gray-500 text-body-2 leading-6 truncate">
            <Text>{description}</Text>
          </p>
        </div>
      </li>
      <li className="flex-center w-10 last:hidden">
        <div className="h-8 w-0 border-e border-dashed border-gray-400"></div>
      </li>
    </Fragment>
  );
}
function WizardTabs({ children }: wizardTabsProps) {
  return (
    <Wrapper className="lg:col-span-2 [&>*]:max-w-[37.5rem] [&>*]:mx-auto">
      {children}
    </Wrapper>
  );
}
function WizardTab({
  index,
  label,
  loading,
  disabled,
  children,
  isLastStep = false,
}: wizardTabProps) {
  const { activeStep, onCancel } = useContext(Context);
  if (index !== activeStep) return null;
  return (
    <Fragment>
      <Wrapper.Header className="pb-2">
        <h2 className="text-gray-800 text-heading-2">
          <Text>{label}</Text>
        </h2>
      </Wrapper.Header>
      <Wrapper.Body>{children}</Wrapper.Body>
      <Wrapper.Footer className="flex items-center justify-between gap-4">
        <Button
          type="button"
          variant="gray"
          disabled={loading}
          onClick={onCancel}
        >
          <Icon name="ArrowLeft" /> <Text>global.wizard.backBtn</Text>
        </Button>
        <Button
          type="submit"
          variant="primary"
          loading={loading}
          disabled={disabled}
        >
          <Text>
            {isLastStep ? "global.wizard.submitBtn" : "global.wizard.nextBtn"}
          </Text>{" "}
          <Icon name={isLastStep ? "TickCircle" : "ArrowRight"} />
        </Button>
      </Wrapper.Footer>
    </Fragment>
  );
}
Wizard.Items = WizardItems;
Wizard.Item = WizardItem;
Wizard.Tabs = WizardTabs;
Wizard.Tab = WizardTab;
export default Wizard;

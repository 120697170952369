import {
  createContext,
  ReactNode,
  TransitionEvent,
  useContext,
  useEffect,
  useId,
  useRef,
  useState,
} from "react";
import { twMerge } from "tailwind-merge";
import Icon from "./_Icon";
type eventKeyType = string | null;
type accordionProps = {
  children: ReactNode;
  defaultActiveKey?: eventKeyType;
};
type accordionItemProps = {
  className?: string;
  eventKey?: eventKeyType;
  children: ReactNode;
};
type accordionToggleProps = {
  className?: string;
  children: ReactNode;
};
type accordionBodyProps = {
  className?: string;
  children: ReactNode;
};
type accordionContextType = {
  activeKey: eventKeyType;
  setActiveKey: (val: eventKeyType) => void;
};
type accordionItemContextType = {
  eventKey: eventKeyType;
  isActive: boolean;
};
const AccordionContext = createContext({} as accordionContextType);
const AccordionItemContext = createContext({} as accordionItemContextType);

function Accordion({
  children = null,
  defaultActiveKey = null,
}: accordionProps) {
  const [activeKey, setActiveKey] = useState<eventKeyType>(defaultActiveKey);
  return (
    <AccordionContext.Provider value={{ activeKey, setActiveKey }}>
      {children}
    </AccordionContext.Provider>
  );
}
function AccordionItem({
  className = "",
  eventKey = null,
  children,
}: accordionItemProps) {
  const id = useId();
  const key = eventKey ?? id;
  const { activeKey } = useContext(AccordionContext);
  const isActive: boolean = activeKey === key;
  return (
    <AccordionItemContext.Provider value={{ eventKey: key, isActive }}>
      <div
        className={twMerge(
          "accordion-item w-full",
          isActive && "active",
          className
        )}
      >
        {children}
      </div>
    </AccordionItemContext.Provider>
  );
}
function AccordionToggle({
  className = "",
  children = null,
}: accordionToggleProps) {
  const { setActiveKey } = useContext(AccordionContext);
  const { eventKey, isActive } = useContext(AccordionItemContext);
  const handleSetActiveKey = () => {
    if (isActive) return setActiveKey(null);
    setActiveKey(eventKey);
  };
  return (
    <button
      type="button"
      onClick={handleSetActiveKey}
      className={twMerge(
        "w-full flex items-center gap-2 overflow-hidden",
        className
      )}
    >
      {children}
      <Icon
        name="ArrowDown2"
        data-active={isActive}
        className="accordion-arrow transition-transform ml-auto data-active:rotate-180"
      />
    </button>
  );
}
function AccordionBody({
  className = "",
  children = null,
}: accordionBodyProps) {
  const collapseRef = useRef<HTMLDivElement>(null);
  const bodyRef = useRef<HTMLDivElement>(null);
  const { isActive } = useContext(AccordionItemContext);
  const handleTransition = ({
    currentTarget,
  }: TransitionEvent<HTMLDivElement>) => {
    currentTarget.style.height = isActive ? "auto" : "0";
  };
  useEffect(() => {
    const collapse = collapseRef.current;
    const body = bodyRef.current;
    const height = body?.getBoundingClientRect().height;
    if (!collapse || !body) return;
    collapse.style.height = `${height}px`;
    if (!isActive) {
      setTimeout(() => {
        collapse.style.height = "0px";
      }, 0);
    }
  }, [isActive]);
  return (
    <div
      ref={collapseRef}
      onTransitionEnd={handleTransition}
      data-active={isActive}
      className="w-full h-0 data-active:h-auto overflow-hidden transition-[height]"
    >
      <div ref={bodyRef} className={twMerge("h-fit py-2", className)}>
        {children}
      </div>
    </div>
  );
}

Accordion.Item = AccordionItem;
Accordion.Toggle = AccordionToggle;
Accordion.Body = AccordionBody;

export default Accordion;

const companies = {
  list: {
    detailsBtn: "Details DE",
    addCompanyBtn: "Add company DE",
  },
  create: {
    companyInformationTab: "Company Information DE",
    addressInformationTab: "Address Information DE",
    adminInfoTab: "User admin information DE",
    finalizeTab: "Finalize DE",
    companyInformationDesc: "Name, Description ... DE",
    addressInformationDesc: "Street, city ,... DE",
    adminInfoDesc: "Name, Email DE",
    finalizeDesc: "Finalize information DE",

    successMessage: "Company has been added. DE",

    backBtn: "Back DE",
    nextBtn: "Next DE",
    submitBtn: "Submit DE",

    name: "Company name DE",
    description: "Short description DE",
    mobileNumber: "Mobile Number DE",
    phoneNumber: "Phone Number DE",

    addressName: "Name DE",
    street: "Street DE",
    houseNumber: "House Number DE",
    postalCode: "Postal Code DE",
    city: "City DE",
    state: "State DE",
    country: "Country DE",

    adminFirstName: "First name DE",
    adminLastName: "Last name DE",
    adminEmailAddress: "Email address DE",
  },
  details: {
    profileTab: "Profile DE",
    channelsTab: "Channels DE",
    settingsTab: "Settings DE",
    usersTab: "Users DE",

    basicInfoTitle: "Basic info DE",
    locationTitle: "Location DE",

    settingSuccessMessage: "Company settings has been updated. DE",
    successMessage: "Company has been updated. DE",

    saveChangedBtn: "Save Changes DE",
    discardBtn: "Discard DE",
    submitBtn: "Submit DE",
    cancelBtn: "Cancel DE",

    name: "Company name DE",
    description: "Short description DE",
    customerNumberPrefix: "Customer Prefix Number DE",
    dashboardLanguages: "Dashboard Languages DE",
    defaultDashboardLanguage: "Default Dashboard Language DE",
    contentLanguages: "Content Languages DE",
    defaultContentLanguage: "Default Content Language DE",

    addressName: "Name DE",
    street: "Street DE",
    houseNumber: "House Number DE",
    postalCode: "Postal Code DE",
    city: "City DE",
    state: "State DE",
    country: "Country DE",
    mobileNumber: "Mobile Number DE",
    phoneNumber: "Phone Number DE",

    channelActive: "Active DE",

    channelAvailableLanguages: "Available Languages DE",
    channelDefaultLanguage: "Default Language DE",
    channelAvailableCurrencies: "Available Currencies DE",
    channelActivate: "Activate DE",

    addAdminUserBtn: "Add admin user DE",
    addAdminUserTitle: "Add admin user DE",
    addAdminUserSuccessMessage: "User has been added successfully. DE",
    detailsBtn: "Details DE",

    adminInfoTitle: "Admin info DE",
    userInfoTitle: "User info DE",
    closeBtn: "Close DE",
    userFirstName: "First name DE",
    userLastName: "Last name DE",
    userEmailAddress: "Email address DE",
    resendLoginInfoBtn: "Resend login info email DE",
  },
};
export default companies;

import { Fragment } from "react";
import { ToastBar, Toaster } from "react-hot-toast";
import { useRoutes } from "react-router";
import { useTranslate } from "./hooks";
import routes from "./routes";

export default function App() {
  const translate = useTranslate();
  const elements = useRoutes(routes);
  return (
    <Fragment>
      {elements}
      <Toaster position="top-center" toastOptions={{ duration: 5000 }}>
        {(t) => (
          <ToastBar
            toast={{ ...t, message: translate(t.message?.toString() ?? "") }}
          >
            {({ message, icon }) => (
              <Fragment>
                {icon}
                {message}
              </Fragment>
            )}
          </ToastBar>
        )}
      </Toaster>
    </Fragment>
  );
}

import companies from "./b2b management/companies";
import users from "./b2b management/users";
import global from "./global";
import layout from "./layout";
import importProducts from "./product management/providers/import-products";
import productDetails from "./product management/providers/product-details";
import rules from "./rules";
import toast from "./toast";
import platformUsers from "./user management/platform-users";
import roles from "./user management/roles";
import userGroups from "./user management/user-groups";
import {errors} from "./errors";
const en = {
  global,
  rules,
  layout,
  toast,
  errors,
  b2bManagement: {
    companies,
    users,
  },
  productManagement: {
    providers: {
      importProducts,
      productDetails,
    },
  },
  userManagement: {
    platformUsers,
    userGroups,
    roles,
  },
};
export default en;

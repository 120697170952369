import { useCallback } from "react";
import { NavLink } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { Accordion, Icon, Text } from "../../components";
import { appPermissions, navItems } from "../../constants";
import { Permission, navItemType, toggleProps } from "../../types";
import { useSelector } from "../../hooks";
export default function Sidebar({ isOpen, toggle }: toggleProps) {
  const permissions = useSelector(s => s.permissions);
  const hasPermission = (permissionsKey: Permission[]) => {
    if (permissionsKey.includes("withoutPermission")) return true;
    if (!permissionsKey?.length) return false;
    return !!permissionsKey?.some(
      permissionKey => permissions?.includes(appPermissions[permissionKey]),
    );
  };
  const renderNavItems = useCallback((items: navItemType[]) => {
    const elements: any[] = [];
    items.forEach((e) => {
      const permission = hasPermission(e.permission);
      if (!permission) return;
      if (!!e.items?.length) {
        const element = (
          <Accordion key={e.label}>
            <Accordion.Item key={e.label}>
              <Accordion.Toggle className="w-full h-8 font-normal text-gray-200 group-[.menu.active]:lg:[&>.accordion-arrow]:hidden">
                <span className="w-8 flex-center text-base">
                  <Icon name={e.icon} variant="Bulk" />
                </span>
                <span className="flex-1 text-start truncate group-[.menu.active]:lg:hidden">
                  <Text>{e.label}</Text>
                </span>
              </Accordion.Toggle>
              <Accordion.Body className="space-y-3 px-1">
                {renderNavItems(e.items)}
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        );
        elements.push(element);
      } else if (e.path) {
        const element = (
          <NavLink
            key={e.label}
            to={e.path}
            className="w-full h-8 flex items-center gap-2 font-normal text-gray-500 rounded [&.active]:bg-white/10 [&.active]:text-white"
          >
            <span className="w-8 flex-center text-base">
              <Icon name={e.icon} variant="Bulk" />
            </span>
            <span className="flex-1 text-start truncate group-[.menu.active]:lg:hidden">
              <Text>{e.label}</Text>
            </span>
          </NavLink>
        );
        elements.push(element);
      }
    });
    return elements;
  }, []);
  return (
    <div
      className={twMerge(
        "menu group fixed flex inset-0 bg-black/20 transition-opacity z-20 lg:bg-transparent",
        isOpen
          ? "active opacity-100 pointer-events-auto lg:pointer-events-none"
          : "opacity-0 pointer-events-none lg:opacity-100"
      )}
    >
      <div
        className={twMerge(
          "flex flex-col w-[265px] bg-primary-active text-white transition-[width,transform] lg:pointer-events-auto",
          isOpen
            ? "translate-x-0 lg:w-[50px]"
            : "-translate-x-full lg:translate-x-0"
        )}
      >
        <div className="relative w-full h-16 flex-center bg-primary">
          <button
            type="button"
            className="hidden lg:flex absolute flex-center rounded overflow-hidden w-6 h-6 -right-3 bg-white text-gray-900 shadow-md"
            onClick={toggle}
          >
            <Icon
              name="ArrowLeft2"
              className={`transition-transform ${isOpen ? "rotate-180" : "rotate-0"
                }`}
            />
          </button>
        </div>
        <div className="w-full flex-1 px-2 py-4 overflow-auto space-y-3">
          {renderNavItems(navItems)}
        </div>
      </div>
      <button
        type="button"
        className="flex-1 h-full opacity-0 lg:hidden"
        onClick={toggle}
      />
    </div>
  );
}

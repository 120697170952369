import { Fragment, useCallback, useMemo } from "react";
import { useLocation } from "react-router";
import { twMerge } from "tailwind-merge";
import { navItems } from "../constants";
import { ClassName, navItemType } from "../types";
import Text from "./_Text";

type itemType = {
  path?: string | null;
  label: string;
};
type breadcrumbProps = {
  items?: itemType[];
} & ClassName;
export default function Breadcrumb({ items = [], className }: breadcrumbProps) {
  const { pathname } = useLocation();
  const convertItem = useCallback(
    (item: navItemType) => {
      const items: itemType[] = [];
      items.push({ label: item.label });
      if (item.items) {
        const findItem = item.items.find((e) =>
          JSON.stringify(e).includes(pathname.replace("/", ""))
        );
        !!findItem && items.push(...convertItem(findItem));
      }
      return items;
    },
    [pathname]
  );
  const baseItems: itemType[] = [
    // { label: "Platform dashboard" },
    { label: "Home" },
  ];
  const pathItems = useMemo(() => {
    const navItem = navItems.find((e) =>
      JSON.stringify(e).includes(pathname.replace("/", ""))
    );
    if (!navItem) return [];
    return convertItem(navItem);
  }, [pathname, convertItem]);
  const allItems = [...baseItems, ...pathItems, ...items];
  const lastItem = allItems.at(-1);
  return (
    <div className={twMerge("block w-full space-y-2", className)}>
      {!!lastItem && (
        <h2 className="block text-gray-800 text-heading-3 font-semibold">
          <Text>{lastItem.label}</Text>
        </h2>
      )}
      <ul className="w-full flex items-center flex-wrap text-body-2 text-gray-500 gap-1">
        {allItems.map((e, i) => (
          <Fragment key={e.label}>
            {i !== 0 && <li>/</li>}
            <li className="last:text-gray-800">
              <Text>{e.label}</Text>
            </li>
          </Fragment>
        ))}
      </ul>
    </div>
  );
}

const users = {
  list: {
    addUser: "Add new user",
    company: "Company",
    details: "Details",
  },
  create: {
    companyInfoTab: "Company information",
    companyInfoDesc: "Choose company",
    basicInfoTab: "Basic Info",
    basicInfoDesc: "Name, description, ...",
    addressTab: "Address",
    addressDesc: "Street, city, ...",
    finalizeTab: "Finalize",
    finalizeDesc: "Finalize information",

    companyName: "Company name",
    selectedCompany: "Selected Company Info",

    email: "Email",
    personalNumber: "Personal number",
    firstName: "First name",
    lastName: "Last name",
    dateOfBirth: "Date of birth",
    gender: "Gender",

    street: "Street",
    houseNumber: "House number",
    postalCode: "Postal code",
    city: "City",
    country: "Country",
    state: "State",
    mobileNumber: "Mobile Number",
    phoneNumber: "Phone Number",

    fullName: "Name",

    backBtn: "Back",
    nextBtn: "Next",
    submitBtn: "Submit",

    successMessage: "User has been added.",
  },
  details: {
    generalTab: "General",

    basicInfoTitle: "Basic info",

    firstName: "First name",
    lastName: "Last name",
    gender: "Gender",
    dateOfBirth: "Date of birth",
    personalNumber: "Personal number",
    email: "Email",

    addressTitle: "Address",

    street: "Street",
    houseNumber: "House number",
    postalCode: "Postal code",
    city: "City",
    country: "Country",
    state: "State",
    mobileNumber: "Mobile Number",
    phoneNumber: "Phone Number",

    discardBtn: "Discard",
    saveChangedBtn: "Save changed",

    successMessage: "User has been updated.",
  },
};
export default users;

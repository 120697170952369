import { Fragment, useContext, useEffect, useState } from "react";
import { Context } from ".";
import { Icon, Image, Loading, Select, Text } from "../../../../components";
import { rules } from "../../../../constants";
import { useAxios } from "../../../../hooks";
import { companyListType } from "../../../../types/company";
export default function CompanyInfo() {
  const { axios, loading } = useAxios();
  const { data, handleSetValue } = useContext(Context);
  const [companies, setCompanies] = useState<companyListType[]>([]);
  const companyItems = companies.map((e) => ({
    name: e.name,
    id: e.companyId,
  }));
  const selectedCompany = companies.find((e) => e.companyId === data.companyId);
  const handleSelect = (value: string) => {
    const selectedCompany = companies.find((e) => e.companyId === value);
    handleSetValue("companyId")(value);
    handleSetValue("companyName")(selectedCompany?.name);
  };
  const getCompanies = () => {
    const url = "/accountservice/api/companies";
    const config = { params: { pageSize: 100, pageNumber: 1 } };
    axios.get(url, config).then(({ data }) => {
      setCompanies(data.items);
    });
  };
  useEffect(getCompanies, []);
  return (
    <section className="space-y-8">
      {loading.get ? (
        <Loading.Inline />
      ) : (
        <Fragment>
          <Select
            label="b2bManagement.users.create.companyName"
            value={data.companyId}
            setValue={handleSelect}
            items={companyItems}
            rules={rules.required}
          />
          <section className="w-full h-24 bg-gray-100 text-gray-500 border border-gray-200 rounded flex-center overflow-hidden">
            {!!selectedCompany ? (
              <div className="w-full h-full bg-white flex items-center gap-4 p-2">
                <Image
                  src={selectedCompany.avatarThumbnailUrl}
                  alt={selectedCompany.name}
                  className="w-32 h-20 rounded object-cover object-left border border-gray-200"
                />
                <div className="flex-1 space-y-1">
                  <h6 className="text-heading-5">{selectedCompany.name}</h6>
                  <p className="text-gray-500 text-body-2">
                    <Icon name="Location" variant="Bold" />{" "}
                    {selectedCompany.city}, {selectedCompany.houseNumber},{" "}
                    {selectedCompany.street}
                  </p>
                </div>
              </div>
            ) : (
              <p>
                <Text>b2bManagement.users.create.selectedCompany</Text>
              </p>
            )}
          </section>
        </Fragment>
      )}
    </section>
  );
}

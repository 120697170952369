import { Fragment } from "react";
import { config } from "../constants";
import Button from "./_Button";
import Icon from "./_Icon";
import Text from "./_Text";

type paginationProps = {
  pageNumber: number | string | null;
  pageSize?: number | string | null;
  setActivePage: (val: number | string) => void;
  totalItems: number | string | null;
  totalPages: number | string | null;
};
export default function Pagination(props: paginationProps) {
  const activePage = Number(props.pageNumber ?? 1);
  const pageSize = Number(props.pageSize ?? config.pageSize);
  const setActivePage = props.setActivePage;
  const totalItems = Number(props.totalItems ?? 0);
  const totalPages = Number(props.totalPages ?? 0);
  const items = {
    from: pageSize * (activePage - 1) + 1,
    to: pageSize * activePage > totalItems ? totalItems : pageSize * activePage,
  };
  const min = 1;
  const max = totalPages;
  const len = 1;
  const disabled = {
    prev: activePage === min,
    next: activePage === max,
  };
  const setPage = (page: number) => {
    return () => setActivePage(page);
  };
  const isActive = (page: number) => {
    return page === activePage;
  };
  const renderPages = () => {
    const elements = [...Array(max)].map((e, i) => {
      const page = i + 1;
      const isPrev = page < activePage;
      // const isNext = page > activePage;
      const isActive = page === activePage;
      const isInRange = page >= activePage - len && page <= activePage + len;
      const rules = [page === min, page === max, isInRange, isActive];
      const pos = isPrev ? "prev" : "next";
      return rules.some(Boolean) ? page : pos;
    });
    // @ts-ignore: Unreachable code error
    return [...new Set(elements)].map((page) =>
      typeof page === "string" ? (
        <span key={page} className="my-auto">
          ...
        </span>
      ) : (
        <Button
          key={page}
          onClick={setPage(+page)}
          variant={isActive(page) ? "primary" : null}
          className={`border-none py-1`}
        >
          {page}
        </Button>
      )
    );
  };

  return (
    <Fragment>
      {max > 1 && (
        <div className="flex items-center justify-between w-full mt-4 whitespace-nowrap">
          <p className="text-gray-900 flex-1 truncate">
            <Text from={items.from} to={items.to} totalItems={totalItems}>
              global.pagination
            </Text>
          </p>
          <div className="w-fit flex-center text-sm gap-2">
            <button
              type="button"
              onClick={setPage(activePage - 1)}
              disabled={disabled.prev}
              className="text-lg btn btn-white"
            >
              <Icon name="ArrowLeft2" size={18} />
            </button>
            {renderPages()}
            <button
              type="button"
              onClick={setPage(activePage + 1)}
              disabled={disabled.next}
              className="text-lg btn btn-white"
            >
              <Icon name="ArrowRight2" size={18} />
            </button>
          </div>
        </div>
      )}
    </Fragment>
  );
}

const users = {
  list: {
    addUser: "Add new user DE",
    company: "Company DE",
    details: "Details DE",
  },
  create: {
    companyInfoTab: "Company information DE",
    companyInfoDesc: "Choose company DE",
    basicInfoTab: "Basic Info DE",
    basicInfoDesc: "Name, description, ... DE",
    addressTab: "Address DE",
    addressDesc: "Street, city, ... DE",
    finalizeTab: "Finalize DE",
    finalizeDesc: "Finalize information DE",

    companyName: "Company name DE",
    selectedCompany: "Selected Company Info DE",

    email: "Email DE",
    personalNumber: "Personal number DE",
    firstName: "First name DE",
    lastName: "Last name DE",
    dateOfBirth: "Date of birth DE",
    gender: "Gender DE",

    street: "Street DE",
    houseNumber: "House number DE",
    postalCode: "Postal code DE",
    city: "City DE",
    country: "Country DE",
    state: "State DE",
    mobileNumber: "Mobile Number DE",
    phoneNumber: "Phone Number DE",

    fullName: "Name DE",

    backBtn: "Back DE",
    nextBtn: "Next DE",
    submitBtn: "Submit DE",

    successMessage: "User has been added. DE",
  },
  details: {
    generalTab: "General DE",

    basicInfoTitle: "Basic info DE",

    firstName: "First name DE",
    lastName: "Last name DE",
    gender: "Gender DE",
    dateOfBirth: "Date of birth DE",
    personalNumber: "Personal number DE",
    email: "Email DE",

    addressTitle: "Address DE",

    street: "Street DE",
    houseNumber: "House number DE",
    postalCode: "Postal code DE",
    city: "City DE",
    country: "Country DE",
    state: "State DE",
    mobileNumber: "Mobile Number DE",
    phoneNumber: "Phone Number DE",

    discardBtn: "Discard DE",
    saveChangedBtn: "Save changed DE",

    successMessage: "User has been updated. DE",
  },
};
export default users;

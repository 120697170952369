import noItemsIcon from "../assets/icons/no items.svg";
import Image from "./_Image";
import Text from "./_Text";
export default function NoItems() {
  return (
    <div className="!w-full space-y-4 text-center p-4 text-2xl text-placeholder">
      <Image
        className="block w-50 max-w-full mx-auto aspect-square object-contain"
        src={noItemsIcon}
        alt="No item found"
      />
      <p className="text-heading-1 text-gray-800">
        <Text>global.noItems</Text>
      </p>
      <p className="text-body-1 text-gray-600">
        <Text>global.noItems</Text>
      </p>
    </div>
  );
}

export const errors = {
  error: "Fehler",

  100: "Channel Already Added To Company DE",

  1000: "Platzcode muss eindeutig sein",
  1001: "Ungültiger Kanal",
  1003: "Markencode muss eindeutig sein in der Firma",
  1004: "Platzcode muss eindeutig sein im Bereich",
  1006: "Attributgruppenvorlage nicht gefunden",
  1007: "Marke konnte nicht gefunden werden",
  1008: "Marke existiert bereits",
  1009: "Checklistenelement konnte nicht gefunden werden",
  1010: "Checklistenelementbezeichnung existiert bereits",
  1011: "Rechtssubjekt konnte nicht gefunden werden",
  1012: "Rechtssubjektkennung muss eindeutig sein in der Firma",
  1013: "Messfamilienkennung muss eindeutig sein",
  1014: "Kennung der Messfamilieneinheit muss eindeutig sein",
  1015: "Messfamilie konnte nicht gefunden werden",
  1016: "PIM-Produktfamilie konnte nicht gefunden werden",
  1017: "Platz konnte nicht gefunden werden",
  1018: "PIM-Produktmedium konnte nicht gefunden werden",
  1019: "Supplier Holiday Not Found DE",
  1020: "Favorite Product Not Found DE",
  1021: "Nur Kanal von nicht kanalspezifischen Attributwerten kann Null sein",
  1022: "Kanalspezifische Attribute sollten integrierte Kanäle verwenden",
  1023: "Nur Sprache von nicht sprachspezifischen Attributwerten kann Null sein",
  1024: "PIM-Produktmediumfelder dürfen keine doppelte Sprache im gleichen Kanal haben",
  1025: "Medienfelder sollten entweder alle oder keine Kanalwert haben",
  1026: "Medienfelder sollten entweder alle oder keine Sprachwert haben",
  1027: "Erforderliche Elemente sollten einen Wert haben",
  1028: "Checklistenelement löschen ist möglich, wenn es nicht in der Vorbereitungsphase verwendet wird",
  1029: "Checklistenelement löschen ist möglich, wenn es nicht in der Verkaufsphase verwendet wird",
  1030: "Produktfamilienkennung muss eindeutig sein in der Firma",
  1031: "Produktfamilie existiert bereits",
  1032: "Zonenkennung muss eindeutig sein im Geschäft",
  1033: "Geschäftskennung muss eindeutig sein in der Firma",
  1034: "Geschäft nicht gefunden",
  1035: "Produktfamilie nicht gefunden",
  1036: "Kanal-Einstellung nicht gefunden",
  1037: "Kanal ist in dieser Kategorie nicht verfügbar",
  1038: "Company Not Found DE",
  1039: "Company Channel Not Found DE",
  1040: "Place Already Exists DE",
  1041: "Company Already Exist DE",
  1042: "LocationNotFound",

  13000: "Product Categor CanNot Have Duplicate ChannelInfo",
  13001: "Product Category CanNot Have Duplicate ChannelException",
  13002: "Product Category ChannelCode Must Exist In ChannelException",
  13003: "Product Category ChannelInfo Must Be Exist",
  13004: "Product Category Sort Order ShouldNot Be LessThanZero",

  2000: "Kanäle sollten in den durchsuchbaren Attributkanälen eindeutig sein",
  2001: "Kanäle sollten in den integrierten Kanälen sein",
  2002: "Attributsuchbare Kanäle müssen vorhanden sein",
  2003: "Attribute Publishable Exception Channels Should Be Unique DE",
  2004: "Attribut-Veröffentlichungsausnahmekanäle müssen vorhanden sein",
  2005: "Attribut-Element sollte nicht dupliziert werden",
  2006: "Attribut muss mindestens eine Übersetzung enthalten",
  2007: "Attributkennung muss eindeutig sein",
  2008: "Attribut-Element Standardwert muss in der Elementlisten-ID enthalten sein",
  2009: "Element muss in Attribut vorhanden sein",
  2010: "Attribut sollte nicht in der Gruppe verwendet werden",
  2011: "Listentyp muss in der Elementübersetzung dieselbe Übersetzung haben",
  2012: "Attributgruppenkennung muss eindeutig sein",
  2013: "Attributgruppe wird in PIM-Produktfamilie verwendet",
  2014: "Attribut darf nicht in der Gruppe wiederholt werden",
  2015: "Attribut muss in der Gruppe vorhanden sein",
  2016: "Attribute Template Order Should Not Be Less Than Zero DE",
  2017: "AttributeGroupTemplateTypeMustNotBuiltin",
  2100: "AttributeTemplateTypeMustBeList",
  2101: "AttributeTemplateProviderTypeMustNotBuiltin",

  3000: "Produktkategorie kann nicht gelöscht werden, wenn sie in PIM-Produkt verwendet wird",
  3001: "Produktkategorie kann nur an verfügbare Kanäle veröffentlicht werden",
  3002: "Produktkategorie kann nur an integrierte Kanäle veröffentlicht werden",
  3003: "Produktkategorie-Kennung muss eindeutig sein",
  3004: "Produktkategorie kann nicht ihr eigener übergeordneter Kategorie sein",
  3005: "Übergeordneter Eintrag der Stammproduktkategorie kann nicht geändert werden",
  3006: "Die Produktkategorie und ihr übergeordneter Eintrag müssen sich in derselben Kategorie befinden",
  3007: "Die Produktkategorie darf keine Unterkategorien haben",
  3008: "Produktkategorie nicht gefunden",

  4000: "Lieferant nicht gefunden",
  4001: "Lieferanten-ILN muss eindeutig sein",
  4002: "Lieferant existiert bereits",
  4003: "Lieferant kann nicht gelöscht werden, wenn er in Selloff-Produkt verwendet wird",
  4004: "Lieferant kann nur an integrierte Kanäle veröffentlicht werden",
  4005: "Lieferantenkennung muss eindeutig sein in der Firma",
  4006: "SupplierBankInfoIsNotValid",
  4010: "Rabatt nicht gefunden",
  4011: "Ungültiger Rabattbetrag",
  4012: "Contact Person Not Found DE",

  5000: "Akzeptierter Anzahlungsbereich",
  5001: "Adresse darf nicht leer sein",
  5003: "Wichtige Dokumente müssen unterschrieben sein",
  5004: "Angebot sollte Artikel mit Anzahlung haben",
  5005: "SalesOfferDownPaymentIsNotSet",

  6000: "Attribut nicht gefunden",
  6001: "Konfigurierbares Programm hat keine Attribute",
  6002: "Programm nicht gefunden",
  6003: "Programmattributkennung muss eindeutig sein",
  6004: "InformationsSeite nicht gefunden",
  6005: "Media-ID muss für das Entfernen von Medien aus der Lahoma-Bestellung vorhanden sein",
  6006: "Das Entfernen von Bildern oder Dokumenten ist für eine laufende Lahoma-Bestellung nicht möglich",
  6007: "Dokument muss vorhanden sein, um es aus Lahoma-Bestellungen zu entfernen",
  6008: "Mediarichtung muss in perspektivischen Medien eindeutig sein",
  6009: "Aufgegebene Lahoma-Bestellung sollte innerhalb des Unternehmens eindeutig sein",
  6010: "Das Entfernen von Bildern oder Dokumenten ist für eine eingereichte Lahoma-Bestellung nicht möglich",
  6011: "Positionscode muss eindeutig sein",
  6012: "Banner-Positionsbauteile dürfen nur ein Element haben",
  6013: "Komponente existiert nicht",
  6014: "Layout der Informationsseite nicht gefunden",
  6015: "Informationsseitenkennung muss eindeutig sein",
  6016: "Channel-Einstellung sollte eine aktive Einstellung haben",
  6017: "Wert ist keine Artikel-ID",
  6018: "Lahoma-Bestellung nicht gefunden",
  6019: "Position nicht gefunden",
  6020: "Program Code Should Be Unique DE",

  7000: "PIM-Produkt nicht gefunden",
  7001: "Attributgruppe sollte nicht in PIM-Produktfamilienbaum verwendet werden",
  7002: "Attributgruppe muss im PIM-Produktfamilienbaum vorhanden sein",
  7003: "Blatt kann im PIM-Produktfamilienbaum entfernt werden",
  7004: "PIM-Produktfamilienkennung muss eindeutig sein",
  7005: "PIM-Produktfamilie kann nicht entfernt werden, wenn sie in PIM-Produkten verwendet wird",
  7006: "PIM-Produktstandort darf nicht wiederholt werden",
  7007: "PIM-Produktmedien können an integrierte Kanäle veröffentlicht werden",
  7008: "Artikelnummer muss eindeutig sein",
  7009: "Attributwerte dürfen weder null noch leer sein",
  7010: "PIM-Produktattribute dürfen nicht null sein",
  7011: "PIM-Produkt kann nur an integrierte Kanäle veröffentlicht werden",
  7012: "Artikelnummer liegt außerhalb des Bereichs",
  7013: "Eltern der PIM-Produktfamilie nicht gefunden",
  7014: "Pim Product Attribute Is Required Not Valid DE",
  7015: "Pim Product Attribute Is Per Language Not Valid DE",
  7016: "Pim Product Attribute Is Per Channel Not Valid DE",
  7018: "Pim Product Attribute Is Not In Pim Based On Pim Product Family Not Valid DE",
  7019: "Pim Product Published Required Attribute Missing Value DE",
  7020: "Pim Product Group Code Is Already Given DE",
  7021: "Pim Product Group Not Found DE",
  7022: "PimProductCategoryCanNotBeDuplicateInPimProduct",
  7023: "PimProductAttributesShouldBeUnique",
  7024: "PimProductSupplierIsNotSet",
  7025: "PimProductSupplierProgramIsNotSet",
  7026: "PimProductGroupIsNotSet",

  8000: "Position nicht gefunden",
  8001: "Doppelte Angebotsversion ist nicht zulässig",
  8002: "Angebotskundenadresse sollte in anderem Typ sein",
  8003: "Hinzufügen von Unterpunkt ist nicht möglich, wenn Position nicht gefunden",
  8004: "Nur aktives Angebot kann dupliziert werden",
  8005: "Nur letzte Version des Angebots kann dupliziert werden",
  8006: "Nur akzeptiertes Angebot kann reaktiviert werden",
  8007: "Nur abgelehntes Angebot kann reaktiviert werden",
  8008: "Ablehnen des Angebots ist nur für aktives Angebot zulässig",
  8009: "Entfernen des Dokuments ist nicht möglich, wenn das Dokument nicht gefunden wurde",
  8010: "Nicht vorhandene Position kann nicht entfernt werden",
  8011: "Entfernen des Unterpunkts ist nicht möglich, wenn die Position nicht gefunden wurde",
  8012: "Nicht vorhandener Unterpunkt kann nicht entfernt werden",
  8013: "Nicht vorhandenes Dokument kann nicht entfernt oder aktualisiert werden",
  8014: "Unterzeichnetes Dokument kann nicht entfernt oder aktualisiert werden",
  8015: "Aktualisieren der Position ist nicht möglich, wenn die Position nicht gefunden wurde",
  8016: "Nur aktives Angebot kann aktualisiert werden",
  8017: "Letzte Version des Angebots kann aktualisiert werden",
  8018: "Aktualisieren des Unterpunkts ist nicht möglich, wenn die Position nicht gefunden wurde",
  8019: "Aktualisieren des Unterpunkts ist nicht möglich, wenn der Unterpunkt nicht gefunden wurde",
  8020: "Nur aktive Bestellung kann storniert werden",
  8021: "Bestellung kann nur für integrierte Kanäle veröffentlicht werden",
  8022: "Veröffentlichung an ERP ist möglich, wenn Bestelldokumente mit Signatur erforderlich unterschrieben sind",
  8023: "Unterzeichnetes Dokument kann nicht entfernt oder aktualisiert werden",
  8024: "Aktive Bestellung kann aktualisiert werden",
  8025: "Angebot nicht gefunden",
  8026: "Dokument nicht gefunden",
  8027: "Bestellung nicht gefunden",
  8028: "Make Order Not Possible When Line Item Supplier Id Null DE",
  8029: "Make Order Not Possible When Sub Line Item Supplier Id Null DE",
  8030: "OfferLineItemNotFound",
  8031: "OfferDocumentNotFound",
  8032: "OfferIsExpired",
  8033: "CustomerNotFound",
  8034: "CustomerCompanyContactPersonNotFound",
  8035: "CustomerHasNotCimNumber",
  8036: "CustomerNotApproved",
  8037: "OrderLineNotFound",
  8038: "OfferLineItemHasReservedInInventory",
  8039: "OfferLineItemHasNotReservedInInventory",

  9000: "Vorbereitungsphase nicht gefunden",
  9001: "Abverkaufsprodukt nicht gefunden",
  9002: "Verkaufsphasenvorlage nicht gefunden",
  9003: "PreparationPhaseAlreadyCreated",
  9004: "Abverkaufsprodukt-Zeitplan nicht gefunden",
  9005: "Abverkaufsprodukt-Zeitplan existiert bereits",
  9006: "Checklistenpunkte nicht gefunden",
  9007: "Reihenfolge der Abverkaufsprodukt-Assets sollte eindeutig sein",
  9008: "Phasenwechsel ist nach der aktuellen Phasendauer möglich",
  9009: "Phasenwechsel ist nur für aktive Abverkaufsprodukte möglich",
  9010: "Phasenwechsel ist nur möglich, wenn alle Checklistenpunkte der Verkaufsphase überprüft wurden",
  9011: "Abschlussphase ist nur nach der letzten Phasendauer möglich",
  9012: "Abschlussphase ist nur für aktive Abverkaufsprodukte möglich",
  9013: "Letzte Phase kann nicht geändert werden",
  9014: "Media-ID muss vorhanden sein, um ein Asset aus dem Abverkaufsproduktzu entfernen",
  9015: "Die nächste Verkaufsphase sollte einen Checklistenpunkt enthalten",
  9016: "Nur die letzte Phase kann geschlossen werden",
  9017: "Die Vorbereitungsphase sollte einen Checklistenpunkt enthalten",
  9018: "Der tatsächliche Verkaufspreis sollte größer als Null sein",
  9019: "Das Entfernen eines Bildes ist für geschlossene Abverkaufsprodukte nicht möglich",
  9020: "Das Entfernen eines Bildes ist für verkaufte Abverkaufsprodukte nicht möglich",
  9021: "Das Umsortieren von Assets ist für geschlossene Abverkaufsprodukte nicht möglich",
  9022: "Das Umsortieren von Assets ist für verkaufte Abverkaufsprodukte nicht möglich",
  9023: "Die Startphase ist ohne Produktfamilie nicht möglich",
  9024: "Die Startphase ist ohne Verkaufsphasenvorlage nicht möglich",
  9025: "Verkauft ist nur nach dem Startdatum und der -uhrzeit des Abverkaufsprodukts möglich",
  9026: "Verkauft ist nur für aktive Abverkaufsprodukte möglich",
  9027: "Die Startphase ist nur für die Vorbereitung von Abverkaufsprodukten möglich",
  9028: "Die Startphase ist nur möglich, wenn alle Checklistenpunkte der Vorbereitungsphase überprüft wurden",
  9029: "Aktualisierung ist für geschlossene Abverkaufsprodukte nicht möglich",
  9030: "Aktualisierung ist für verkaufte Abverkaufsprodukte nicht möglich",
  9031: "Die Aktualisierung eines Checklistenpunkts der Vorbereitungsphase ist nur für die Vorbereitung von Abverkaufsprodukten möglich",
  9032: "Die Aktualisierung eines Checklistenpunkts der Verkaufsphase ist nur nach der aktuellen Phasendauer möglich",
  9033: "Die Aktualisierung eines Checklistenpunkts der Verkaufsphase ist nur für aktive Abverkaufsprodukte möglich",
  9034: "Die Aktualisierung einer Verkaufsphasenvorlage ist für aktive Abverkaufsprodukte nicht möglich",
  9035: "Das Zurückziehen eines verkauften Artikels ist nur für verkaufte Abverkaufsprodukte möglich",
  9036: "Die Verkaufsphasenvorlagenkennung muss eindeutig sein",

  11000: "Anbieter-Produkt-Importverfolgung nicht gefunden",
  11001: "Anbieter-Produkt-Import nicht gefunden",
  11002: "Provider Configuration Not Installed DE",
  11003: "B2B Provider Configuration Already Exist DE",
  11004: "Provider Configuration Not Found DE",

  12000: "Duplicate Iwofurn Configuration DE",
  12001: "Iwofurn Configuration Not Found DE",
  12002: "Store Iln Not Exist DE",
  12003: "Iwofurn Configuration Url Is Not Set DE",
  12004: "Iwofurn Configuration User Name Is Not Set DE",
  12005: "Iwofurn Configuration Password Is Not Set DE",
  12006: "IwofurnInternalTagIsNotFound",
  12007: "IwofurnOrderHasNoLineItem",
  12008: "IwofurnApiNotResponding",

  14000: "ShippingMethodWithThisCodeAlreadyExist",
  14001: "ShippingMethodNotFound",
  14002: "ShippingMethodCanNotBeRepeatedInProduct",
  14003: "ShippingMethodNotExistInProduct",

  14100: "DocumentTemplateNotFound",
  14101: "SalesOfferDocumentPdfNotCreated",
  14102: "SalesOfferDocumentNotFound",
  14103: "SalesOfferHasAccepted",
  14104: "SalesOfferHasNotActive",

  14200: "GeneralDiscountNotFound",
  14201: "PriceTemplateNotFound",
  14202: "PriceTemplateItemNotFound",
  14203: "PriceTemplateDiscountNotFound",
  14204: "GeneralDiscountCodeAlreadyExists",
  14205: "PriceTemplateCodeAlreadyExists",
  14206: "InvalidPriceTemplateDiscountsRequested",
  14207: "InvalidGeneralDiscountRequested",

  14300: "ErpInventoryConfigNotFound",
  14301: "ErpInventoryServiceLoginMicrosoftFailed",
  14302: "ErpInventoryServiceLoginDynamicsFailed",
  14303: "ErpInventoryServiceGetProductInventoryResponseHaveBadRequest",
  14304: "ErpInventoryServiceGetProductInventoryResponseProductNotAvailable",
  14305: "OnlyStoreHaveLegalEntityCodeCanUsedInErpInventoryService",
  14306: "ErpInventoryServiceReserveProductInInventoryResponseHaveBadRequest",
  14307: "ErpInventoryServiceReserveProductInInventoryNoResponse",
  14308: "ErpInventoryServiceUnreserveProductFromInventoryHaveBadRequest",
  14309: "ErpInventoryServiceUnreserveProductFromInventoryNoResponse",
  14310: "ErpInventoryServiceProductQuantityIsLessThanRequest",
  14311: "OnlyProductHaveArticleNumberCanUsedInErpInventoryService",

  14400: "SalesPersonInformationNotFound",

  A1000: "CompanyNotFound",
  A1001: "CustomerNotFound",
  A1002: "UserNotFount",
  A1003: "EmployeeNotFound",
  A1004: "UserGroupNotFound",
  A1005: "LegalEntityNotFound",
  A1006: "StoreNotFound",
  A1007: "MobileNumberIsAlreadyRegistered",
  A1008: "CustomerAccountNotFound",
  A1009: "MobileNumberVerificationCodeIsNotValid",
  A1010: "MobileNumberIsNotVerifiedYet",
  A1011: "EmailAddressIsNotVerified",
  A1012: "PassCodeShouldBeSet",
  A1013: "EmailAddressIsAlreadyRegistered",
  A1014: "PassCodeShouldBeValid",
  A1015: "EmailAddressIsNotMatch",
  A1016: "LegalEntityWithSameCodeAlreadyExist",
  A1017: "NewPassCodeIsNotMatch",
  A1018: "InvalidPassCode",
  A1019: "UserPrincipalNameNotTheSameAsEmailAddress",
  A1020: "UserAlreadySignedUp",
  A1021: "UserAlreadyExist",
  A1022: "DeviceTokenIsNotValid",
  A1023: "EmailConfirmationCodeIsExpired",
  A1024: "EmailConfirmationCodeIsNotValid",
  A1025: "MobileNumberIsNotConfirmed",
  A1026: "RemoveCustomerIsNotPossible",
  A1027: "CustomerMustAcceptGdpr",
  A1028: "CustomerMustBePerson",
  A1029: "MobileNumberMustRegistered",
  A1030: "CompanyTaxIdMustBeFilled",
  A1031: "CompanyRegistrationNumberMustBeUnique",
  A1032: "CustomerMustBeCompany",
  A1033: "CompanyContactPersonNotFound",
  A1034: "CountryNotFound",

  C9001: "ErpConfigurationNotFound",
  C9002: "SendMessageToErpFailed",
  C9003: "ChannelMessageNotFound",
  C9004: "FavoriteNotFound",
  C9005: "FavoriteItemShouldBeUnique",
  C9006: "FavoriteItemNotFound",
  C9007: "CanNotCreateIncident",
  C9008: "CanNotUpdateIncident",
  C9009: "CanNotCreateOrderInCrm",

  C9101: "CrmStoreNotFound",
  C9102: "CrmOrderNotFound",
  C9103: "CrmOrderDetailNotFound",
  C9104: "CrmCustomerNotFound",
  C9105: "CrmEmployeeNotFound",

  AS1001: "EloConfigNotFound",
  AS1002: "EloConfigAlreadyExist",
  AS1003: "EloServiceNotAvailable",

  AS2001: "PersonalAzureBlobConfigNotFound",
  AS2002: "TenantStorageConfigNotFound",
  AS2003: "StorageTypeNotSetForPulsFileType",

};

import { cloneDeep } from "lodash";
import {
  Dispatch,
  SetStateAction,
  createContext,
  createElement,
  useEffect,
  useState,
} from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router";
import { Loading, Wizard } from "../../../../components";
import { useAxios } from "../../../../hooks";
import Finalize from "./Finalize";
import Information from "./Information";
type dataType = {
  name: string;
  description: string;
  id: string;
  roles: string[];
};
type contextType = {
  data: dataType;
  setData: Dispatch<SetStateAction<dataType>>;
  handleSetValue: (key: keyof dataType) => (value: any) => void;
};
export const Context = createContext({} as contextType);
export default function UserGroupDetails() {
  const steps = [
    {
      label: "userManagement.userGroups.details.infoTab",
      text: "userManagement.userGroups.details.infoDesc",
      id: 0,
      component: Information,
    },
    {
      label: "userManagement.userGroups.details.finalizeTab",
      text: "userManagement.userGroups.details.finalizeDesc",
      id: 1,
      component: Finalize,
    },
  ];
  const navigate = useNavigate();
  const { userGroupId } = useParams();
  const isNew = userGroupId === "add";
  const { axios, loading } = useAxios();
  const [activeIndex, setActiveIndex] = useState(0);
  const [data, setData] = useState({} as dataType);
  const isFirstStep = activeIndex === 0;
  const isLastStep = activeIndex === steps.length - 1;
  const handleSetValue = (key: keyof dataType) => {
    return (value: any) =>
      setData((p) => {
        const data = cloneDeep(p);
        data[key] = value;
        return data;
      });
  };
  const handleCancel = () => {
    if (isFirstStep) return navigate(-1);
    setActiveIndex((p) => p - 1);
  };
  const getData = () => {
    if (isNew) return;
    const url = `/accountservice/api/usergroups/${userGroupId}`;
    axios.get(url).then(({ data }) => {
      const result = { ...data };
      result.roles = result.roles?.map((e: any) => e.id);
      setData(result);
    });
  };
  const addNew = () => {
    const url = "/accountservice/api/usergroups";
    const body = {
      name: data.name,
      description: data.description,
      roles: data.roles,
    };
    axios.post(url, body).then(({ data: id }) => {
      const url = `/user-groups/${id}`;
      const message = "userManagement.userGroups.details.addMessage";
      toast.success(message);
      navigate(url);
      setActiveIndex(0);
    });
  };
  const update = () => {
    const url = `/accountservice/api/usergroups/${userGroupId}`;
    const body = {
      name: data.name,
      description: data.description,
      roleIds: data.roles,
    };
    axios.put(url, body).then(() => {
      const message = "userManagement.userGroups.details.updateMessage";
      toast.success(message);
      setActiveIndex(0);
    });
  };
  const submit = () => {
    if (!isLastStep) return setActiveIndex((p) => p + 1);
    isNew ? addNew() : update();
  };
  useEffect(getData, [isNew, userGroupId]);
  if (loading.get) return <Loading.Inline />;
  return (
    <Wizard onSubmit={submit} onCancel={handleCancel} activeStep={activeIndex}>
      <Wizard.Items>
        {steps.map((e) => (
          <Wizard.Item
            key={e.id}
            index={e.id}
            label={e.label}
            description={e.text}
          />
        ))}
      </Wizard.Items>
      <Wizard.Tabs>
        <Context.Provider value={{ data, setData, handleSetValue }}>
          {steps.map((e) => (
            <Wizard.Tab
              key={e.id}
              index={e.id}
              label={e.label}
              loading={loading.post || loading.update}
              isLastStep={isLastStep}
            >
              {createElement(e.component)}
            </Wizard.Tab>
          ))}
        </Context.Provider>
      </Wizard.Tabs>
    </Wizard>
  );
}

import {
  createContext,
  Fragment,
  ReactNode,
  useContext,
  useEffect,
  useRef,
} from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
type tabContextType = {
  active: string | null;
  setActive: (val: string) => void;
};
type tabsProps = {
  children: ReactNode;
  activeKey?: string | null;
};
type tabWrapperProps = {
  children: ReactNode;
};
type tabButtonProps = {
  children: ReactNode;
  eventKey: string;
};
type tabItemProps = {
  children: ReactNode;
  eventKey: string;
};
const TabContext = createContext({} as tabContextType);
function Tabs({ activeKey = null, children }: tabsProps) {
  const [params, setParams] = useSearchParams({ tab: activeKey ?? "" });
  // const [active, setActive] = useState<string | null>(null);
  const active = params.get("tab");
  const setActive = (key: string) => {
    setParams((p) => {
      p.set("tab", key);
      return p;
    });
  };
  return (
    <TabContext.Provider value={{ active, setActive }}>
      {children}
    </TabContext.Provider>
  );
}
function TabButtonGroup({ children }: tabWrapperProps) {
  const divRef = useRef<HTMLDivElement>(null);
  const indicatorRef = useRef<HTMLSpanElement>(null);
  const { i18n } = useTranslation();
  const { active } = useContext(TabContext);
  const handleIndicator = () => {
    setTimeout(() => {
      const selector = "button[data-active='true']";
      const activeButton = divRef.current?.querySelector(selector);
      // @ts-ignore: Unreachable code error
      const buttonOffsetLeft = activeButton?.offsetLeft ?? 0;
      const buttonWidth = activeButton?.getBoundingClientRect().width ?? 0;
      const left = buttonOffsetLeft;
      const width = buttonWidth;
      indicatorRef.current?.style.setProperty("left", `${left}px`);
      indicatorRef.current?.style.setProperty("width", `${width}px`);
    }, 0);
  };
  useEffect(handleIndicator, [active, i18n.language]);
  return (
    <div
      ref={divRef}
      className="relative w-full flex items-center gap-4 overflow-auto"
    >
      <span
        className="absolute bottom-0 left-0 w-auto h-[1.5px] rounded-full bg-primary transition-[width,left]"
        ref={indicatorRef}
      />
      {children}
    </div>
  );
}
function TabButton({ eventKey, children }: tabButtonProps) {
  const { active, setActive } = useContext(TabContext);
  const isActive = active === eventKey;
  const handleClick = () => {
    setActive(eventKey);
  };
  return (
    <button
      data-active={isActive}
      type="button"
      onClick={handleClick}
      className="text-gray-500 border-b border-transparent px-1 py-4 data-active:text-primary transition-colors whitespace-nowrap min-w-fit font-medium"
    >
      {children}
    </button>
  );
}
function TabItem({ eventKey, children }: tabItemProps) {
  const { active } = useContext(TabContext);
  const isActive = active === eventKey;
  return <Fragment>{isActive && children}</Fragment>;
}
Tabs.ButtonGroup = TabButtonGroup;
Tabs.Button = TabButton;
Tabs.Item = TabItem;
export default Tabs;
// const handleIndicator = () => {
//   const indicator = indicatorRef.current;
//   const selector = "button[data-active='true']";
//   const button = divRef.current?.querySelector(selector);
//   if (!indicator) return;
//   const buttonRect = button?.getBoundingClientRect();
//   // @ts-ignore: Unreachable code error
//   const buttonLeft = button?.offsetLeft ?? 0;
//   const buttonWidth = buttonRect?.width ?? 0;
//   const indicatorRect = indicator.getBoundingClientRect();
//   const indicatorLeft = indicator.offsetLeft;
//   const indicatorWidth = indicatorRect.width;
//   const isLeft = indicatorLeft > buttonLeft;
//   const left = buttonLeft;
//   const width = isLeft
//     ? indicatorLeft + indicatorWidth - buttonLeft
//     : buttonWidth + buttonLeft - indicatorLeft;
//   isLeft && indicator.style.setProperty("left", `${left}px`);
//   indicator.style.setProperty("width", `${width}px`);
//   debounce(() => {
//     indicator.style.setProperty("left", `${buttonLeft}px`);
//     indicator.style.setProperty("width", `${buttonWidth}px`);
//   }, 300);
// };

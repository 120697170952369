import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  createContext,
  createElement,
  useEffect,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router";
import {
  Icon,
  Image,
  Loading,
  Tabs,
  Text,
  Wrapper,
} from "../../../../components";
import { accepts } from "../../../../constants";
import { useAxios, useUploadFile } from "../../../../hooks";
import { userDetailsType } from "../../../../types/user";
import General from "./General";

type contextType = {
  userData: userDetailsType;
  setUserData: Dispatch<SetStateAction<userDetailsType | null>>;
};

export const Context = createContext({} as contextType);

export default function UserDetails() {
  const inputFileRef = useRef<HTMLInputElement>(null);
  const { companyId, userId } = useParams();
  const { axios, loading } = useAxios();
  const uploadFile = useUploadFile();
  const [data, setData] = useState<userDetailsType | null>(null);
  const [loadingFile, setLoadingFile] = useState(false);
  const tabs = [
    {
      id: "1",
      label: "b2bManagement.users.details.generalTab",
      component: General,
    },
  ];
  const clickInput = () => {
    inputFileRef.current?.click();
  };
  const clearInput = () => {
    const element = inputFileRef.current;
    if (!element) return;
    element.value = "";
  };
  const updateAvatar = (e: ChangeEvent<HTMLInputElement>) => {
    setLoadingFile(true);
    uploadFile(e.target.files?.[0])
      .then((e) => {
        const url = `/accountservice/api/users/${userId}/b2b-admin-image-by-companyid`;
        const body = { companyId, ...e };
        axios.patch(url, body).then(() => {
          setData((p) => ({ ...p, imageUrl: e.url } as userDetailsType));
        });
      })
      .finally(() => {
        setLoadingFile(false);
        clearInput();
      });
  };
  const getData = () => {
    const url = `/accountservice/api/users/${userId}/${companyId}/b2b-admin`;
    axios.get(url).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, []);
  if (loading.get) return <Loading.Inline />;
  if (!data) return null;
  return (
    <section className="space-y-6">
      <input
        ref={inputFileRef}
        type="file"
        hidden
        accept={accepts.image}
        onChange={updateAvatar}
      />
      <Tabs activeKey={tabs[0].id}>
        <Wrapper>
          <Wrapper.Body className="flex flex-wrap gap-5">
            <button
              type="button"
              className="relative rounded-md overflow-hidden"
              onClick={clickInput}
            >
              <Image
                src={data.imageUrl}
                alt={data.firstName}
                className="w-32 max-w-full aspect-square object-contain bg-gray-100 data-loading:animate-pulse"
                data-loading={loadingFile || loading.update}
              />
              <span className="absolute top-0 right-0 w-8 h-8 flex-center bg-primary/80 text-white rounded-md">
                <Icon name="Edit2" />
              </span>
            </button>
            <div className="space-y-2">
              <h1 className="text-heading-5 text-gray-800">
                {data.firstName} {data.lastName}
              </h1>
              <p className="text-gray-500 text-body-2">
                <Icon name="User" variant="Bulk" /> {data.personalNumber ?? "-"}
              </p>
              <p className="text-gray-500 text-body-2">
                <Icon name="Sms" variant="Bulk" /> {data.emailAddress ?? "-"}
              </p>
              <p className="text-gray-500 text-body-2">
                <Icon name="Mobile" variant="Bulk" />{" "}
                {data.personalNumber ?? "-"}
              </p>
              <p className="text-gray-500 text-body-2">
                <Icon name="Profile2User" variant="Bulk" />{" "}
                {data.companyName ?? "-"}
              </p>
            </div>
          </Wrapper.Body>
          <Wrapper.Footer className="py-0">
            <Tabs.ButtonGroup>
              {tabs.map((e) => (
                <Tabs.Button key={e.label} eventKey={e.id}>
                  <Text>{e.label}</Text>
                </Tabs.Button>
              ))}
            </Tabs.ButtonGroup>
          </Wrapper.Footer>
        </Wrapper>
        <Context.Provider
          value={{
            userData: data,
            setUserData: setData,
          }}
        >
          {tabs.map((e) => (
            <Tabs.Item key={e.label} eventKey={e.id}>
              {createElement(e.component)}
            </Tabs.Item>
          ))}
        </Context.Provider>
      </Tabs>
    </section>
  );
}

import { useContext } from "react";
import { Context } from ".";
import { FileUploader } from "../../../../../components";
import { accepts } from "../../../../../constants";

export default function File() {
  const { data, handleSetValue } = useContext(Context);
  // b2bManagement.users.create.personalNumber
  return (
    <section>
      <div className="lg:w-2/5 mx-auto">
        <FileUploader
          value={data.uri}
          setValue={handleSetValue("uri")}
          accept={accepts.xml}
        />
      </div>
    </section>
  );
}

import {
  Dispatch,
  SetStateAction,
  createContext,
  createElement,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router";
import {
  Icon,
  Loading,
  Status,
  Tabs,
  Text,
  Wrapper,
} from "../../../../../components";
import { types } from "../../../../../constants";
import { useAxios, useConverter } from "../../../../../hooks";
import { providerProductItemType } from "../../../../../types/provider-product";
import Products from "./Products";

type contextType = {
  providerData: providerProductItemType;
  setProviderData: Dispatch<SetStateAction<providerProductItemType | null>>;
};

export const Context = createContext({} as contextType);

export default function ProviderProductDetails() {
  const { providerId } = useParams();
  const { axios, loading } = useAxios();
  const convert = useConverter();
  const [data, setData] = useState<providerProductItemType | null>(null);
  const tabs = [
    {
      id: "1",
      label: `New Products EN (${data?.newProducts})`,
      component: () => <Products status="1" />,
    },
    {
      id: "2",
      label: `Updated Products EN (${data?.newProducts})`,
      component: () => <Products status="2" />,
    },
  ];
  const getData = () => {
    const url = `/productservice/api/providerproductimportpursuits/${providerId}`;
    axios.get(url).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [providerId]);
  if (loading.get) return <Loading.Inline />;
  if (!data) return null;
  return (
    <section className="space-y-6">
      <Tabs activeKey={tabs[0].id}>
        <Wrapper>
          <Wrapper.Body className="flex flex-wrap gap-5">
            <div className="w-36 h-36 relative rounded-md flex-center bg-gray-100 text-gray-400">
              <Icon name="User" variant="Bulk" className="text-6xl" />
            </div>
            <div className="space-y-2">
              <h1 className="text-heading-5 text-gray-800">
                {
                  types.productProviders.find((e) => e.id === data.providerName)
                    ?.name
                }
              </h1>
              <p className="text-gray-500 text-body-2">
                <Icon name="Calendar" variant="Bulk" />{" "}
                {convert.date(data.createdAt)}
              </p>
              <p className="text-gray-500 text-body-2">
                <Icon name="Calendar" variant="Bulk" />{" "}
                {convert.date(data.updatedAt)}
              </p>
              <p className="text-gray-500 text-body-2">
                <Icon name="Document" variant="Bulk" />{" "}
                <Text>Unaffected Products EN</Text>: {data.unAffectedProducts}
              </p>
              <Status.Pursuit id={data.status} />
            </div>
          </Wrapper.Body>
          <Wrapper.Footer className="py-0">
            <Tabs.ButtonGroup>
              {tabs.map((e) => (
                <Tabs.Button key={e.label} eventKey={e.id}>
                  <Text>{e.label}</Text>
                </Tabs.Button>
              ))}
            </Tabs.ButtonGroup>
          </Wrapper.Footer>
        </Wrapper>
        <Context.Provider
          value={{
            providerData: data,
            setProviderData: setData,
          }}
        >
          {tabs.map((e) => (
            <Tabs.Item key={e.label} eventKey={e.id}>
              {createElement(e.component)}
            </Tabs.Item>
          ))}
        </Context.Provider>
      </Tabs>
    </section>
  );
}

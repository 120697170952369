import { Fragment, useContext, useEffect, useState } from "react";
import { Context } from ".";
import {
  Button,
  Loading,
  Pagination,
  SearchBox,
  Status,
  Table,
  Text,
} from "../../../../../components";
import { config } from "../../../../../constants";
import { ImportProductDetailsMenu } from "../../../../../containers";
import { useAxios } from "../../../../../hooks";
import { WithPaging } from "../../../../../types";
import { importProductItemType } from "../../../../../types/import-product";
type dataType = WithPaging<importProductItemType>;
type paramsType = {
  keyword: string;
  pageSize: string;
  pageNumber: string;
  pursuitId: string;
  status: string;
};
type productsProps = {
  status: string;
};
export default function Products({ status }: productsProps) {
  const { providerData } = useContext(Context);
  const { axios, loading } = useAxios();
  const [data, setData] = useState<dataType | null>(null);
  const [activeId, setActiveId] = useState("");
  const [params, setParams] = useState<paramsType>({
    keyword: "",
    pageSize: config.pageSize,
    pageNumber: "1",
    pursuitId: providerData.providerProductImportPursuitId,
    status: status,
  });
  const handleSetParams = (key: keyof paramsType) => {
    return (value: any) =>
      setParams((p) => {
        const params = { ...p };
        params[key] = value;
        return params;
      });
  };
  const clearActiveId = () => {
    setActiveId("");
  };
  const goToDetails = (id: string) => {
    return () => setActiveId(id);
  };
  const getData = () => {
    const url = "/productservice/api/providerproductimports/pursuit";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [params]);
  if (loading.get && !data) return <Loading.Inline />;
  if (!data) return null;
  return (
    <Fragment>
      <div className="flex items-center gap-4">
        <SearchBox
          value={params.keyword}
          setValue={handleSetParams("keyword")}
          className="w-auto basis-full lg:basis-1/3"
        />
      </div>
      <Table loading={loading.get}>
        {data.items?.map((e) => (
          <Fragment key={e.providerProductImportId}>
            <tr
              onClick={goToDetails(e.providerProductImportId)}
              className="cursor-pointer"
            >
              <td>
                <h2 className="text-heading-6 text-gray-800 whitespace-normal max-w-xs">
                  {e.title}
                </h2>
              </td>
              <td>
                <Status.ProductImported id={e.importInfo?.importedAs ?? 1} />
              </td>
              <td>
                <p className="text-body-2 text-gray-500">
                  <Text>Provider EN</Text>: {e.importInfo?.providerName}
                </p>
              </td>
              <td>
                <p className="text-body-2 text-gray-600">
                  <Text>Version EN</Text>: {e.version}
                </p>
              </td>
              <td>
                <Button type="button" variant="gray">
                  <Text>Details EN</Text>
                </Button>
              </td>
            </tr>
            <ImportProductDetailsMenu
              data={e}
              isOpen={activeId === e.providerProductImportId}
              toggle={clearActiveId}
            />
          </Fragment>
        ))}
      </Table>
      <Pagination
        pageNumber={params.pageNumber}
        setActivePage={handleSetParams("pageNumber")}
        totalItems={data.totalItems}
        totalPages={data.totalPages}
      />
    </Fragment>
  );
}

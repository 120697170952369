const productDetails = {
  title: "Product Details",
  closeBtn: "Close",
  importAgainBtn: "Import again",

  basicInfo: "Basic info",
  specification: "Technical specifications",
  media: "Media",

  productTitle: "Product Title",
  language: "Language",
  articleNumber: "Article Number",
  price: "Price",
  productFamily: "Product family",
  shortDescription: "Short Description",
  description: "Description",

  importedProductText: "This product was imported at",
};
export default productDetails;

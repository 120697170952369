import { useContext } from "react";
import { Context } from ".";
import { InputGroup } from "../../../../components";
import { rules } from "../../../../constants";

export default function Information() {
  const { data, handleSetValue } = useContext(Context);
  // b2bManagement.companies.list.addCompanyBtn
  return (
    <section className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-6">
      <div className="col-span-full">
        <InputGroup
          label="b2bManagement.companies.create.name"
          value={data.name}
          setValue={handleSetValue("name")}
          rules={rules.required}
        />
      </div>
      <div className="col-span-full">
        <InputGroup
          as="textarea"
          label="b2bManagement.companies.create.description"
          value={data.description}
          setValue={handleSetValue("description")}
        />
      </div>
      {/* <Select
        label="b2bManagement.companies.create.languages"
        value={data.languages}
        setValue={handleSetValue("languages")}
        items={languages}
        rules={rules.required}
        multiple
      />
      <Select
        label="b2bManagement.companies.create.defaultLanguage"
        value={data.defaultLanguage}
        setValue={handleSetValue("defaultLanguage")}
        items={languages.filter((e) => data.languages?.includes(e.id))}
        rules={rules.required}
      />
      <Select
        label="b2bManagement.companies.create.contentLanguages"
        value={data.contentLanguages}
        setValue={handleSetValue("contentLanguages")}
        items={languages}
        rules={rules.required}
        multiple
      />
      <Select
        label="b2bManagement.companies.create.defaultContentLanguage"
        value={data.defaultContentLanguage}
        setValue={handleSetValue("defaultContentLanguage")}
        items={languages.filter((e) => data.contentLanguages?.includes(e.id))}
        rules={rules.required}
      /> */}
      <InputGroup
        label="b2bManagement.companies.create.mobileNumber"
        value={data.mobileNumber}
        setValue={handleSetValue("mobileNumber")}
        // rules={rules.required}
      />
      <InputGroup
        label="b2bManagement.companies.create.phoneNumber"
        value={data.phoneNumber}
        setValue={handleSetValue("phoneNumber")}
        // rules={rules.required}
      />
    </section>
  );
}

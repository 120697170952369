import useAxios from "./useAxios";
export default function useSearchAddress() {
  const { axios } = useAxios();
  const searchAddress = async (params: any) => {
    const url = "/addressservice/api/address/search";
    const config = {params: {countrySet: params.countrySet, ...params}};
    return await axios.get(url, config).then(({data}) => {
      return data.results.map((e: any) => ({
        name: e.address?.freeformAddress,
        id: e.id,
        address: {
          street: e.address?.streetName,
          houseNo: e.address?.streetNumber,
          postalCode: e.address?.postalCode,
          city: e.address?.municipality,
          state: e.address?.countrySubdivision,
          country: e.address?.country,
          location: {longitude: e.position?.lon, latitude: e.position?.lat},
          countryCode: e.address.countryCode,
          countryCodeISO3: e.address.countryCodeISO3,
          countrySubdivision: e.address.countrySubdivision,
          countrySubdivisionName: e.address.countrySubdivisionName,
        },
      }));
    });
  };
  return searchAddress;
}

import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  Badge,
  Button,
  Icon,
  Pagination,
  SearchBox,
  Table,
  Text,
} from "../../../../components";
import { config } from "../../../../constants";
import { useAxios, usePermission } from "../../../../hooks";
import { WithPaging } from "../../../../types";
import { roleItemType } from "../../../../types/role";
import { withPermission } from "../../../../hoc";

type paramsType = {
  pageNumber: string;
  pageSize: string;
  keyword: string;
};

function Roles() {
  const navigate = useNavigate();
  const { axios, loading } = useAxios();
  const [data, setData] = useState({} as WithPaging<roleItemType>);
  const createPermission = usePermission("AS_CreateRole");
  const [params, setParams] = useSearchParams({
    pageNumber: "1",
    pageSize: config.pageSize,
    keyword: "",
  } as paramsType);
  const handleSetParams = (key: keyof paramsType) => {
    return (value: any) =>
      setParams((p) => {
        p.set(key, String(value));
        return p;
      });
  };
  const goToDetails = (id: string) => {
    return () => navigate(id);
  };
  const getData = () => {
    const url = "/accountservice/api/roles";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [params]);
  return (
    <section className="space-y-4">
      <div className="flex flex-wrap items-center gap-4">
        <SearchBox
          value={params.get("keyword")}
          setValue={handleSetParams("keyword")}
          className="w-auto basis-full lg:basis-1/3"
        />
        {createPermission &&
          <Button as={Link} to="add" className="w-fit ms-auto">
            <Icon name="Add" /> <Text>userManagement.roles.list.addNewBtn</Text>
          </Button>
        }
      </div>
      <Table loading={loading.get}>
        {data.items?.map((e) => (
          <tr key={e.id} onClick={goToDetails(e.id)} className="cursor-pointer">
            <td>
              <h5 className="text-gray-800 text-heading-5">{e.name}</h5>
            </td>
            <td>
              <p className="text-gray-600 text-body-1 whitespace-normal max-w-sm">
                {e.description}
              </p>
            </td>
            <td>
              <Badge variant="success">
                <Text>userManagement.roles.list.active</Text>
              </Badge>
            </td>
            <td>
              <Button type="button" variant="gray">
                <Text>userManagement.roles.list.editBtn</Text>
              </Button>
            </td>
          </tr>
        ))}
      </Table>
      <Pagination
        pageNumber={params.get("pageNumber")}
        setActivePage={handleSetParams("pageNumber")}
        totalItems={data.totalItems}
        totalPages={data.totalPages}
      />
    </section>
  );
}

export default withPermission(Roles, ['AS_GetRole']);
import { navItemType } from "../types";

const navItems: navItemType[] = [
  {
    label: "layout.dashboard",
    path: "/",
    icon: "Home",
    permission: ["withoutPermission"]
  },
  {
    label: "layout.b2bManagement.root",
    icon: "More",
    permission: ["AS_GetCompany"],
    items: [
      {
        label: "layout.b2bManagement.companies",
        path: "/companies",
        icon: "Bag2",
        permission: ["AS_GetCompany"]
      },
      // {
      //   label: "layout.b2bManagement.users",
      //   path: "/users",
      //   icon: "Profile2User",
      // },
    ],
  },
  // {
  //   label: "layout.productManagement.root",
  //   icon: "Shop",
  //   items: [
  //     {
  //       label: "layout.productManagement.providers.root",
  //       icon: "More",
  //       items: [
  //         {
  //           label: "layout.productManagement.providers.providerProducts",
  //           path: "/provider-products",
  //           icon: "ShoppingCart",
  //         },
  //         {
  //           label: "layout.productManagement.providers.importProducts",
  //           path: "/import-products",
  //           icon: "Chart1",
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    label: "layout.userManagement.root",
    icon: "Profile2User",
    permission: ["AS_GetUserGroup","AS_GetUser","AS_GetRole"],
    items: [
      {
        label: "layout.userManagement.allPlatformUsers",
        path: "/platform-users",
        icon: "Profile2User",
        permission: ["AS_GetUser"]
      },
      {
        label: "layout.userManagement.userGroups",
        path: "/user-groups",
        icon: "Bag2",
        permission: ["AS_GetUserGroup"]
      },
      {
        label: "layout.userManagement.roles",
        path: "/roles",
        icon: "DocumentText",
        permission: ["AS_GetRole"]
      },
    ],
  },
];
export default navItems;

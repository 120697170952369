import {useTranslation} from "react-i18next";
import {SelectSearchable} from "..";
import {useEffect, useState} from "react";
import { useAxios } from "../../hooks";
import { Country, CountrySelectOption } from "../../types/country";

type Props = {
  setValue: (v: CountrySelectOption) => void;
  value?: string;
};

export default function SelectCountry({setValue, value}: Props) {
  const {i18n} = useTranslation();
  const [allCountries, setAllCountries] = useState<[CountrySelectOption]>();
  const { axios } = useAxios();
  const promiseOptionsCountry = (inputValue: string) =>
    new Promise<any[]>(resolve => {
      setTimeout(() => {
        resolve(handleSearchCountries(inputValue));
      }, 200);
    });
  const [selected, setSelected] = useState<CountrySelectOption>();

  const handleSearchCountries = async (filter: string) => {
    const url = "accountservice/api/countries/default-countries?";
    const params = {
      keyword: filter,
    };
    return axios.get(url, {params}).then(({data}) => {
      const countries = data.map((country: Country) => ({
        value: country.countryIso2,
        label: country.translates.find(
          (item: any) => item.language === i18n.language,
        )?.labelTitle,
        country: country,
      }));
      if (!filter) setAllCountries(countries);
      return countries;
    });
  };

  const selectOptionByValue = () => {
    let defaultItem: CountrySelectOption[] | undefined;
    if (value)
      defaultItem = allCountries?.filter(
        (item: CountrySelectOption) => item.value === value,
      );
    else
      defaultItem = allCountries?.filter(
        (item: CountrySelectOption) => item.value === "DE",
      );

    if (defaultItem && defaultItem?.length > 0) {
      setSelected(defaultItem[0]);
      if (value) setValue(defaultItem[0]);
    }
  };

  const onCountryChangeHandler = (selected: CountrySelectOption) => {
    if (selected) {
      setSelected?.(selected);
      setValue(selected);
    }
  };

  useEffect(() => {
    if (allCountries) {
      selectOptionByValue();
    }
  }, [value, allCountries]);

  return (
    <SelectSearchable
      label="global.locations.country"
      value={selected || ""}
      cacheOptions
      loadOptions={promiseOptionsCountry}
      defaultOptions
      onChange={onCountryChangeHandler}
    />
  );
}

import { Fragment, useContext, useEffect, useState } from "react";
import { Context } from "../";
import {
  Button,
  Icon,
  Image,
  Pagination,
  SearchBox,
  Table,
  Text,
  Types,
} from "../../../../../components";
import { config } from "../../../../../constants";
import { useAxios, useToggleState } from "../../../../../hooks";
import { Company, WithPaging } from "../../../../../types";
import CreateUser from "./CreateUser";
import UsersDetails from "./UsersDetails";
type Data = WithPaging<Company.User>;
type Params = {
  pageNumber: number;
  pageSize: number;
  keyword?: string;
  tenantId?: string;
  userGroupId?: string;
};
export default function Users() {
  const { axios, loading } = useAxios();
  const { companyData } = useContext(Context);
  const [data, setData] = useState({} as Data);
  const initParams: Params = {
    pageNumber: 1,
    pageSize: +config.pageSize,
    tenantId: companyData.companyId,
  };
  const [params, setParams] = useState(initParams);
  const [activeId, setActiveId] = useState("");
  const [showCreateUser, toggleCreateUser] = useToggleState(false);
  const handleSetActiveId = (id: string) => {
    return () => setActiveId(id);
  };
  const clearActiveId = () => {
    setActiveId("");
  };
  const resetParams = () => {
    setParams(initParams);
  };

  const handleSubmitted = () => {
    const stringInitParams = JSON.stringify(initParams);
    const stringParams = JSON.stringify(params);
    if (stringInitParams === stringParams) return getData();
    resetParams();
  };
  const handleSetParams = (key: keyof Params) => {
    const isPageNumber = key === "pageNumber";
    return (value: any) =>
      setParams((p) => ({
        ...p,
        [key]: value,
        ...(!isPageNumber && { pageNumber: 1 }),
      }));
  };
  const getData = () => {
    const url = "/accountservice/api/users/company";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [params]);
  return (
    <Fragment>
      <div className="flex flex-wrap items-center gap-4">
        <SearchBox
          value={params.keyword}
          setValue={handleSetParams("keyword")}
          className="w-auto basis-full lg:basis-1/3"
        />
        <Button
          type="button"
          className="w-fit ms-auto"
          onClick={toggleCreateUser}
        >
          <Icon name="Add" />{" "}
          <Text>b2bManagement.companies.details.addAdminUserBtn</Text>
        </Button>
      </div>
      <Table loading={loading.get}>
        {data.items?.map((e) => (
          <Fragment key={e.userId}>
            <tr
              className="cursor-pointer"
              onClick={handleSetActiveId(e.userId)}
            >
              <td>
                <div className="flex items-center gap-4">
                  <div className="w-20 h-20 flex-center rounded bg-gray-100 text-gray-400 overflow-hidden">
                    {!!e.avatar?.url ? (
                      <Image
                        src={e.avatar?.url}
                        alt={e.firstName + e.lastName}
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <Icon name="User" variant="Bulk" className="text-5xl" />
                    )}
                  </div>
                  <div className="flex-1 space-y-1">
                    <h6 className="text-gray-800 text-heading-6">
                      {e.firstName} {e.lastName}
                    </h6>
                    <p className="text-gray-500 text-body-2">
                      <Icon name="Sms" variant="Bulk" /> {e.emailAddress}
                    </p>
                  </div>
                </div>
              </td>
              <td>
                <Types.User id={e.userType} />
              </td>
              <td>
                <Button as="span" type="button" variant="gray">
                  <Text>b2bManagement.companies.details.detailsBtn</Text>
                </Button>
              </td>
            </tr>
            <UsersDetails
              user={e}
              isOpen={e.userId === activeId}
              toggle={clearActiveId}
            />
          </Fragment>
        ))}
      </Table>
      <Pagination
        pageNumber={params.pageNumber}
        setActivePage={handleSetParams("pageNumber")}
        totalItems={data.totalItems}
        totalPages={data.totalPages}
      />
      <CreateUser
        isOpen={showCreateUser}
        toggle={toggleCreateUser}
        onSubmitted={handleSubmitted}
      />
    </Fragment>
  );
}

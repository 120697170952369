const platformUsers = {
  list: {
    addNewBtn: "Add new DE",
    detailsBtn: "Details DE",
    userGroup: "User group DE",
  },
  create: {
    basicInfoTab: "Basic info DE",
    basicInfoDesc: "Name,Email ... DE",
    userGroupTab: "User Group DE",
    userGroupDesc: "User group DE",
    addressTab: "Address DE",
    addressDesc: "City, Street, ... DE",
    finalizeTab: "Finalize DE",
    finalizeDesc: "Finalize information DE",

    userType: "Type DE",
    fullName: "Full name DE",
    email: "Email DE",
    firstName: "First Name DE",
    lastName: "Last Name DE",
    dateOfBirth: "Date Of Birth DE",
    gender: "Gender DE",
    mobileNumber: "Mobile Number DE",
    phoneNumber: "Phone Number DE",

    userGroup: "User group DE",

    name: "Name DE",
    street: "Street DE",
    houseNumber: "House number DE",
    postalCode: "Postal code DE",
    city: "City DE",
    country: "Country DE",
    state: "State DE",

    backBtn: "Back DE",
    nextBtn: "Next DE",
    submitBtn: "Submit DE",

    successMessage: "User has been added. DE",
  },
  details: {
    generalTab: "General DE",

    basicInfoTitle: "Basic info DE",

    userType: "Type DE",
    firstName: "First name DE",
    lastName: "Last name DE",
    gender: "Gender DE",
    dateOfBirth: "Date of birth DE",
    personalNumber: "Personal number DE",
    email: "Email DE",
    userGroup: "User group DE",
    mobileNumber: "Mobile Number DE",
    phoneNumber: "Phone Number DE",

    addressTitle: "Address DE",

    name: "Name DE",
    street: "Street DE",
    houseNumber: "House number DE",
    postalCode: "Postal code DE",
    city: "City DE",
    country: "Country DE",
    state: "State DE",

    discardBtn: "Discard DE",
    saveChangedBtn: "Save changed DE",

    successMessage: "User has been updated. DE",
  },
};
export default platformUsers;

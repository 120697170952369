import { Fragment } from "react";
import { config } from "../constants";

type listProps = {
  length?: number;
};
const loadingItems = [{ delay: "0" }, { delay: "-500ms" }, { delay: "0" }];
function LoadingInline() {
  return (
    <div className="w-fit flex-center gap-[2px] mx-auto p-4">
      {loadingItems.map((e, i) => (
        <span
          key={i}
          style={{ animationDelay: e.delay }}
          className="w-5 h-5 rounded-full bg-primary animate-loader"
        />
      ))}
    </div>
  );
}
function LoadingTr({ length = Math.floor(+config.pageSize / 2) }: listProps) {
  return (
    <Fragment>
      {[...Array(length).keys()].map((e) => (
        <tr key={e} className="animate-pulse">
          <td>
            <section className="flex items-center gap-3 w-fit">
              <i className="w-32 h-20 rounded bg-gray-100" />
              <div className="max-w-full space-y-2">
                <span className="max-w-full block w-48 h-4 rounded-full bg-gray-100" />
                <span className="max-w-full block w-24 h-4 rounded-full bg-gray-100" />
              </div>
            </section>
          </td>
          {/* <td>
            <span className="max-w-full block w-32 h-4 rounded-full bg-gray-100" />
          </td> */}
          <td>
            <span className="max-w-full inline-block w-24 h-10 rounded bg-gray-100" />
          </td>
        </tr>
      ))}
    </Fragment>
  );
}

function LoadingButton() {
  return (
    <div className="absolute inset-0 m-auto w-fit h-fit flex-center gap-[2px]">
      {loadingItems.map((e, i) => (
        <span
          key={i}
          style={{ animationDelay: e.delay }}
          className="w-2 h-2 rounded-full animate-loader bg-current"
        />
      ))}
    </div>
  );
}

const Loading = {
  Tr: LoadingTr,
  Inline: LoadingInline,
  Button: LoadingButton,
};
export default Loading;

const global = {
  noItems: "No item found DE",
  pagination: "Showing {{ from }} to {{ to }} of {{ totalItems }} entries DE",
  searchBox: "Search... DE",
  noAccessText: 'You have no access to dashboard',
  pleaseLoginAccount: 'Please login with another account',
  lgoinToDashboard: 'Login to dashboard with another account',
  wizard: {
    backBtn: "Back DE",
    nextBtn: "Next DE",
    submitBtn: "Submit DE",
  },
  uploadFile: {
    title: "Upload file DE",
    description: "Drag or drop file DE",
  },
  channels: {
    digitalStorefront: "Digitales Schaufenster",
    customerTerminal: "Digitaler Kiosk",
    crm: "CRM Channel",
    erp: "ERP Channel",
    salesTools: "Sales Tools Channel",
    onlineShop: "Online Shop Channel",
  },
  status: {
    pursuit: {
      unknown: "Unknown DE",
      inProgress: "InProgress DE",
      fail: "Fail DE",
      success: "Success DE",
    },
    productImported: {
      unknown: "unknown DE",
      newProductCreated: "New Product Created DE",
      productIsUpdated: "Product is updated DE",
      fail: "Fail DE",
    },
  },
  types: {
    gender: {
      male: "Male DE",
      female: "Female DE",
    },
    user: {
      none: "None DE",
      admin: "Admin DE",
      user: "User DE",
    },
  },
  locations: {
    street: "Straße",
    houseNumber: "Hausnummer",
    postalCode: "Postleitzahl",
    city: "Stadt",
    country: "Land",
    state: "Bundesland",
    location: "Standort",
    locationOnMap: "Standort auf Karte",
    address: "Adresse",
    searchPlaceHolder: "Adressensuche",
    name: "Name DE",
  },
};
export default global;

export const config = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  tenant: process.env.REACT_APP_TENANT,
  b2cTenant: process.env.REACT_APP_B2C_TENANT,
  clientId: process.env.REACT_APP_CLIENT_ID ?? "",
  b2cSigningPolicy: process.env.REACT_APP_B2C_SIGNIN_POLICY,
  redirectUrl: process.env.REACT_APP_REDIRECT_URL,
  scope: process.env.REACT_APP_SCOPE,
  subscriptionKey: process.env.REACT_APP_SUBSCRIPTION_KEY,
  lang: "de",
  isDevelopment:
    !process.env.NODE_ENV || process.env.NODE_ENV === "development",
  pageSize: "10",
  styleSelectReact:
  {
    control: (baseStyles: any, isSelected: any) => ({
      ...baseStyles,
      "&:hover": {
        borderColor: "none"
      },
      borderColor: 'rgb(52 94 172 / 10%)',
      backgroundColor: "#F5F8FA",
      height: "44px",
      borderRadius: "6px",
      boxShadow: 'none',
      '&:focus': {
        border: '0 !important',
      },
    }),
    dropdownIndicator: (base: any) => ({
      ...base,
      color: "#3F4254",
      opacity: "40%"
    }),
    clearIndicator: (base: any) => ({
      ...base,
      color: "#3F4254",
      opacity: "40%"
    }),
  }
};

import {
  ComponentProps,
  createContext,
  ElementType,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { twMerge } from "tailwind-merge";
import { asProps } from "../types";
import Icon from "./_Icon";

type contextType = {
  toggleShow: () => void;
  onSelect: (val: any) => void;
  drop: "left" | "right";
};
type dropdownElementProps = {
  className?: string;
  children?: ReactNode;
};
type dropdownProps = {
  onSelect?: (val: any) => void;
  drop?: "left" | "right";
} & dropdownElementProps;
type dropdownItemProps = {
  isActive?: boolean;
  eventKey?: any;
  onClick?: () => void;
} & dropdownElementProps;
const DropdownContext = createContext({} as contextType);

function Dropdown({
  className = "",
  onSelect = () => {},
  drop = "right",
  children,
}: dropdownProps) {
  const ref = useRef<HTMLDivElement>(null);
  const [show, setShow] = useState(false);
  const toggleShow = () => {
    setShow((p) => !p);
  };
  const handleClick = (e: MouseEvent) => {
    const outside = !ref.current?.contains(e.target as Node);
    outside && setShow(false);
  };
  useEffect(() => {
    window.addEventListener("click", handleClick);
    return () => {
      window.removeEventListener("click", handleClick);
    };
  }, []);
  return (
    <div
      ref={ref}
      data-active={show}
      className={`dropdown inline-block relative group ${className}`}
    >
      <DropdownContext.Provider value={{ toggleShow, onSelect, drop }}>
        {children}
      </DropdownContext.Provider>
    </div>
  );
}
function DropdownToggle<E extends ElementType = "button">({
  as,
  className = "",
  children = null,
  ...props
}: dropdownElementProps & ComponentProps<E> & asProps<E>) {
  const Component = as || "button";
  const { toggleShow } = useContext(DropdownContext);
  return (
    <Component
      className={`dropdown-toggle ${className}`}
      onClick={toggleShow}
      {...props}
    >
      {children}
      <Icon name="ArrowDown2" size={18} className="ml-1" />
    </Component>
  );
}
function DropdownMenu({
  className = "",
  children = null,
}: dropdownElementProps) {
  const { drop } = useContext(DropdownContext);
  return (
    <ul
      style={{ [drop]: 0, transformOrigin: `top ${drop}` }}
      className={twMerge(
        "dropdown-menu absolute w-full min-w-[10rem] text-body-1 font-medium bg-white rounded shadow-sm border border-gray-200 top-full z-10 transition-[transform,opacity] pointer-events-none opacity-0 scale-90 group-data-active:pointer-events-auto group-data-active:opacity-100 group-data-active:scale-100 py-1 px-2 space-y-1",
        className
      )}
    >
      {children}
    </ul>
  );
}
function DropdownItem({
  className = "",
  children = null,
  eventKey = "",
  onClick,
  isActive,
}: dropdownItemProps) {
  const { toggleShow, onSelect } = useContext(DropdownContext);
  const handleClick = () => {
    onSelect(eventKey);
    toggleShow();
    onClick?.();
  };
  return (
    <li
      className={twMerge(
        "dropdown-item block text-start py-2 px-4 hover:bg-primary hover:text-white cursor-pointer rounded-md transition-colors",
        isActive && "bg-primary-light text-primary",
        className
      )}
      onClick={handleClick}
    >
      {children}
    </li>
  );
}
Dropdown.Toggle = DropdownToggle;
Dropdown.Menu = DropdownMenu;
Dropdown.Item = DropdownItem;
export default Dropdown;

import { ReactNode, useEffect, useRef } from "react";
import ReactDOM from "react-dom";

type portalProps = {
  children: ReactNode;
};
export default function Portal({ children }: portalProps) {
  const divRef = useRef(document.createElement("div"));
  useEffect(() => {
    const div = divRef.current;
    const id = crypto.randomUUID();
    div.dataset.key = id;
    document.body.appendChild(div);
    return () => {
      document.body.removeChild(div);
    };
  }, []);
  return ReactDOM.createPortal(children, divRef.current);
}

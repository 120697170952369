const companies = {
  list: {
    detailsBtn: "Details",
    addCompanyBtn: "Add company",
  },
  create: {
    companyInformationTab: "Company Information",
    addressInformationTab: "Address Information",
    adminInfoTab: "User admin information",
    finalizeTab: "Finalize",
    companyInformationDesc: "Name, Description ...",
    addressInformationDesc: "Street, city ,...",
    adminInfoDesc: "Name, Email",
    finalizeDesc: "Finalize information",

    successMessage: "Company has been added.",

    backBtn: "Back",
    nextBtn: "Next",
    submitBtn: "Submit",

    name: "Company name",
    description: "Short description",
    mobileNumber: "Mobile Number",
    phoneNumber: "Phone Number",

    addressName: "Name",
    street: "Street",
    houseNumber: "House Number",
    postalCode: "Postal Code",
    city: "City",
    state: "State",
    country: "Country",

    adminFirstName: "First name",
    adminLastName: "Last name",
    adminEmailAddress: "Email address",
  },
  details: {
    profileTab: "Profile",
    channelsTab: "Channels",
    settingsTab: "Settings",
    usersTab: "Users",

    basicInfoTitle: "Basic info DE",
    locationTitle: "Location DE",

    settingSuccessMessage: "Company settings has been updated.",
    successMessage: "Company has been updated.",

    saveChangedBtn: "Save Changes",
    discardBtn: "Discard",
    submitBtn: "Submit",
    cancelBtn: "Cancel",

    name: "Company name",
    description: "Short description",
    customerNumberPrefix: "Customer Prefix Number",
    dashboardLanguages: "Dashboard Languages",
    defaultDashboardLanguage: "Default Dashboard Language",
    contentLanguages: "Content Languages",
    defaultContentLanguage: "Default Content Language",

    addressName: "Name",
    street: "Street",
    houseNumber: "House Number",
    postalCode: "Postal Code",
    city: "City",
    state: "State",
    country: "Country",
    mobileNumber: "Mobile Number",
    phoneNumber: "Phone Number",

    channelActive: "Active",

    channelAvailableLanguages: "Available Languages",
    channelDefaultLanguage: "Default Language",
    channelAvailableCurrencies: "Available Currencies",
    channelActivate: "Activate",

    adminBadge: "Admin",

    addAdminUserBtn: "Add admin user",
    addAdminUserTitle: "Add admin user",
    addAdminUserSuccessMessage: "User has been added successfully.",
    detailsBtn: "Details",

    adminInfoTitle: "Admin info",
    userInfoTitle: "User info",
    closeBtn: "Close",
    userFirstName: "First name",
    userLastName: "Last name",
    userEmailAddress: "Email address",
    resendLoginInfoBtn: "Resend login info email",
  },
};
export default companies;

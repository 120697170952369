const roles = {
  list: {
    addNewBtn: "Add new role",
    editBtn: "Edit",
    active: "Active",
  },
  details: {
    basicInfoTab: "Basic information",
    basicInfoDesc: "Name, Description ...",
    roleInfoTab: "Role information",
    roleInfoDesc: "Authorizations",
    finalizeTab: "Finalize",
    finalizeDesc: "Finalize information",

    roleName: "Role name",
    shortDescription: "Short description",

    togglePermissions: "Select all/ Deselect All",

    backBtn: "Back",
    nextBtn: "Next",
    submitBtn: "Submit",
    addMessage: "Role has been added successfully.",
    updateMessage: "Role has been updated successfully.",
  },
};
export default roles;

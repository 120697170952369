import { useContext } from "react";
import { Context } from ".";
import { Select } from "../../../../../components";
import { languages, rules, types } from "../../../../../constants";

export default function BasicInfo() {
  const { data, handleSetValue } = useContext(Context);
  return (
    <section className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-6">
      <Select
        label="Provider name EN"
        value={data.providerName}
        setValue={handleSetValue("providerName")}
        items={types.productProviders}
        rules={rules.required}
      />
      <Select
        label="Language EN"
        value={data.language}
        setValue={handleSetValue("language")}
        items={languages}
        rules={rules.required}
      />
      <div className="col-span-full">
        <Select
          label="Configuration version EN"
          value={data.configurationVersion}
          setValue={handleSetValue("configurationVersion")}
          items={[1].map((e) => ({
            name: `Version EN ${e}`,
            id: e.toString(),
          }))}
          rules={rules.required}
        />
      </div>
    </section>
  );
}

import { useAccount } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import { Dropdown, Icon } from "../../components";
import { authInstance, languages } from "../../constants";
import { getToken } from "../../methods";
type headerProps = {
  toggleMenu: () => void;
};
export default function Header({ toggleMenu }: headerProps) {
  const { i18n } = useTranslation();
  const profile = useAccount();
  const activeLanguage = languages.find((e) => e.id === i18n.language);
  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };
  const logout = () => {
    getToken().then((token) => {
      if (!token) return;
      authInstance.logoutRedirect({ idTokenHint: token });
    });
  };
  return (
    <header className="sticky w-full bg-white/75 top-0 shadow z-10 backdrop-blur-sm">
      <div className="w-full h-16 flex items-center justify-end gap-4 px-2 max-w-screen-2xl mx-auto lg:px-8">
        <button
          type="button"
          className="block lg:hidden mr-auto text-2xl"
          onClick={toggleMenu}
        >
          <Icon name="HambergerMenu" />
        </button>
        <Dropdown onSelect={changeLanguage}>
          <Dropdown.Toggle
            type="button"
            className="text-gray-600 font-medium text-body-1"
          >
            {activeLanguage?.name}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {languages.map((e) => (
              <Dropdown.Item
                key={e.id}
                eventKey={e.id}
                isActive={e.id === activeLanguage?.id}
              >
                {e.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown>
          <Dropdown.Toggle className="flex-center gap-4 [&>svg]:hidden">
            <span className="text-gray-600">{profile?.name}</span>
            <span className="w-10 h-10 inline-flex flex-center bg-gray-200 rounded-md">
              <Icon name="User" variant="Bulk" className="text-2xl" />
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu className="w-fit space-y-4">
            <div className="flex items-center gap-4">
              <span className="w-12 h-12 inline-flex flex-center bg-gray-200 rounded-md">
                <Icon name="User" variant="Bulk" className="text-2xl" />
              </span>
              <div className="flex-1 space-y-1 text-start">
                <p className="block text-sm">{profile?.name}</p>
                <p className="block text-xs text-gray-600">
                  {profile?.username}
                </p>
              </div>
            </div>
            <Dropdown.Item
              className="text-danger hover:bg-danger"
              onClick={logout}
            >
              Logout en
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </header>
  );
}

import NoAccessImage from "./assets/image/no-access.svg";
import { Text, Button } from "./components";

type NoAccessProps = {
  onClick: () => void;
};

export default function NoAccess({ onClick }: NoAccessProps) {
  return (
    <div className="flex h-screen items-center justify-center">
      <div className="flex flex-col items-center space-y-4">
        <img src={NoAccessImage} alt="no-access" />
        <span className="text-heading-5 font-semibold text-gray-800">
          <Text>global.noAccessText</Text>
        </span>
        <span className="text-base font-light text-gray-500">
          <Text>global.pleaseLoginAccount</Text>
        </span>
        <Button onClick={onClick}>
          <Text>global.lgoinToDashboard</Text>
        </Button>
      </div>
    </div>
  );
}

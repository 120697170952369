const platformUsers = {
  list: {
    addNewBtn: "Add new",
    detailsBtn: "Details",
    userGroup: "User group",
  },
  create: {
    basicInfoTab: "Basic info",
    basicInfoDesc: "Name,Email ...",
    userGroupTab: "User Group",
    userGroupDesc: "User group",
    addressTab: "Address",
    addressDesc: "City, Street, ...",
    finalizeTab: "Finalize",
    finalizeDesc: "Finalize information",

    userType: "Type",
    fullName: "Full name",
    email: "Email",
    firstName: "First Name",
    lastName: "Last Name",
    dateOfBirth: "Date Of Birth",
    gender: "Gender",
    mobileNumber: "Mobile Number",
    phoneNumber: "Phone Number",

    userGroup: "User group",

    name: "Name",
    street: "Street",
    houseNumber: "House number",
    postalCode: "Postal code",
    city: "City",
    country: "Country",
    state: "State",

    backBtn: "Back",
    nextBtn: "Next",
    submitBtn: "Submit",

    successMessage: "User has been added.",
  },
  details: {
    generalTab: "General",

    basicInfoTitle: "Basic info",

    userType: "Type",
    firstName: "First name",
    lastName: "Last name",
    gender: "Gender",
    dateOfBirth: "Date of birth",
    personalNumber: "Personal number",
    email: "Email",
    userGroup: "User group",
    mobileNumber: "Mobile Number",
    phoneNumber: "Phone Number",

    addressTitle: "Address",

    name: "Name",
    street: "Street",
    houseNumber: "House number",
    postalCode: "Postal code",
    city: "City",
    country: "Country",
    state: "State",

    discardBtn: "Discard",
    saveChangedBtn: "Save changed",

    successMessage: "User has been updated.",
  },
};
export default platformUsers;

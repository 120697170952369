import { Image } from "../types";
import useAxios from "./useAxios";

type fileType = Blob | string | undefined | null;
type uploadFileType = (file: fileType) => Promise<Image>;

export default function useUploadFile() {
  const { axios } = useAxios();
  const uploadFile: uploadFileType = (file) => {
    return new Promise((resolve, reject) => {
      if (!file) return reject("File not found.");
      const url = "/assetservice/api/assets/batch";
      const body = new FormData();
      body.set("formFile", file);
      axios
        .post(url, body)
        .then(({ data }) => {
          resolve(data[0]);
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  return uploadFile;
}

import { createContext, useContext, useMemo } from "react";
import { twMerge } from "tailwind-merge";
import { ClassName, childrenProps, sizeType, toggleProps } from "../types";
import Portal from "./_Portal";
type modalProps = toggleProps & {
  size?: sizeType;
} & childrenProps &
  ClassName;
type modalElementProps = childrenProps & ClassName;
type contextType = {
  size?: sizeType;
};
const Context = createContext({} as contextType);
function Modal({
  isOpen,
  toggle = () => {},
  size = "md",
  className = "",
  children,
}: modalProps) {
  return (
    <Portal>
      <div
        className={twMerge(
          "modal group fixed inset-0 flex flex-col z-30 bg-black/20 opacity-0 pointer-events-none transition-opacity [&.active]:opacity-100 [&.active]:pointer-events-auto",
          isOpen && "active",
          className
        )}
      >
        <button
          type="button"
          onClick={() => toggle()}
          className="absolute inset-0 cursor-default opacity-0"
        />
        <Context.Provider value={{ size }}>{children}</Context.Provider>
      </div>
    </Portal>
  );
}
function ModalDialog({ className, children }: modalElementProps) {
  const { size } = useContext(Context);
  const sizeClasses = useMemo(() => {
    if (size === "sm") return "min-[576px]:max-w-[300px]";
    if (size === "md") return "min-[576px]:max-w-[500px]";
    if (size === "lg") return "min-[992px]:max-w-[800px]";
    if (size === "xl")
      return "min-[1200px]:max-w-[1140px] min-[992px]:max-w-[800px]";
    return "";
  }, [size]);
  return (
    <div
      className={twMerge(
        "modal-dialog relative flex flex-col bg-white m-auto w-full rounded shadow px-6 transition-transform max-w-[97.5%] max-h-[97.5%] scale-75 group-[.modal.active]:scale-100",
        sizeClasses,
        className
      )}
    >
      {children}
    </div>
  );
}
function ModalHeader({ className = "", children = null }: modalElementProps) {
  return (
    <div
      className={`w-full text-center py-4 border-b border-gray ${className}`}
    >
      {children}
    </div>
  );
}
function ModalBody({ className = "", children = null }: modalElementProps) {
  return (
    <div className={`flex-1 py-4 overflow-auto ${className}`}>{children}</div>
  );
}
function ModalFooter({ className = "", children = null }: modalElementProps) {
  return (
    <div className={`w-full py-4 border-t border-gray ${className}`}>
      {children}
    </div>
  );
}
Modal.Dialog = ModalDialog;
Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;
export default Modal;

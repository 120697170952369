import { Fragment, useState } from "react";
import { Dropdown, Icon, InputGroup, Loading, Text } from "../components";
import { useAxios } from "../hooks";
import { debounce } from "../methods";
import { Address } from "../types";
type addressItemType = {
  id: string;
  address: {
    buildingNumber: null | string;
    country: null | string;
    countryCode: null | string;
    countryCodeISO3: null | string;
    countrySecondarySubdivision: null | string;
    countrySubdivision: null | string;
    countrySubdivisionName: null | string;
    countryTertiarySubdivision: null | string;
    crossStreet: null | string;
    extendedPostalCode: null | string;
    freeformAddress: null | string;
    municipality: null | string;
    municipalitySubdivision: null | string;
    postalCode: null | string;
    street: null | string;
    streetName: null | string;
    streetNameAndNumber: null | string;
    streetNumber: null | string;
  };
  position: { lat: number; lon: number };
  type: string;
};
type searchAddressProps = {
  label?: string;
  setValue: (val: Address) => void;
};
export default function SearchAddress({ label, setValue }: searchAddressProps) {
  const { axios, loading } = useAxios();
  const [search, setSearch] = useState("");
  const [addresses, setAddresses] = useState<addressItemType[]>([]);
  const items = addresses.map((e: any) => ({
    name: e.address?.freeformAddress,
    id: e.id,
  }));
  const hasItems = !!items.length;
  const handleSetSearch = (e: string) => {
    setSearch(e);
    debounce(() => handleSearchAddress(e));
  };
  const handleSearchAddress = (query: string) => {
    if (!query) return setAddresses([]);
    const url = "/addressservice/api/address/search";
    const config = { params: { countrySet: "DE", query } };
    axios.get(url, config).then(({ data }) => {
      setAddresses(data.results);
    });
  };
  const handleSetAddress = (id: string) => {
    const findAddress = addresses.find((e) => e.id === id);
    const address = findAddress?.address;
    const position = findAddress?.position;
    if (!address) return;
    const value: Address = {
      name: search,
      street: address?.streetName ?? "",
      number: address?.streetNumber ?? "",
      postalCode: address?.postalCode ?? "",
      city: address?.municipality ?? "",
      state: address?.countrySubdivision ?? "",
      country: address?.countryCode ?? "",
      location: position ?? null,
      countryCode: address?.countryCode ?? "",
      countryCodeISO3: address?.countryCodeISO3 ?? "",
      countrySubdivision: address?.countrySubdivision ?? "",
      countrySubdivisionName: address?.countrySubdivisionName ?? "",
    };
    setValue(value);
  };
  return (
    <Dropdown
      data-active={"true"}
      className="w-full"
      onSelect={handleSetAddress}
    >
      <Dropdown.Toggle
        as={InputGroup}
        value={search}
        setValue={handleSetSearch}
        label={label}
        placeholder="global.searchBox"
        append={
          <span className="input-group-text">
            <Icon name="ArrowDown2" />
          </span>
        }
      />
      <Dropdown.Menu>
        {loading.get ? (
          <Loading.Inline />
        ) : (
          <Fragment>
            {hasItems ? (
              items.map((e) => (
                <Dropdown.Item key={e.id} eventKey={e.id}>
                  {e.name}
                </Dropdown.Item>
              ))
            ) : (
              <p className="text-center text-gray-500 p-2">
                <Text>global.noItems</Text>
              </p>
            )}
          </Fragment>
        )}
      </Dropdown.Menu>
    </Dropdown>
  );
}

import { types } from "../constants";
import Badge from "./_Badge";
import Text from "./_Text";

type Props = { id: string | number };
function User({ id }: Props) {
  const data = types.user.find((e) => `${e.id}` === `${id}`);
  return (
    <Badge variant={data?.variant}>
      <Text>{data?.name ?? ""}</Text>
    </Badge>
  );
}
const Types = { User };
export default Types;

import { Fragment, useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router";
import { twMerge } from "tailwind-merge";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useMsal } from "@azure/msal-react";
import { authInstance, config } from "../../constants";
import NoAccess from "../../no-access";
import { useAxios, useMountEffect } from "../../hooks";
import { useDispatch } from "react-redux";
import { setPermissions } from "../../redux/permissions";
import { Loading } from "../../components";

export default function MainLayout() {
  const { pathname } = useLocation();
  const [isPlatformUser, setIsPlatformUser] = useState<boolean>(true);
  const { instance, accounts } = useMsal();
  const [showSidebar, setShowSidebar] = useState(false);
  const [loading, setLoading] = useState(false);
  const { axios } = useAxios();
  const dispatch = useDispatch();

  const toggleSidebar = () => {
    setShowSidebar((p) => !p);
  };

  const logoutHandler = async () => {
    const token = getLocalIdToken();
    const currentAccount = instance.getAccountByHomeId(
      accounts[0].homeAccountId
    );
    await instance.logoutRedirect({
      idTokenHint: token.secret,
      account: currentAccount,
      postLogoutRedirectUri: "/",
    });
    localStorage.clear();
  };

  const getLocalIdToken = () => {
    const homeAccountId = authInstance?.getAllAccounts()[0]?.homeAccountId;
    const Idtoken = `${homeAccountId}-${`${config.tenant}.b2clogin.com`}-idtoken-${config.clientId
      }----`;
    //@ts-ignore
    const token = JSON.parse(sessionStorage.getItem(Idtoken)) || "";
    return token;
  };
  useEffect(() => {
    setShowSidebar(false);
  }, [pathname]);

  useEffect(() => {
    const currentAccount = instance.getAccountByHomeId(
      accounts[0].homeAccountId
    );
    const userType = currentAccount?.idTokenClaims?.extension_usertype;
    if (userType === 1 || userType === 2) {
      setIsPlatformUser(true);
    } else {
      setIsPlatformUser(false);
    }
  }, []);

  useMountEffect(() => {
    setLoading(true);
    axios.get("/accountservice/api/users/permissions")
      .then((permissions) => {
        dispatch(setPermissions(permissions.data.permissions));
      }).finally(() => {
        setLoading(false);
      })
  });
  return (
    <Fragment>
      {isPlatformUser ? (
        <>
          {loading && <Loading.Inline />}
          {!loading &&
            <>
              <Sidebar isOpen={showSidebar} toggle={toggleSidebar} />
              <div
                className={twMerge(
                  "lg:transition-[padding]",
                  showSidebar ? "lg:ps-[50px]" : "lg:ps-[265px]"
                )}
              >
                <Header toggleMenu={toggleSidebar} />
                <main className="w-full max-w-screen-2xl mx-auto p-2 lg:px-8 lg:py-6">
                  <Outlet />
                </main>
              </div>
            </>
          }
        </>
      ) : (
        <NoAccess onClick={logoutHandler} />
      )}
    </Fragment>
  );
}

import { useContext } from "react";
import { Context } from ".";
import { InputGroup, Select } from "../../../../components";
import { rules, types } from "../../../../constants";

export default function BasicInfo() {
  const { data, handleSetValue } = useContext(Context);
  return (
    <section className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-6">
      <Select
        label="userManagement.platformUsers.create.userType"
        value={data.userType}
        setValue={handleSetValue("userType")}
        items={types.user}
        rules={rules.required}
      />
      <InputGroup
        label="userManagement.platformUsers.create.email"
        value={data.emailAddress}
        setValue={handleSetValue("emailAddress")}
        rules={rules.email}
      />
      <InputGroup
        label="userManagement.platformUsers.create.firstName"
        value={data.firstName}
        setValue={handleSetValue("firstName")}
        rules={rules.required}
      />
      <InputGroup
        label="userManagement.platformUsers.create.lastName"
        value={data.lastName}
        setValue={handleSetValue("lastName")}
        rules={rules.required}
      />
      <InputGroup
        label="userManagement.platformUsers.create.dateOfBirth"
        value={data.dateOfBirth}
        setValue={handleSetValue("dateOfBirth")}
        type="date"
      />
      <Select
        label="userManagement.platformUsers.create.gender"
        value={data.gender}
        setValue={handleSetValue("gender")}
        items={types.gender}
      />
      <InputGroup
        label="userManagement.platformUsers.create.mobileNumber"
        value={data.mobileNumber}
        setValue={handleSetValue("mobileNumber")}
      />
      <InputGroup
        label="userManagement.platformUsers.create.phoneNumber"
        value={data.phoneNumber}
        setValue={handleSetValue("phoneNumber")}
      />
    </section>
  );
}

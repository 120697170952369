import { Fragment, useContext, useState } from "react";
import { Context } from "../";
import { Badge, Icon, Table, Text } from "../../../../../components";
import { channels } from "../../../../../constants";
import ChannelDetails from "./ChannelDetails";

export default function Channels() {
  const { companyData } = useContext(Context);
  const [activeCode, setActiveCode] = useState("");
  const handleSetActiveCode = (code: string) => {
    return () => setActiveCode(code);
  };
  const clearActiveCode = () => {
    setActiveCode("");
  };
  const isActive = (code: string) => {
    const codes = companyData.channels.map((e) => e.code);
    return codes.includes(code);
  };
  return (
    <Table>
      {channels.map((e) => (
        <Fragment key={e.code}>
          <tr className="cursor-pointer" onClick={handleSetActiveCode(e.code)}>
            <td className="space-y-2">
              <h5 className="text-heading-5">
                <Text>{e.name}</Text>
              </h5>
              <p className="text-gray-400">{e.code}</p>
            </td>
            <td>
              {isActive(e.code) && (
                <Badge variant="success">
                  <Text>b2bManagement.companies.details.channelActive</Text>
                </Badge>
              )}
            </td>
            <td>
              <Icon name="ArrowRight2" className="text-xl" />
            </td>
          </tr>
          <ChannelDetails
            channel={e}
            isOpen={activeCode === e.code}
            toggle={clearActiveCode}
          />
        </Fragment>
      ))}
    </Table>
  );
}

import { toast } from "react-hot-toast";

export default function handleErrors(error: any, translate: any) {
  let message = "";
  switch (error.response?.status || error.status) {
    case 403:
      break;
    case 404:
      message =
        (error.response.data?.detail && `errors.${error.response.data?.detail}`) ||
        error.response?.data.title ||
        error.response?.data.message ||
        error.response.data?.statusText ||
        error.response.data ||
        "404";
      return toast.error(translate(message));
    case 409:
      message =
        (error.response.data?.detail && `errors.${error.response.data?.detail}`) ||
        error.response.data?.statusText ||
        error.response.data ||
        error.response.data?.title ||
        "409";
      return toast.error(translate(message));
    case 500:
      message = error.response.statusText;
      return toast.error(translate(message));

    case 400:
      message = error.response.statusText;
      return toast.error(
        message,
        (error.response.data?.detail && `errors.${error.response.data?.detail}`) ||
        error.response?.data.title ||
        error.response?.data.message ||
        error.response.data?.statusText ||
        error.response.data ||
        "400",
      );

    default:
      message = "errors.error";
      return toast.error(translate(message));
  }
}

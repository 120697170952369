import { useAxios } from "../hooks";
import Icon from "./_Icon";
import Text from "./_Text";

type FileUploaderProps = {
  value?: string | null;
  setValue?: CallableFunction;
  accept?: any;
};

export default function FileUploader({
  value = "",
  setValue = () => {},
  accept,
}: FileUploaderProps) {
  const { axios, loading } = useAxios();
  const hasFile = !!value;
  const clearFile = () => {
    setValue(null);
  };
  const showFileName = (src?: string | null) => {
    if (!src) return "--";
    return src.split("/").at(-1);
  };
  const uploadFile = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.currentTarget.files[0];
    const url = "/assetservice/api/assets/batch";
    const body = new FormData();
    body.append("formFile", file);
    axios.post(url, body).then(({ data }) => {
      setValue(data[0].url);
    });
  };
  if (hasFile || loading.post)
    return (
      <div className="block w-full flex-center flex-col bg-gray-100 border-[0.5px] border-gray-100 rounded p-8 text-center space-y-2">
        <button type="button" onClick={clearFile}>
          <Icon name="CloseCircle" className="text-danger text-lg" />
        </button>
        {loading.post ? (
          <div className="block w-full h-2 rounded-full overflow-hidden bg-white">
            <span
              style={{ width: `${loading.progress}%` }}
              className="h-full block bg-success rounded transition-[width] max-w-full"
            />
          </div>
        ) : (
          hasFile && (
            <div>
              <Icon name="Document" className="text-primary text-5xl" />
            </div>
          )
        )}
        <p className="w-full text-body-base text-gray-600 truncate">
          {showFileName(value)}
        </p>
      </div>
    );
  return (
    <label
      data-type="button"
      className="block w-full flex-center flex-col bg-primary-light border-[0.5px] border-dashed border-primary rounded p-8 text-center space-y-2 cursor-pointer"
    >
      <Icon name="DocumentUpload" className="text-primary text-5xl" />
      <h5 className="text-gray-800 text-heading-5">
        <Text>global.uploadFile.title</Text>
      </h5>
      <p className="text-gray-600 text-body-1 font-medium">
        <Text>global.uploadFile.description</Text>
      </p>
      <input type="file" onChange={uploadFile} hidden accept={accept} />
    </label>
  );
}

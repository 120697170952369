import { useState } from "react";
import { Dropdown, InputGroup, SelectCountry } from "..";
import { useSearchAddress } from "../../hooks";
import { CountrySelectOption } from "../../types/country";
import { debounce } from "../../methods";
import { Address } from "../../types";

type SearchAddressProps = {
  setValue: (address: Address) => void;
  inputAriaLabel?: string;
  menuItemAriaLabel?: string;
  defaultCountry?: string;
};

type addressItemsType = {
  name: string;
  id: string;
  address: any;
}[];

export default function SearchAddress({
  setValue,
  menuItemAriaLabel,
  defaultCountry,
}: SearchAddressProps) {
  const [loading, setLoading] = useState(false);
  const searchAddress = useSearchAddress();
  const [search, setSearch] = useState<string>("");
  const [items, setItems] = useState<addressItemsType>([]);
  const [country, setCountry] = useState<CountrySelectOption>();

  const handleSetSearch = (query: string) => {
    setSearch(query);
    debounce(() => {
      const hasQuery = !!query;
      if (!hasQuery) {
        setItems([]);
      }
      handleSearchAddress(query);
    });
  };

  const handleSearchAddress = (query: string) => {
    if (!query) return setItems([]);
    setLoading(true);
    searchAddress({ query, countrySet: country?.value || defaultCountry || "DE" })
      .then(setItems)
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSetAddress = (id: string) => {
    const address = items.find(e => e.id === id);
    if (address) {
      setValue(address.address);
      setSearch("");
    }
  };

  return (
    <div className="flex gap-2">
      <SelectCountry value={defaultCountry} setValue={setCountry} />
      <Dropdown
        data-active={"true"}
        className="block w-full"
        onSelect={handleSetAddress}
      >
        <Dropdown.Toggle
          as={InputGroup}
          label="global.locations.address"
          value={search}
          setValue={handleSetSearch}
          placeholder="global.locations.searchPlaceHolder"
          prepend={
            <div hidden={!loading} className="input-group-text">
              <span className="inline-block h-4 w-4 animate-spin rounded-full border-2 border-current border-r-transparent align-[-0.2em]" />
            </div>
          }
          append={
            <i className="bi bi-chevron-down input-group-text text-primary" />
          }
        />
        <Dropdown.Menu>
          {items.map((e: any) => (
            <Dropdown.Item
              aria-label={menuItemAriaLabel}
              key={e.id}
              eventKey={e.id}
            >
              {e.name}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

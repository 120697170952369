import { ComponentProps, useEffect, useRef } from "react";
import imageLoadingGif from "../assets/icons/image Loading.gif";
import imageIcon from "../assets/icons/no image.png";
type imageProps = {
  src?: string | null | undefined;
  alt?: string;
  thumbnail?: string;
};
type imgProps = Omit<ComponentProps<"img">, keyof imageProps>;
export default function Image({
  src,
  alt,
  thumbnail = imageIcon,
  loading = "lazy",
  ...props
}: imageProps & imgProps) {
  const imageRef = useRef<HTMLImageElement>(null);
  const handleSrc = () => {
    const element = imageRef.current;
    if (!element) return;
    if (!src) {
      element.src = imageIcon;
      return;
    }
    element.src = imageLoadingGif;
    const image = new window.Image();
    image.src = src;
    image.onload = () => {
      element.src = src;
    };
  };
  useEffect(handleSrc, [src]);
  return (
    <img
      ref={imageRef}
      src={thumbnail}
      alt={alt}
      loading={loading}
      {...props}
    />
  );
}

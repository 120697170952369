import i18n from "i18next";
import { config } from "../constants";
import { de, en } from "../langs";
const resources = {
  en: { translation: en },
  de: { translation: de },
};
i18n.init({
  resources,
  lng: config.lang,
});
export default i18n;

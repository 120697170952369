const userGroups = {
  list: {
    addNewBtn: "Add new DE",
    editBtn: "Edit DE",
    active: "Active DE",
  },
  details: {
    infoTab: "Information DE",
    infoDesc: "Name, Description ... DE",
    finalizeTab: "Finalize DE",
    finalizeDesc: "Finalize information DE",

    userGroupName: "User group name DE",
    role: "Role DE",
    shortDescription: "Short description DE",

    backBtn: "Back DE",
    nextBtn: "Next DE",
    submitBtn: "Submit DE",
    addMessage: "User Group has been added successfully. DE",
    updateMessage: "User Group has been updated successfully. DE",
  },
};
export default userGroups;

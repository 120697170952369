import { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  Button,
  Icon,
  Image,
  Pagination,
  SearchBox,
  Table,
  Text,
} from "../../../../components";
import { config } from "../../../../constants";
import { useAxios } from "../../../../hooks";
import { WithPaging } from "../../../../types";

type dataType = {
  userId: string;
  companyId: string;
  companyName: string;
  firstName: string;
  lastName: string;
  emailAddress: string;
  personalNumber: null | number;
  userType: number;
  dateOfBirth: Date;
  gender: number;
  imageThumbnailUrl: string;
};
type paramsType = {
  pageNumber: string;
  pageSize: string;
  keyword: string;
  companyId: string;
};

export default function Users() {
  const navigate = useNavigate();
  const { axios, loading } = useAxios();
  const [data, setData] = useState({} as WithPaging<dataType>);
  const [params, setParams] = useSearchParams({
    pageNumber: "1",
    pageSize: config.pageSize,
    keyword: "",
    companyId: "",
  } as paramsType);
  const handleSetParams = (key: keyof paramsType) => {
    return (value: any) =>
      setParams((p) => {
        p.set(key, String(value));
        return p;
      });
  };
  const goToDetails = (companyId: string, userId: string) => {
    return () => navigate(`${companyId}/${userId}`);
  };
  const getData = () => {
    const url = "/accountservice/api/users/b2b-admins";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [params]);
  return (
    <section className="space-y-4">
      <div className="flex flex-wrap items-center gap-4">
        <SearchBox
          value={params.get("keyword")}
          setValue={handleSetParams("keyword")}
          className="w-auto basis-full lg:basis-1/3"
        />
        <Button as={Link} to="add" className="w-fit ms-auto">
          <Icon name="Add" /> <Text>b2bManagement.users.list.addUser</Text>
        </Button>
      </div>
      <Table loading={loading.get}>
        {data.items?.map((e) => (
          <tr
            key={e.userId}
            onClick={goToDetails(e.companyId, e.userId)}
            className="cursor-pointer"
          >
            <td>
              <div className="flex items-center gap-4">
                {!!e.imageThumbnailUrl ? (
                  <Image
                    src={e.imageThumbnailUrl}
                    alt={e.firstName + e.lastName}
                    className="w-20 h-20 rounded object-cover"
                  />
                ) : (
                  <div className="w-20 h-20 flex-center rounded bg-gray-100 text-gray-400">
                    <Icon name="User" variant="Bulk" className="text-5xl" />
                  </div>
                )}

                <div className="flex-1 space-y-1">
                  <h6 className="text-gray-800 text-heading-6">
                    {e.firstName} {e.lastName}
                  </h6>
                  <p className="text-gray-500 text-body-2">
                    <Icon name="Sms" variant="Bulk" /> {e.emailAddress}
                  </p>
                </div>
              </div>
            </td>
            <td>
              <p className="text-body-1 text-gray-800">
                <Text>b2bManagement.users.list.company</Text>:{" "}
                <span className="text-gray-600">{e.companyName}</span>
              </p>
            </td>
            <td>
              <Button type="button" variant="gray">
                <Text>b2bManagement.users.list.details</Text>
              </Button>
            </td>
          </tr>
        ))}
      </Table>
      <Pagination
        pageNumber={params.get("pageNumber")}
        setActivePage={handleSetParams("pageNumber")}
        totalItems={data.totalItems}
        totalPages={data.totalPages}
      />
    </section>
  );
}

import { ChangeEvent } from "react";
import { twMerge } from "tailwind-merge";
import { useTranslate } from "../hooks";
import { debounce } from "../methods";
import Icon from "./_Icon";
type searchBoxProps = {
  placeholder?: string;
  value: string | null | undefined;
  setValue: (val: string) => void;
  className?: string;
};
export default function SearchBox({
  placeholder = "global.searchBox",
  value,
  setValue,
  className,
}: searchBoxProps) {
  const translate = useTranslate();
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value || "";
    debounce(() => setValue(value));
  };
  return (
    <div className={twMerge("w-full h-9 flex-center gap-1", className)}>
      <input
        defaultValue={value ?? ""}
        onChange={handleChange}
        placeholder={translate(placeholder) ?? ""}
        className="flex-1 h-full bg-white border border-transparent rounded px-3 py-1 focus-within:border-primary transition-colors"
      />
      <button
        type="button"
        className="h-full aspect-square rounded bg-white text-primary"
      >
        <Icon name="SearchNormal1" variant="TwoTone" size={18} />
      </button>
    </div>
  );
}

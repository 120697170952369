import { Fragment, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  Badge,
  Button,
  Pagination,
  SearchBox,
  Table,
  Text,
} from "../../../../../components";
import { config } from "../../../../../constants";
import { ImportProductDetailsMenu } from "../../../../../containers";
import { useAxios, useConverter } from "../../../../../hooks";
import { WithPaging } from "../../../../../types";
import { importProductItemType } from "../../../../../types/import-product";

type paramsType = {
  pageNumber: string;
  pageSize: string;
  keyword: string;
};

export default function ImportProducts() {
  const { axios, loading } = useAxios();
  const convert = useConverter();
  const [data, setData] = useState({} as WithPaging<importProductItemType>);
  const [params, setParams] = useSearchParams({
    pageNumber: "1",
    pageSize: config.pageSize,
    keyword: "",
  } as paramsType);
  const [activeId, setActiveId] = useState("");
  const clearActiveId = () => {
    setActiveId("");
  };
  const handleSetParams = (key: keyof paramsType) => {
    return (value: any) =>
      setParams((p) => {
        p.set(key, String(value));
        return p;
      });
  };
  const goToDetails = (id: string) => {
    return () => setActiveId(id);
  };
  const getData = () => {
    const url = "/productservice/api/providerproductimports";
    const config = { params };
    axios.get(url, config).then(({ data }) => {
      setData(data);
    });
  };
  useEffect(getData, [params]);
  return (
    <section className="space-y-4">
      <div className="flex items-center gap-4">
        <SearchBox
          value={params.get("keyword")}
          setValue={handleSetParams("keyword")}
          className="w-auto basis-full lg:basis-1/3"
        />
      </div>
      <Table loading={loading.get}>
        {data.items?.map((e) => (
          <Fragment key={e.providerProductImportId}>
            <tr
              onClick={goToDetails(e.providerProductImportId)}
              className="cursor-pointer"
            >
              <td className="space-y-2">
                <h2 className="text-heading-6 text-gray-800 whitespace-normal max-w-xs">
                  {e.title}
                </h2>
                <p className="text-body-2 text-gray-500">
                  <Text>Provider EN</Text>: {e.importInfo?.providerName}
                </p>
                <p>
                  <Badge variant="primary">{e.language}</Badge>
                </p>
              </td>
              <td className="space-y-2">
                <p className="text-body-2 text-gray-600">
                  <Text>Version EN</Text>: {e.version}
                </p>
                <p className="text-body-2 text-gray-600">
                  <Text>Configuration Version EN</Text>:{" "}
                  {e.importInfo?.configurationVersion}
                </p>
              </td>
              <td className="space-y-2">
                <p className="text-body-2 text-gray-600 font-medium">
                  <Text>Imported by EN</Text>:{" "}
                  <span className="font-normal">
                    {e.importInfo?.importedBy}
                  </span>
                </p>
                <p className="text-body-2 text-gray-600 font-medium">
                  <Text>Imported date EN</Text>:{" "}
                  <span className="font-normal">
                    {convert.date(e.importInfo?.importedDate)}
                  </span>
                </p>
              </td>
              <td>
                <Button type="button" variant="gray">
                  <Text>Details EN</Text>
                </Button>
              </td>
            </tr>
            <ImportProductDetailsMenu
              data={e}
              isOpen={activeId === e.providerProductImportId}
              toggle={clearActiveId}
            />
          </Fragment>
        ))}
      </Table>
      <Pagination
        pageNumber={params.get("pageNumber")}
        setActivePage={handleSetParams("pageNumber")}
        totalItems={data.totalItems}
        totalPages={data.totalPages}
      />
    </section>
  );
}

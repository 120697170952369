import { useContext, useEffect, useState } from "react";
import { Context } from ".";
import { Loading, Select } from "../../../../components";
import { rules } from "../../../../constants";
import { useAxios } from "../../../../hooks";
import { userGroupItemType } from "../../../../types/user-group";
export default function UserGroup() {
  const { axios, loading } = useAxios();
  const { data, handleSetValue } = useContext(Context);
  const [userGroups, setUserGroups] = useState<userGroupItemType[]>([]);
  const userGroupItems = userGroups.map((e) => ({
    name: e.name,
    id: e.id,
  }));
  const handleSelect = (value: string) => {
    const selectedItem = userGroups.find((e) => e.id === value);
    handleSetValue("userGroupId")(value);
    handleSetValue("userGroupName")(selectedItem?.name);
  };
  const getCompanies = () => {
    const url = "/accountservice/api/usergroups";
    const config = { params: { pageSize: 100, pageNumber: 1 } };
    axios.get(url, config).then(({ data }) => {
      setUserGroups(data.items);
    });
  };
  useEffect(getCompanies, []);
  if (loading.get) return <Loading.Inline />;
  return (
    <section>
      <Select
        label="userManagement.platformUsers.create.userGroup"
        value={data.userGroupId}
        setValue={handleSelect}
        items={userGroupItems}
        rules={rules.required}
      />
    </section>
  );
}

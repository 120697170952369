import { usePermission } from "./../hooks";
import { ComponentType } from "react";
import { Permission } from "./../types";
import NoPermission from "./../assets/image/no-access.svg";

export default function withPermission<P extends object>(
  Component: ComponentType<P>,
  permissions: Permission[],
) {
  const Error = () => {
    return (
      <p>
        <img className="mx-auto" src={NoPermission} alt="No Permission" />
      </p>
    );
  };
  return (props: P) => {
    const hasPermission = usePermission(...permissions);
    if (!hasPermission) return <Error />;
    return <Component {...props} />;
  };
}
